import React from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { status, priority } from '../utils/helperFunctions';
import useScreenSize from '../context/useScreenSize';
import moment from 'moment';

const TasksQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    getDeadlineStatus,
    getTitleFromMilliseconds,
    noOverlay

}) => {
    const { text, formatDate } = useLanguage();
    const { isDesktop } = useScreenSize();
    return (
        <ConfirmModal
            toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
            isOpen={isQuickViewOpen}
            noOverlay={noOverlay}
            shaddow
            width={isDesktop ? '50%' : '100%'}
            height={isDesktop ? '70%' : '100%'}
        >
            <div className='w-100'>
                <h4 className='mb-md'>{quickViewData?.taskTitle}</h4>
                <FieldWrapper
                    name={text?.logs?.totalTime}
                    label={getTitleFromMilliseconds(quickViewData?.totalTime)}
                />
                <ConditionalRender renderIf={quickViewData?.taskStatus === 'completed'}>
                    <FieldWrapper
                        name={text?.analytics?.dateCompleted}
                        label={moment(quickViewData?.dateCompleted).format('MM-DD-YYYY')}
                    />
                </ConditionalRender>
                <FieldWrapper
                    name={text?.tasks?.details?.description}
                    label={quickViewData?.taskDescription}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.project}
                    label={quickViewData?.taskProject?.projectName}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.assignedTo}
                    label={quickViewData?.taskAssignedTo ? `${quickViewData?.taskAssignedTo?.firstName} ${quickViewData?.taskAssignedTo?.lastName}` : text?.tasks?.details?.unassigned}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.status}
                    label={status(text).find(p => p.value === quickViewData?.taskStatus)?.label}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.priority}
                    label={priority(text).find(p => p.value === quickViewData?.taskPriority)?.label}
                />
                <FieldWrapper
                    name={<p
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: getDeadlineStatus(quickViewData?.taskDeadline).color,
                            marginRight: '10px'
                        }}
                    />}
                    label={getDeadlineStatus(quickViewData?.taskDeadline)?.title}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.deadline}
                    label={formatDate(quickViewData?.taskDeadline, 'MM-DD-YYYY')}
                />
            </div>
        </ConfirmModal>
    )
}

export default TasksQuickView

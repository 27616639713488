import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates';

import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';

const StyledDateRangePicker = styled.div`
.DateRangePickerInput {
    border: none;
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.white};
    .DateInput_input__focused {
        border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
    }
    .DateRangePickerInput_arrow {
        margin: 0 1em;
        .DateRangePickerInput_arrow_svg {
            fill: ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
        }
    }
}
    .DateInput {
        background: ${colors.lightGray};
        .DateInput_input {
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .DateRangePickerInput_clearDates_default {
        margin: 0;
        right: 0;
        &:hover {
            background: transparent;
            svg {
                fill: ${colors.red};
            }
        }
        svg {
            fill: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .CalendarDay__hovered_span,
    .CalendarDay__selected_span,
    .CalendarDay__selected {
        background: ${colors.blue};
        border: 1px double ${colors.blue};
        color: white;
    }
`;


const ExportLogs = ({
    input,
    meta
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [focusedInput, setFocusedInput] = useState(null);

    const handleDateChange = ({ startDate, endDate }) => {
        input.onChange({ startDate, endDate });
    };

    return (
        <div>
            <StyledDateRangePicker theme={theme} className='flex flex-column mt-md'>
                <DateRangePicker
                    name={input?.name}
                    startDate={input.value.startDate}
                    startDateId="start_date_id"
                    endDate={input.value.endDate}
                    endDateId="end_date_id"
                    onDatesChange={handleDateChange}
                    focusedInput={focusedInput}
                    onFocusChange={input => setFocusedInput(input)}
                    isOutsideRange={() => false}
                    displayFormat="MM-DD-YYYY"
                    showClearDates
                    numberOfMonths={!isDesktop ? 1 : 2}
                    hideKeyboardShortcutsPanel
                    startDatePlaceholderText={text?.tasks?.home?.filters?.startDate}
                    endDatePlaceholderText={text?.tasks?.home?.filters?.endDate}
                />
                {meta.error && meta.touched && <span className='error mt-md'>{meta.error}</span>}
            </StyledDateRangePicker>
        </div>
    )
}

export default ExportLogs
// utils/validators.js
export const matchesPattern = (pattern, message) => (value) => (value && pattern.test(value) ? undefined : message);

export const isRequired = (message) => (value) => (value ? undefined : message);
export const requiredNumber = message => value =>
    Number(value) === 0 || value ? undefined : message;

export const minLength = (min) => (message) => (value) => (value && value.length >= min ? undefined : message);

export const isValidEmail = (message) => (value) => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return value && pattern.test(value) ? undefined : message;
};

export const noSpaces = (value) => {
    if (value && /\s/.test(value)) {
        return 'No spaces allowed';
    }
    return undefined;
};

export const noSpecialChars = (value) => {
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharsRegex.test(value)
        ? 'Field name cannot contain special characters'
        : undefined;
};

export const noNumbers = (value) => {
    const numbersRegex = /[0-9]/;
    return numbersRegex.test(value)
        ? 'Field name cannot contain numbers'
        : undefined;
};


export const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';
import colors from '../../globalStyles.scss';

const StyledFileUploaderInput = styled.div`
  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.24s ease-in-out;
    &:hover {
      border-color: #888888;
    }
  }
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .wrapper {
    margin-top: 10px;
  }
  ul {
    list-style: none;
    padding: 0;
    width: 50%;
  }
  li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 0;
    background: ${colors.backgroundSecondary};
    color: ${colors.white};
    margin-bottom: 1rem;
  }
  .remove {
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.red};
    svg {
        color: ${colors.white};
        font-size: 1rem;
    }
}
`;

const FileUploaderInput = ({
  input,
  meta
}) => {
  const { text } = useLanguage();

  const [files, setFiles] = useState([]);
  const maxSize = 5 * 1024 * 1024; // 5 MB limit
  const allowedFileTypes = ['text/plain', 'application/pdf'];
  const [error, setError] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      const validFiles = acceptedFiles.filter((file) => {
        if (!allowedFileTypes.includes(file.type)) {
          setError(`${text?.uploader?.fileType} ${file.type} ${text?.uploader?.isNot}`);
          return false;
        }
        if (file.size > maxSize) {
          setError(`${text?.uploader?.file} ${file.name} ${text?.uploader?.toLarge}`);
          return false;
        }
        setError('');
        return true;
      });

      const updatedFiles = [...files, ...validFiles];
      setFiles(updatedFiles);
      input.onChange(updatedFiles);
    },
    [files, input]
  );

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    input.onChange(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf']
    },
    multiple: true,
  });

  return (
    <StyledFileUploaderInput>
      <div {...getRootProps()} className='dropzone'>
        <input {...getInputProps()} />
        <p>{text?.uploader?.select}</p>
      </div>
      {meta.error && meta.touched && <p className='error'>{meta.error}</p>}
      {error && <p className='error'>{error}</p>}
      <div className='wrapper'>
        <ul>
          {files.map((file, index) => (
            <li key={index}>
              <button
                className='remove'
                type='button'
                onClick={() => handleRemoveFile(index)}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
              <p className='ml-md'>
                {file.name}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </StyledFileUploaderInput>
  );
};

export default FileUploaderInput;

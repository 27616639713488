import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfDiv = styled.div`
    position: relative;
    // width: 100%;
    overflow: auto;
`;

const PdfPage = styled(Page)`
    width: 100% !important; /* Make sure the page takes full width */
    height: auto !important; /* Maintain aspect ratio */
    // margin: 0 auto; /* Center the page */

`;

// TODO: FIND A BETTER SOLUTION FOR SMALLER SCREENS

const PdfViewer = ({ pdfFile }) => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <PdfDiv>
            <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from({ length: numPages }, (_, index) => index + 1).map(page => (
                    <PdfPage
                        key={page}
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                    />
                ))}
            </Document>
        </PdfDiv>
    );
}

export default PdfViewer;

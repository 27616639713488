// App.js (React App)
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { verify } from '../utils/calls'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'

const VerifyEmail = () => {
    const [message, setMessage] = useState("");
    const location = useLocation();
    const { theme } = useTheme();


    // Extract the token from the query string
    const { token } = queryString.parse(location.search);

    const hasRun = useRef(false);

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const res = await verify(token);
                if (res.status === 200) {
                    setMessage(res?.data?.message);
                } else {
                    setMessage("Error verifying email");
                }
            } catch (error) {
                console.error('error', error);
                setMessage("Error verifying email");
            }
        };

        if (token && !hasRun.current) {
            verifyEmail();
            hasRun.current = true;
        } else if (!token) {
            setMessage("No token found");
        }
    }, [token]);

    return (
        <div className='flex items-center justify-center' style={{
            color: theme === 'dark' ? colors.white : colors.black,
            fontSize: '1.2rem',
            fontFamily: colors.openSans,
            height: '100%'
        }}>
            <div>{message}</div>
        </div>
    );
};

export default VerifyEmail;

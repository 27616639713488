import request from '../request';

/**
 * get attributes request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getAttributes = async (userId) => {
    let url = '/attributes';
    if (userId) {
        url += `?userId=${userId}`;
    }
    try {
        const { data, status } = await request('GET', url);

        // Get the language from sessionStorage
        const language = sessionStorage.getItem('language') || 'en';

        // Check if the language is Spanish and translate if necessary
        if (language === 'es') {
            data.inventoryColumns = data.inventoryColumns.map(column => {
                switch (column.fieldName) {
                    case 'inventoryName':
                        column.fieldLabel = 'Nombre';
                        break;
                    case 'inventoryCategory':
                        column.fieldLabel = 'Categoría';
                        break;
                    case 'inventoryQuantity':
                        column.fieldLabel = 'Cantidad';
                        break;
                    case 'projectName':
                        column.fieldLabel = 'Proyecto';
                        break;
                    case 'createdAt':
                        column.fieldLabel = 'Creado el';
                        break;
                    case 'updatedAt':
                        column.fieldLabel = 'Actualizado el';
                        break;
                    case 'isPriority':
                        column.fieldLabel = 'Prioridad';
                        break;
                    default:
                        break;
                }
                return column;
            });
        }

        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get attributes: ${error.message}`);
    }
};

export default getAttributes;

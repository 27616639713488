import React from 'react'
import styled from 'styled-components'
import ChatContainer from '../Chat/ChatContainer'
import ConditionalRender from '../Core/ConditionalRender'
import NavLink from '../Navigation/NavLink'
import colors from '../globalStyles.scss'
import { languageOptions } from '../utils/helperFunctions'
import { useLanguage } from '../context/LanguageContext';
import CreatableInput from '../Core/Inputs/CreatableInput'
import useScreenSize from '../context/useScreenSize'

const StyledFooter = styled.div`
    
    background: ${colors.black};
    &.desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        padding: 15px;
        height: 5%;
    }

    width: 100%;
    bottom: 0;
    .nav-links {
        font-family: ${colors.openSans};
        display: flex;
        a {
            margin-right: 15px;
            color: ${colors.white};
        }
    }
`

const Footer = ({
    isCompanyPremium,
    isLoggedIn,
    handleToggleChat,
    showChat,
    typing,
    handleSendMessage,
    conversationHistory,
    thinking,
    chatErrorMessage,
    isSuperAdmin,
    handleLinkClick,
    toggleMenu,
    isAdmin
}) => {
    const { updateLanguage, language, text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    return (
        <StyledFooter
            className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}
        >
            <ul className='nav-links items-center'>
                <NavLink to="/contact-us" onClick={handleLinkClick && handleLinkClick}  >
                    {text?.footer?.contactUs}
                </NavLink>
                <NavLink to="/announcements" onClick={handleLinkClick && handleLinkClick}   >{text?.footer?.announcement}</NavLink>
                <ConditionalRender renderIf={isLoggedIn}>
                    <NavLink to="/settings" onClick={handleLinkClick && handleLinkClick}  >{text?.footer?.settings}</NavLink>
                </ConditionalRender>
                <ConditionalRender renderIf={isLoggedIn && isAdmin && isCompanyPremium}>
                    <NavLink to="/analytics" onClick={handleLinkClick && handleLinkClick} banner={'New'}  >{'Analytics'}</NavLink>
                </ConditionalRender>
                <ConditionalRender renderIf={isSuperAdmin}>
                    <NavLink to="/admin" onClick={handleLinkClick && handleLinkClick}  >{'Super Admin'}</NavLink>
                </ConditionalRender>
                <ConditionalRender renderIf={!isLoggedIn}>
                    <div>
                        <CreatableInput
                            initValue={
                                languageOptions.find((x) => x.value === language)?.label
                            }
                            isSearchable={false}
                            isClearable={false}
                            name="selectedLanguage"
                            onChange={(event) => {
                                updateLanguage(event.value, true);
                            }}
                            options={languageOptions}
                            style={{
                                width: '100%'
                            }}
                        />
                    </div>
                </ConditionalRender>
            </ul>
            <ConditionalRender renderIf={isDesktop}>
                {
                    (isCompanyPremium && isLoggedIn) && (
                        <ChatContainer
                            showChat={showChat}
                            handleToggleChat={handleToggleChat}
                            handleSendMessage={handleSendMessage}
                            conversationHistory={conversationHistory}
                            thinking={thinking}
                            typing={typing}
                            chatErrorMessage={chatErrorMessage}
                        />
                    )
                }
            </ConditionalRender>
        </StyledFooter >
    )
}

export default Footer
import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { FieldStyles } from './FieldStyles';
import styled from 'styled-components';
import colors from '../../globalStyles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import TimePicker from 'rc-time-picker';
import { isEmpty } from 'lodash';
import ConditionalRender from '../ConditionalRender';
import 'rc-time-picker/assets/index.css';
import { useTheme } from '../../context/ThemeContext';

const StyledDatePicker = styled.div`

display: flex;
align-items: center;
.SingleDatePickerInput__withBorder {
    border: none;
    border-bottom: 1px solid ${(props) => (props.theme === 'dark' ? colors.white : colors.blue)};
    border-bottom-color: ${props => props.showLightColors && colors.blue};
    .DateInput {
        .DateInput_input {
            background: ${props => props.disabled
        ? (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.disabledDark : colors.lightBlue))
        : (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray))};
        }

    }
}
  .CalendarDay__selected {
    background: ${colors.blue};
    &:hover {
        background: ${colors.blue};
    }
  }
  .TimePicker {
    margin-left: 15px;
    input {
        border-radius: 0;
        background: ${props => props.disabled
        ? (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.disabledDark : colors.lightGray))
        : (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray))};
        &focus: {
            border-bottom: 1px solid ${colors.blue};
        }
    }
  }
`;

const SingleDateInput = ({
    input,
    meta,
    setIsEditing,
    onChange,
    disabled,
    form,
    withTimePicker,
    showLightColors,
    className,
    allowEmptyInitialValue = false,
    isClearable = false,
    style,
    isOutsideRange = (day) => day.isBefore(moment(), 'day'),
    ...rest
}) => {
    const { theme } = useTheme();
    const [focused, setFocused] = useState(false);
    const [selectedTime, setSelectedTime] = useState(moment('00:00', 'HH:mm'));

    const combineDateAndTime = (date, time) => {
        const dateTime = date.clone().set({
            hour: time.hour(),
            minute: time.minute(),
            second: time.second()
        });

        input.onChange(dateTime);
        if (onChange) onChange({ name: input.name, value: dateTime });
        if (setIsEditing) setIsEditing(true);
    };

    const handleDateChange = (date) => {
        if (date) {
            combineDateAndTime(date, selectedTime);
        }
    };

    const handleTimeChange = (time) => {
        if (time) {
            setSelectedTime(time);
            const currentMoment = moment(input.value);
            combineDateAndTime(currentMoment, time);
        }
    };

    useEffect(() => {
        if (!isEmpty(input?.value)) {
            setSelectedTime(moment(input.value));
        } else {
            setSelectedTime(moment('00:00', 'HH:mm'));
            form?.change(input?.name, moment('00:00', 'HH:mm'))
        }
    }, [input]);

    const handleClear = () => {
        input.onChange(null);
        setSelectedTime(moment('00:00', 'HH:mm'));
        if (onChange) onChange({ name: input.name, value: null });
        if (setIsEditing) setIsEditing(true);
    };


    return (
        <StyledDatePicker
            theme={theme}
            showLightColors={showLightColors}
            disabled={disabled}
            style={style}
        >
            <SingleDatePicker
                className='w-100'
                date={!isEmpty(input.value) ? moment(input.value) : allowEmptyInitialValue ? null : moment()}
                onDateChange={handleDateChange}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="single-date-picker"
                hideKeyboardShortcutsPanel
                minDate={moment()}
                numberOfMonths={1}
                disabled={disabled}
                isOutsideRange={isOutsideRange}
                {...rest}
            />
            <ConditionalRender renderIf={withTimePicker}>
                <TimePicker
                    value={selectedTime}
                    className='TimePicker'
                    onChange={handleTimeChange}
                    showSecond={false}
                    allowEmpty={false}
                    inputReadOnly
                    use12Hours
                    minuteStep={1}
                    disabled={disabled}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={!isEmpty(input.value) && isClearable && !disabled}>
                <FontAwesomeIcon
                    className='ml-md'
                    onClick={handleClear}
                    icon={faClose} style={{
                        color: colors.red,
                        cursor: 'pointer'
                    }}
                />
            </ConditionalRender>
            {meta.touched && meta.error && <span>{meta.error}</span>}
        </StyledDatePicker>
    );
};

export const SelectDate = ({ name, label, className, ...rest }) => {
    return (
        <FieldStyles className={className}
        >
            {label && (
                <label className='flex b mb-sm'>{label}</label>
            )}
            <div className='custom-field'>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Field className='w-100' name={name} component={SingleDateInput} {...rest} />
                </div>
            </div>
        </FieldStyles>
    );
};

export default SelectDate;

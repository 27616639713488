import request from '../request';

/**
 * get logs request to the API.
 * @param {string} userId - The user ID.
 * @param {string} projectId - The project ID (optional).
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getLogs = async (userId, projectId, showAll) => {
    let url = '/time/logs';
    let queryParams = [];

    if (userId) {
        url += `/${userId}`;
    }
    if (projectId) {
        queryParams.push(`projectId=${projectId}`);
    }
    if (showAll) {
        queryParams.push('showAll=true');
    }

    if (queryParams?.length > 0) {
        url += `?${queryParams.join('&')}`;
    }

    try {
        const { data, status } = await request('GET', url);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get logs: ${error.message}`);
    }
};

export default getLogs;


import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ConditionalRender from './ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';
import colors from '../globalStyles.scss';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height:  ${props => props.height || 'auto'};
    width: ${props => props.width || '100%'};
    background-color: ${colors.darkGray};
        &.desktop {
            .SelectableBox {
                    width: 30%;
                }
        }
            &.tablet {
                .SelectableBox {
                    width: 50%;
                }
            }
            &.phone {
                .SelectableBox {
                    width: 100%;
                }
            }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto; /* Push to the bottom */
  padding: 0.5em;
  background-color: ${colors.darkGray};
`;

const Button = styled.button`
    margin: 0 0.5rem;
    padding: 0.5rem 1em;
background-color: ${props =>
        props.disabled
            ? '#cccccc'
            : props.showLightColors
                ? colors.backgroundSecondary
                : props.theme === 'dark'
                    ? colors.secondary
                    : colors.blue
    };
    svg {    
        color: ${colors.blue};
        color: ${props => (props.showLightColors && colors.white)};
    }
    border: none;
    border-radius: 4px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  &:hover {
    background-color: ${props => (props.disabled ? '#cccccc' : (props.theme === 'dark') ? colors.lightGray : colors.blueLight)};
    svg {
        color: ${colors.blueLight};
        color: ${props => (props.showLightColors && colors.white)};
    }
  }
`;

const PageIndicator = styled.p`
  margin: 0 1em;
  font-size: 1em;
  font-weight: bold;
  font-family: ${colors.openSans};
`;

const Wrapper = styled.div`
  flex: 1;
  display: ${(props) => (props.noData ? 'flex' : 'block')} ;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0.5em;
`;

const PaginatedData = ({
    data,
    className,
    dataIsLoading = false,
    defaultItemsPerPage = 12,
    ItemComponent,
    height,
    width,
    noDataText = 'No data available',
    searching,
    showLightColors
}) => {
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data?.slice(startIndex, endIndex);
    };

    useEffect(() => {
        setCurrentPage(1); // Reset to the first page whenever data changes
    }, [searching]);

    return (
        <Container
            height={height}
            width={width}
            className={classnames({
                [className]: className,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
        >
            <PaginationContainer theme={theme}>
                <div className="flex items-center">
                    <Button showLightColors={showLightColors} theme={theme} onClick={(e) => {
                        e.preventDefault()
                        setCurrentPage(currentPage - 1)
                    }}
                        disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faCaretLeft} />
                    </Button>
                    <PageIndicator>{currentPage} / {totalPages}</PageIndicator>
                    <Button showLightColors={showLightColors} theme={theme} onClick={(e) => {
                        e.preventDefault()
                        setCurrentPage(currentPage + 1)
                    }}
                        disabled={currentPage === totalPages}>
                        <FontAwesomeIcon icon={faCaretRight} />
                    </Button>
                </div>
            </PaginationContainer>
            <Wrapper className='scroll-container' noData={isEmpty(data)}>
                <ConditionalRender renderIf={!isEmpty(data)}>
                    {getCurrentPageData()?.map(item => (
                        <ItemComponent key={item?._id || ''} item={item} />
                    ))}
                </ConditionalRender>
                <ConditionalRender isLoading={dataIsLoading} renderIf={isEmpty(data)}>
                    <div className='no-data'>{noDataText}</div>
                </ConditionalRender>
            </Wrapper>
        </Container>
    );
};

export default PaginatedData;

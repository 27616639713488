import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {Object} email - The user email data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const adminUpdate = async (params) => {
    try {
        const response = await request('POST', '/admin/update', { params });
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to update user: ${error.message}`);
    }
};

export default adminUpdate;

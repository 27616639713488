import React from 'react';
import useScreenSize from '../context/useScreenSize'
import HomeLandingContainerDesktop from './HomeLandingContainerDesktop';
import HomeLandingContainerTablet from './HomeLandingContainerTablet';
import HomeLandingContainerPhone from './HomeLandingContainerPhone';
import ConditionalRender from '../Core/ConditionalRender';

const HomeLandingContainer = ({
}) => {
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    return (
        <>
            <ConditionalRender renderIf={isDesktop}>
                <HomeLandingContainerDesktop />
            </ConditionalRender>
            <ConditionalRender renderIf={isTablet}>
                <HomeLandingContainerTablet />
            </ConditionalRender>
            <ConditionalRender renderIf={isPhone}>
                <HomeLandingContainerPhone />
            </ConditionalRender>
        </>
    )
}

export default HomeLandingContainer
import React, { useState, useMemo, useCallback } from 'react';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import RenderImages from '../Core/RenderImages';
import { useDropzone } from 'react-dropzone';
import { updateCommentImages } from '../utils/calls';
import { useLanguage } from '../context/LanguageContext';
import base64ToBlob from '../utils/base64ToBlob';
import { useTheme } from '../context/ThemeContext'

import styled from 'styled-components'
import colors from '../globalStyles.scss'

const StyledDiv = styled.div`
 .image-uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dropzone {
            display: flex;
            flex-direction: column;
            margin: 0.5em 0;
            padding: 1em;
            background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                background: ${colors.lightGray};
            }
            span {
                font-family: ${colors.roboto};
                color: ${props => props.theme === 'dark' ? colors.black : colors.black};
            }
        }
    }

`



const CommentWithImages = ({
    comment,
    disabled,
    projectId,
    taskId,
    handleGetTask
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageError, setImageError] = useState('')
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setImages] = useState([]);

    useMemo(() => {
        setImages(comment?.imageUrls);
    }, [comment?.imageUrls]);

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const processImages = async (images) => {
        const formData = new FormData();
        console.log(images)
        images.forEach((image) => {
            if (image.new) {
                const imageBlob = base64ToBlob(image?.url);
                formData.append('newImages', imageBlob, image?.originalName);
            } else {
                formData.append('oldImages', JSON.stringify(image));
            }
        });

        formData.append('commentId', comment?._id);
        formData.append('taskId', taskId);
        formData.append('projectId', projectId);

        // Send to the API
        const res = await updateCommentImages(formData)
        if (res.status === 200) {
            console.log("Images updated");
        } else {
            throw new Error("Images update failed");
        }

        return res;
    };

    const removeImage = (imageIndex) => {
        setImages((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            // TODO: CALL UPDATE API HERE TO REMOVE IMAGE
            processImages(updatedImages);
            return updatedImages;
        });
    };

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setImages((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];
                // TODO: CALL UPDATE API HERE TO ADD IMAGES
                processImages(updatedImages);
                return updatedImages;
            });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    return (
        <StyledDiv
            theme={theme}
        >
            <ConditionalRender renderIf={disabled}>
                <div className='image-uploader'>
                    <div
                        className='dropzone'
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <span>
                            {text?.inventory?.details?.uploadImages}
                        </span>
                    </div>
                </div>
            </ConditionalRender>
            <ConditionalRender renderIf={!isEmpty(images)}>
                <RenderImages
                    images={images}
                    disabled={!disabled}
                    removeImage={removeImage}
                    openModal={openCarousel}
                    closeModal={closeModal}
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    isModalOpen={isModalOpen}
                />
            </ConditionalRender>
        </StyledDiv>
    );
};

export default CommentWithImages;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from './HoverPopup';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'


const StyledDiv = styled.span`
display: flex;
    svg {
        border-bottom: 1px solid ${props => props.theme === 'dark' ? colors.secondary : colors.black};
        padding-bottom: 2px;
    }

`;

const Info = ({
    text,
    id,
    className,
    placement
}) => {
    const { theme } = useTheme();

    return (
        <StyledDiv theme={theme}>
            <HoverPopup
                btnClassName={className}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                disabled
                className='popup'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    background: 'transparent',
                    border: 'none',
                    hoverBackground: 'transparent'
                }}
                placement={placement}
                id={id}
                text={text}
            >
                <FontAwesomeIcon
                    icon={faInfo}
                    style={{
                        color: theme === 'dark' ? colors.secondary : colors.black,
                        fontSize: '0.8rem'
                    }}
                />
            </HoverPopup>
        </StyledDiv>
    )
}

export default Info
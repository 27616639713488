import React from 'react'
import Switch from '@mui/material/Switch';
import { useTheme } from '../context/ThemeContext'
import colors from '../globalStyles.scss'
import styled from 'styled-components'
import { useLanguage } from '../context/LanguageContext';

const StyledDiv = styled.div`
    .error {
        color: ${colors.red};
        font-size: 0.8em;
    }
`

const EnabledDisabledToggle = ({
    disabled,
    handleDisabledToggle,
    isEditing
}) => {

    const { theme } = useTheme();
    const { text } = useLanguage();

    return (
        <StyledDiv
            theme={theme}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                marginBottom: '1em'
            }}>
            {
                isEditing && (
                    <p className={'small error'}>
                        {text?.editToggle?.unsaved}
                    </p>
                )
            }
            <div className='align-self-end'>
                <Switch
                    checked={!disabled}
                    onChange={handleDisabledToggle}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <span
                    style={{
                        fontFamily: colors.openSans,
                        color: disabled ? (theme === 'dark' ? colors.primary : colors.black) : (theme === 'dark' ? colors.white : colors.black)
                    }}
                >{disabled ? text?.editToggle?.off : text?.editToggle?.on}
                </span>
            </div>
        </StyledDiv>
    )
}

export default EnabledDisabledToggle
import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledDiv = styled.div`
    padding: 20px;
    h1 {
        font-size: 1.5rem;
    }
        h4, h2 {
            font-size: 1.2rem;
            margin-bottom: 10px;
            text-align: left;
        }
        p {
            font-size: 1rem;
            margin-bottom: 10px;
            text-align: left;
        }

        ul {
            li {
                margin-bottom: 10px;
                text-align: left; 
            }
        }s

`


const CookiesPolicy = () => {
    const { language } = useLanguage();
    return (
        <StyledDiv>
            <div className='flex justify-space-between w-100'>
                <h1>
                    {language === 'es' ? 'Política de Cookies' : 'Cookies Policy'}
                </h1>
                <p className='mr-md'>
                    {language === 'es' ? 'Última actualización: 04 de julio de 2024' : 'Last updated: July 04, 2024'}
                </p>
            </div>
            <hr />
            <p>
                {language === 'es' ?
                    'Esta Política de Cookies explica qué son las Cookies y cómo las usamos. Debe leer esta política para que pueda entender qué tipo de cookies usamos, o la información que recopilamos usando Cookies y cómo se usa esa información. Esta Política de Cookies ha sido creada con la ayuda de la' :
                    'This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used. This Cookies Policy has been created with the help of the.'
                }
                <a href="https://www.freeprivacypolicy.com/free-cookies-policy-generator/" target="_blank" rel='noreferrer'>
                    {language === 'es' ? 'Generador de Política de Cookies Gratis' : 'Free Cookies Policy Generator'}
                </a>
            </p>
            <p className='mb-md mt-md'>
                {language === 'es' ?
                    'Las Cookies no suelen contener ninguna información que identifique personalmente a un usuario, pero la información personal que almacenamos sobre Usted puede estar vinculada a la información almacenada en y obtenida de Cookies. Para obtener más información sobre cómo usamos, almacenamos y mantenemos segura su información personal, consulte nuestra Política de Privacidad.' :
                    'Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.'
                }
            </p>
            <p className='mb-md'>
                {language === 'es' ?
                    'No almacenamos información personal sensible, como direcciones postales, contraseñas de cuentas, etc. en las Cookies que usamos.' :
                    'We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.'
                }
            </p>
            <h2 className='mb-md'>
                {language === 'es' ? 'Interpretación y Definiciones' : 'Interpretation and Definitions'}
            </h2>
            <hr />
            <h4>
                {language === 'es' ? 'Interpretación' : 'Interpretation'}
            </h4>
            <p className='mb-md'>
                {language === 'es' ?
                    'Las palabras cuya letra inicial está en mayúscula tienen los significados que se definen bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.' :
                    'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.'
                }
            </p>
            <h4>
                {language === 'es' ? 'Definiciones' : 'Definitions'}
            </h4>
            <p>
                {language === 'es' ? 'Para los propósitos de esta Política de Cookies:' : 'For the purposes of this Cookies Policy:'}
            </p>
            <ul>
                <li><strong>{language === 'es' ? 'Empresa' : 'Company'}</strong> {language === 'es' ? '(referido como "la Empresa", "Nosotros" o "Nuestro" en esta Política de Cookies) se refiere a ardidflow.com.' : '(referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to ardidflow.com.'}</li>
                <li><strong>{language === 'es' ? 'Cookies' : 'Cookies'}</strong> {language === 'es' ? 'significa pequeños archivos que se colocan en Su computadora, dispositivo móvil o cualquier otro dispositivo por un sitio web, que contienen detalles de su historial de navegación en ese sitio web entre sus muchos usos.' : 'means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.'}</li>
                <li><strong>{language === 'es' ? 'Sitio Web' : 'Website'}</strong> {language === 'es' ? 'se refiere a ardidflow.com, accesible desde ' : 'refers to ardidflow.com, accessible from '}<a href="https://ardidflow.com" rel="external nofollow noopener" target="_blank">https://ardidflow.com</a></li>
                <li><strong>{language === 'es' ? 'Usted' : 'You'}</strong> {language === 'es' ? 'significa el individuo que accede o usa el Sitio Web, o una empresa, o cualquier entidad legal en nombre de la cual dicho individuo está accediendo o usando el Sitio Web, según corresponda.' : 'means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.'}</li>
            </ul>
            <h2 className='mt-md'>
                {language === 'es' ? 'El uso de las Cookies' : 'The use of the Cookies'}
            </h2>
            <hr />
            <h4>
                {language === 'es' ? 'Tipo de Cookies que Usamos' : 'Type of Cookies We Use'}
            </h4>
            <p>
                {language === 'es' ? 'Las Cookies pueden ser "Persistentes" o "De Sesión". Las Cookies Persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las Cookies de Sesión se eliminan tan pronto como cierra su navegador web.' : 'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.'}
            </p>
            <p>
                {language === 'es' ? 'Usamos tanto Cookies de sesión como persistentes para los propósitos que se detallan a continuación:' : 'We use both session and persistent Cookies for the purposes set out below:'}
            </p>
            <ul>
                <li>
                    <p><strong>{language === 'es' ? 'Cookies necesarias / esenciales' : 'Necessary / Essential Cookies'}</strong></p>
                    <p>{language === 'es' ? 'Tipo: Cookies de Sesión' : 'Type: Session Cookies'}</p>
                    <p>{language === 'es' ? 'Administradas por: Nosotros' : 'Administered by: Us'}</p>
                    <p>{language === 'es' ? 'Propósito: Estas Cookies son esenciales para proporcionarle servicios disponibles a través del Sitio Web y para permitirle usar algunas de sus características. Ayudan a autenticar a los usuarios y prevenir el uso fraudulento de cuentas de usuario. Sin estas Cookies, los servicios que ha solicitado no pueden ser proporcionados, y solo usamos estas Cookies para proporcionarle esos servicios.' : 'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.'}</p>
                </li>
                <li>
                    <p><strong>{language === 'es' ? 'Cookies de Funcionalidad' : 'Functionality Cookies'}</strong></p>
                    <p>{language === 'es' ? 'Tipo: Cookies Persistentes' : 'Type: Persistent Cookies'}</p>
                    <p>{language === 'es' ? 'Administradas por: Nosotros' : 'Administered by: Us'}</p>
                    <p>{language === 'es' ? 'Propósito: Estas Cookies nos permiten recordar las elecciones que hace cuando usa el Sitio Web, como recordar sus datos de inicio de sesión o su preferencia de idioma. El propósito de estas Cookies es ofrecerle una experiencia más personal y evitar que tenga que volver a ingresar sus preferencias cada vez que use el Sitio Web.' : 'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.'}</p>
                </li>
            </ul>
            <h4 className='mt-md'>
                {language === 'es' ? 'Sus Opciones Respecto a las Cookies' : 'Your Choices Regarding Cookies'}
            </h4>
            <p>
                {language === 'es' ? 'Si prefiere evitar el uso de Cookies en el Sitio Web, primero debe deshabilitar el uso de Cookies en su navegador y luego eliminar las Cookies guardadas en su navegador asociadas con este sitio web. Puede utilizar esta opción para evitar el uso de Cookies en cualquier momento.' : 'If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.'}
            </p>
            <p>
                {language === 'es' ? 'Si no acepta nuestras Cookies, puede experimentar algunos inconvenientes en su uso del Sitio Web y algunas características pueden no funcionar correctamente.' : 'If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.'}
            </p>
            <p>
                {language === 'es' ? 'Si desea eliminar las Cookies o indicar a su navegador web que elimine o rechace las Cookies, visite las páginas de ayuda de su navegador web.' : 'If You\'d like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.'}
            </p>
            <ul>
                <li>
                    <p>{language === 'es' ? 'Para el navegador web Chrome, visite esta página de Google: ' : 'For the Chrome web browser, please visit this page from Google: '}<a href="https://support.google.com/accounts/answer/32050" rel="external nofollow noopener" target="_blank">https://support.google.com/accounts/answer/32050</a></p>
                </li>
                <li>
                    <p>{language === 'es' ? 'Para el navegador web Internet Explorer, visite esta página de Microsoft: ' : 'For the Internet Explorer web browser, please visit this page from Microsoft: '}<a href="http://support.microsoft.com/kb/278835" rel="external nofollow noopener" target="_blank">http://support.microsoft.com/kb/278835</a></p>
                </li>
                <li>
                    <p>{language === 'es' ? 'Para el navegador web Firefox, visite esta página de Mozilla: ' : 'For the Firefox web browser, please visit this page from Mozilla: '}<a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" rel="external nofollow noopener" target="_blank">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></p>
                </li>
                <li>
                    <p>{language === 'es' ? 'Para el navegador web Safari, visite esta página de Apple: ' : 'For the Safari web browser, please visit this page from Apple: '}<a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" rel="external nofollow noopener" target="_blank">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
                </li>
            </ul>
            <p>
                {language === 'es' ? 'Para cualquier otro navegador web, visite las páginas web oficiales de su navegador web.' : 'For any other web browser, please visit your web browser\'s official web pages.'}
            </p>
            <h4 className='mt-md'>
                {language === 'es' ? 'Más Información sobre las Cookies' : 'More Information about Cookies'}
            </h4>
            <p>
                {language === 'es' ? 'Puede obtener más información sobre las cookies: ' : 'You can learn more about cookies: '}<a href="https://www.freeprivacypolicy.com/blog/cookies/" target="_blank" rel='noreferrer'>{language === 'es' ? 'Cookies: ¿Qué Hacen?' : 'Cookies: What Do They Do?'}</a>.
            </p>
            <h4 className='mt-md'>
                {language === 'es' ? 'Contáctenos' : 'Contact Us'}
            </h4>
            <p>
                {language === 'es' ? 'Si tiene alguna pregunta sobre esta Política de Cookies, puede contactarnos:' : 'If you have any questions about this Cookies Policy, You can contact us:'}
            </p>
            <ul className='mb-md'>
                <li>
                    {language === 'es' ? 'Por correo electrónico: ' : 'By email: '}
                    <a href="mailto:admin@ardidflow.com">
                        {'admin@ardidflow.com'}
                    </a>
                </li>
                <li>
                    {language === 'es' ? 'A través de nuestro formulario de contacto' : 'Through our contact form'}
                    <Link to='/contact-us'>
                        {language === 'es' ? ' Contacto' : ' Contact'}
                    </Link>
                </li>
            </ul>
        </StyledDiv>
    );
}

export default CookiesPolicy;

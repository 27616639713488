// mixins.js
import { css } from 'styled-components';

export const btnUnderline = (color) => `
&::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 50%;
    right: 50%;
    height: 2px;
    background-color: ${color};
    transition: all 0.3s ease-in-out;
  }
  &:hover::after,
  &.active::after {
    left: 0;
    right: 0;
  }
}
`;

export const sizes = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
};

export const media = {
  mobile: (...args) => css`
    @media (max-width: ${sizes.mobile}) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: ${sizes.tablet}) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (max-width: ${sizes.desktop}) {
      ${css(...args)}
    }
  `,
};
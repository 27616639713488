import request from '../request';

/**
 * Sends a ids request to the API.
 * @param {Object} productIds - The ids data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const deleteProducts = async (productIds) => {
    try {
        const { data, status } = await request('POST', '/inventory/delete-products', { productIds });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to delete products: ${error.message}`);
    }
};

export default deleteProducts;

import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createSubscription, retrievePaymentIntent, updatePremium } from '../utils/calls'
import { useNavigate } from "react-router-dom";
import Loader from "../Core/Loader";
import ConditionalRender from "../Core/ConditionalRender";
import Info from "./Info";
import { useTheme } from "../context/ThemeContext";
import FieldWithValidation from "../Core/FieldWithValidation";
import CallToAction from "../Core/CallToAction";
import { Form } from "react-final-form";
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../context/LanguageContext';

import {
    composeValidators,
    isRequired
} from "../utils/validators";

const StyledCardElement = styled(CardElement)`
  border-bottom: 1px solid black;
  border-bottom-color: ${({ theme }) => theme === 'light' ? colors.black : colors.white};
  padding-bottom: 10px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.StripeElement--invalid {
    border-color: ${colors.red};
  }
`;

const StyledForm = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: ${({ paymentIsLoading }) => paymentIsLoading ? 'hidden' : 'visible'};   
    form {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .FieldWithValidation {
            margin: 10px 0;
            .form-group {
                margin: 0;
            }
        }
    }
    .card-info {
        width: 100%;
        // margin: 10px 30px 30px;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
        .back {
            cursor: pointer;
            display: flex;
            span {
                margin-left: 5px;
                font-family: ${colors.alegreya};
                font-weight: bold;
            }
        }

    }
`

const PaymentForm = ({
    selectedPlan,
    setShowPayment,
    fetchUserDetails,
    startTrial,
    email,
    isPremium,
    fetchCompanyDetails,
    company
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();

    const stripe = useStripe();
    const elements = useElements();
    const { type, price, productName, value } = selectedPlan;
    const [paymentIsLoading, setPaymentIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        // Check if stripe and elements are properly initialized
        if (!stripe) {
            console.log('Stripe.js has not loaded.');
            setErrorMessage("Stripe.js has not loaded.");
            return;
        }

        if (!elements) {
            console.log('Elements have not been initialized.');
            setErrorMessage("Elements have not been initialized.");
            return;
        }

        const cardElement = elements?.getElement(CardElement);
        if (!cardElement) {
            console.log('Card Element not found.');
            setErrorMessage("Card Element not found.");
            return;
        }
        try {
            setPaymentIsLoading(true);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                    name: values?.name,
                    email,
                    address: {
                        line1: values?.addressLine1,
                        line2: values?.addressLine2,
                        city: values?.city,
                        state: values?.state,
                        postal_code: values?.zipCode,
                    },
                },
            });
            if (error) {
                setErrorMessage(error.message)
            }
            const payment_method_id = paymentMethod?.id;
            const res = await createSubscription({ email, payment_method_id, price, productName, type, startTrial });

            if (res.status !== 200) {
                setErrorMessage(text?.payment?.billing?.error)
            }

            const paymentIntentClientSecret = res?.data?.payment_intent_client_secret;
            const paymentIntentId = res?.data?.id;

            if (!paymentIntentClientSecret) {
                // this means it will go into trial first
                if (res.status === 200) {
                    fetchUserDetails(res?.data?.id).then(() => {
                        fetchCompanyDetails && fetchCompanyDetails(company?.id)
                        navigate("/");
                        window.location.reload();
                    });
                }

            } else {
                const paymentIntent = await retrievePaymentIntent(paymentIntentId);
                const paymentStatus = paymentIntent?.data?.paymentIntent?.status;

                if (paymentStatus === 'succeeded') {
                    const res = await updatePremium({ isPremium: true });

                    if (res.status === 200) {
                        fetchUserDetails(res?.data?.id).then(() => {
                            fetchCompanyDetails && fetchCompanyDetails(company?.id)
                            navigate("/");
                            window.location.reload();
                        });
                    } else {
                        console.error('Updating premium status failed"')
                    }
                } else if (paymentStatus === 'processing') {
                    setPaymentIsLoading(true);
                } else {
                    console.error('Payment error')
                }
            }
        } catch (error) {
            setErrorMessage(text?.payment?.billing?.error)
            console.error(error);
        } finally {
            setPaymentIsLoading(false);
        }
    };

    const isRequiredValidator = composeValidators(
        isRequired(text?.payment?.billing?.validations?.required)
    )

    useEffect(() => {
        console.log('CardElement mounted');
        return () => {
            console.log('CardElement unmounted');
        };
    }, []);

    // if (paymentIsLoading) return (
    //     <div style={{
    //         height: '80vh',
    //         width: '100%'
    //     }}>
    //         <Loader count={1} />
    //     </div>
    // )


    return (
        <div className='PaymentForm__wrapper'>
            <ConditionalRender renderIf={paymentIsLoading} isLoading={paymentIsLoading}>
                <Loader />
            </ConditionalRender>
            <StyledForm paymentIsLoading={paymentIsLoading}>
                <h3>
                    {text?.payment?.billing?.title}
                </h3>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <FieldWithValidation
                                validate={isRequiredValidator}
                                label={text?.payment?.billing?.name}
                                name="name"
                                component={"input"}
                                type="text"
                                block
                            />
                            <FieldWithValidation
                                validate={isRequiredValidator}
                                label={text?.payment?.billing?.address1}
                                name="addressLine1"
                                component={"input"}
                                type="text"
                                block
                            />
                            <FieldWithValidation
                                label={text?.payment?.billing?.address2}
                                name="addressLine2"
                                component={"input"}
                                type="text"
                                block
                            />
                            <FieldWithValidation
                                validate={isRequiredValidator}
                                label={text?.payment?.billing?.city}
                                name="city"
                                component={"input"}
                                type="text"
                                block
                            />
                            <FieldWithValidation
                                validate={isRequiredValidator}
                                label={text?.payment?.billing?.state}
                                name="state"
                                component={"input"}
                                type="text"
                                block
                            />
                            <FieldWithValidation
                                validate={isRequiredValidator}
                                label={text?.payment?.billing?.zip}
                                name="zipCode"
                                component={"input"}
                                type="text"
                                block
                            />
                            <div className='card-info'>
                                <StyledCardElement
                                    theme={theme}
                                    options={{
                                        iconStyle: "solid",
                                        style: {
                                            base: {
                                                iconColor: "#c4f0ff",
                                                color: theme === 'light' ? colors.black : colors.white,
                                                fontWeight: 500,
                                                fontFamily: colors.roboto,
                                                fontSize: "1rem",
                                                fontSmoothing: "antialiased",
                                                ":-webkit-autofill": {
                                                    color: "#fce883",
                                                },
                                                "::placeholder": {
                                                    color: "#87bbfd",
                                                },
                                            },
                                            invalid: {
                                                iconColor: colors.red,
                                                color: colors.red,
                                            },
                                        },
                                        hidePostalCode: true
                                    }
                                    }

                                />
                            </div>
                            {
                                errorMessage && <div className='small error'> {errorMessage} </div>
                            }
                            <div className='buttons'>
                                <div className='back' onClick={() => setShowPayment(false)}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> <span> {text?.payment?.billing?.back} </span>
                                </div>
                                <CallToAction btnStyle={{
                                    backgroundColor: colors.green
                                }} text={text?.payment?.billing?.button} type='submit' disabled={!stripe} />
                            </div>
                        </form>
                    )}
                />
            </StyledForm>
            <Info paymentIsLoading={paymentIsLoading} selectedPlan={selectedPlan} startTrial={startTrial} />
        </div>
    );
};

export default PaymentForm
import React from 'react'
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { createAnnouncement } from '../utils/calls';
import MultiFields from '../Core/MultiFields';
import FormActions from '../Core/FormActions';
import colors from '../globalStyles.scss'


const CreateAnnouncement = ({
    fetchAnnouncements
}) => {
    const navigate = useNavigate();


    const onSubmit = async (values) => {
        const res = await createAnnouncement(values)
        try {
            if (res.status === 200) {
                navigate('/announcements');
                fetchAnnouncements()
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <MultiFields
                            name="title"
                            component="input"
                            type="text"
                            label="Title"
                            block
                            style={{
                                margin: '15px 0'
                            }}
                        />
                        <MultiFields
                            name="body"
                            component="textarea"
                            type="text"
                            label="Body"
                            block
                            style={{
                                margin: '15px 0'
                            }}
                        />
                        <FormActions
                            type="submit"
                            onClick={form.reset}
                            resetForm={form.reset}
                            submitText={"Save"}
                            secondaryText={"Reset"}
                            form={form}
                            btnStyle={{
                                color: colors.blue
                            }}
                        />
                    </form>
                )}
            />
        </div>
    )
}

export default CreateAnnouncement
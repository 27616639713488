import React from 'react'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import classnames from 'classnames'
import useScreenSize from '../context/useScreenSize';


const StyledSelectableBox = styled.div`
        margin-top: 0.5em;
        padding: 1em;
        background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightGray) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray)};
        background: ${props => props.showLightColors && colors.backgroundSecondary};
        color: ${colors.white};
        transition: background-color 0.1s;
        &.pointer {
            &:hover {
                background-color: ${colors.background};
            }
        }

`

const SelectableBox = ({
    children,
    className,
    onClick,
    showLightColors,
    ...rest
}) => {
    const { theme } = useTheme();

    return (
        <StyledSelectableBox
            {...rest}
            theme={theme}
            showLightColors={showLightColors}
            onClick={onClick && onClick}
            className={classnames(className, {
                'SelectableBox': true,
                'pointer': onClick,
            })}
        >
            {children}
        </StyledSelectableBox >
    )
}

export default SelectableBox
import styled from 'styled-components';
import colors from '../../globalStyles.scss'

export const FieldStyles = styled.div`
    align-items: center;
    justify-content: space-between;
    caret-color: ${props => props.showLightColors && colors.black};
    .custom-label {
        flex 1;
        font-size: 1rem;
        display: flex;
        font-family: ${colors.openSans};
    }
    .custom-field {
        flex: 2;
        display: flex;
        flex-direction: column;
        textarea {
            background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightBlue) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray)};
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
            color: ${(props) => props.showLightColors && colors.black};
            border-bottom: ${(props) => props.value ? '1px solid' + colors.black : '1px solid black'};
            border-bottom-color: ${props => props.theme === 'dark' ? colors.white : colors.black};
            border-bottom-color: ${props => props.showLightColors && colors.black};
            background: ${props => props.showLightColors && colors.darkGray};
            border-radius: 0;
        }
        textarea.error_message {
            border-bottom: ${(props) => props.value || (props.type === 'number' && props.value === 0) ? '1px solid' + colors.blue : '1px solid' + colors.red};
        }
        textarea.error_message:focus {
            border-bottom: ${(props) => props.value || (props.type === 'number' && props.value === 0) ? '1px solid' + colors.blue : '1px solid' + colors.red};
        }
        textarea:focus {
            outline: 0;
            border-bottom: 1px solid ${colors.black};
            border-bottom-color: ${props => props.theme === 'dark' ? colors.white : colors.black};
            border-bottom-color: ${props => props.showLightColors && colors.black};

          }
        .react-tel-input  {
            .form-control {
                width: 100%;
            }
        }
    }
`
import React from 'react';
import MultiFields from '../MultiFields';
import { useLanguage } from '../../context/LanguageContext';
import { useTheme } from '../../context/ThemeContext';
import styled from 'styled-components';
import colors from '../../globalStyles.scss';

const StyledTimeEditFields = styled.div`
    background: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
    background: ${props => props.showLightColors && colors.lightGray};
    color: ${props => props.theme === 'dark' ? colors.white : colors.black};
    color: ${props => props.showLightColors && colors.black};
    padding: 1rem;
    margin-bottom: 1rem;
    input {
        caret-color: ${props => props.theme === 'dark' ? colors.black : colors.black};
        color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        color: ${props => props.showLightColors && colors.black};
    }
`;

const TimeEditFields = ({
    id,
    label,
    initialTimeInMilliseconds,
    showLightColors = true,
    showDays = false,
    setIsEditing
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();

    // Convert initial time from milliseconds to days, hours, and minutes for display
    const initialDays = Math.floor(initialTimeInMilliseconds / 86400000);
    const initialHours = Math.floor((initialTimeInMilliseconds % 86400000) / 3600000);
    const initialMinutes = Math.floor((initialTimeInMilliseconds % 3600000) / 60000);

    const validateDays = value => {
        const days = parseInt(value, 10);
        if (days < 0) {
            return text?.logs?.editForm?.invalidDay;
        }
        return undefined;
    };

    const validateHours = value => {
        const hours = parseInt(value, 10);
        if (hours < 0 || hours > 23) {
            return text?.logs?.editForm?.invalidHour;
        }
        return undefined;
    };

    const validateMinutes = value => {
        const minutes = parseInt(value, 10);
        if (isNaN(minutes) || minutes < 0 || minutes > 59) {
            return text?.logs?.editForm?.atleast;
        }
        return undefined;
    };

    return (
        <StyledTimeEditFields showLightColors={showLightColors} theme={theme} className='flex flex-column w-100'>
            <label className='b flex'>
                {label}
            </label>
            <div className='flex items-center'>
                {(initialDays || showDays) > 0 && (
                    <MultiFields
                        className='field-style'
                        name={`days_${id}`}
                        label={text?.logs?.editForm?.days}
                        component="number"
                        type="number"
                        validate={validateDays}
                        block
                        min={'0'}
                        initialValue={initialDays}
                        setIsEditing={setIsEditing}
                        showLightColors={showLightColors}
                        wrapperStyles={{ marginRight: '1rem' }}
                    />
                )}
                <MultiFields
                    className='field-style'
                    name={`hours_${id}`}
                    label={text?.logs?.editForm?.hours}
                    component="number"
                    type="number"
                    validate={validateHours}
                    block
                    min={'0'}
                    initialValue={initialHours}
                    setIsEditing={setIsEditing}
                    showLightColors={showLightColors}
                    wrapperStyles={{ marginRight: '1rem' }}
                />
                <MultiFields
                    className='field-style'
                    name={`minutes_${id}`}
                    label={text?.logs?.editForm?.minutes}
                    component="number"
                    type="number"
                    validate={validateMinutes}
                    block
                    initialValue={initialMinutes}
                    setIsEditing={setIsEditing}
                    showLightColors={showLightColors}
                />
            </div>
        </StyledTimeEditFields>
    );
};

export default TimeEditFields;

import { useEffect, useContext } from 'react';
import { GlobalContext } from './GlobalContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { getToken } from "../utils/auth";

const useAuthRedirect = () => {
    const { logoutUser } = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            logoutUser();
        }
    }, [navigate, location]);
};

export default useAuthRedirect;
import request from '../request';

/**
 * Sends a user login request to the API.
 * @param {Object} loginData - The user login data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const resendPin = async ({ email, pin }) => {
    try {
        const { data, status } = await request('POST', '/users/resend-pin', { email, pin });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to login user: ${error.message}`);
    }
};

export default resendPin;

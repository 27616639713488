import React, { useState, useEffect } from 'react'
import ConditionalRender from '../Core/ConditionalRender';
import HoverPopup from '../Core/HoverPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'react-final-form';
import PaginatedData from '../Core/PaginatedData';
import Checkbox from '../Core/Inputs/CheckBox';
import Wrapper from '../Core/Wrapper';
import colors from '../globalStyles.scss';
import SelectableBox from '../Core/SelectableBox';
import CustomTable from '../Core/Tables/CustomTable';
import { useTheme } from '../context/ThemeContext'
import { employeesColumns, assignedEmployeesColumns } from '../Invetory/columns';
import ConfirmModal from '../Core/ConfirmModal';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import SearchInput from '../Core/Inputs/SearchInput';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import {
    removeEmployees
} from '../utils/calls'
import { useLanguage } from '../context/LanguageContext';
import { truncate } from 'lodash';

const StyledDiv = styled.div`
.EmployeesContainer {
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        a, span {
            color: ${colors.white};
        }
    }
}
`

const EmployeesContainerExpanded = ({
    employees,
    filteredEmployees,
    user,
    company,
    fetchCompanyEmployees,
    isProject,
    project,
    showLightColors,
    employeesIsLoading,
    checkboxSelection = true,
    isDetailsProject,
    userRole,
    isDashboard
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { isDesktop, isTablet } = useScreenSize();


    const [searchQuery, setSearchQuery] = useState('');
    const [employeesList, setEmployeesList] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const assignedIds = new Set(project?.assignedEmployees.map(emp => emp.id));
        const formattedEmployees = isDetailsProject ? filteredEmployees : employees;

        const sortedEmployees = formattedEmployees && [...formattedEmployees].sort((a, b) => {
            if (assignedIds.has(a.id) && !assignedIds.has(b.id)) {
                return -1;
            }
            if (!assignedIds.has(a.id) && assignedIds.has(b.id)) {
                return 1;
            }
            return 0;
        });

        if (sortedEmployees?.length === 0) {
            setTableData([]);
        } else {
            setTableData(sortedEmployees);
        }
    }, [employees, project, employeesIsLoading]);


    const [error, setError] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const assignedIds = new Set(project?.assignedEmployees.map(emp => emp.id));
        const formattedEmployees = isDetailsProject ? filteredEmployees : employees;


        const sortedEmployees = formattedEmployees && [...formattedEmployees].sort((a, b) => {
            if (assignedIds.has(a.id) && !assignedIds.has(b.id)) {
                return -1;
            }
            if (!assignedIds.has(a.id) && assignedIds.has(b.id)) {
                return 1;
            }
            return 0;
        });

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            const filteredEmployees = sortedEmployees.filter((employee) => {
                const fullName = `${employee?.firstName} ${employee?.lastName}`.toLowerCase();
                return fullName.includes(query);
            });
            setEmployeesList(filteredEmployees);
            setTableData(filteredEmployees);
        } else {
            setEmployeesList(sortedEmployees);
            setTableData(sortedEmployees);
        }
    }, [searchQuery, employees, project, employeesIsLoading]);


    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const [toggleModal, setToggleModal] = useState(false);

    const handleDeleteSelection = async () => {
        try {
            const res = await removeEmployees(selectedIds);
            if (res.status === 200) {
                setToggleModal(!toggleModal)
                fetchCompanyEmployees(company?.id)
                setSelectedIds([])
                setSelectedItems([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <StyledDiv>
            <Wrapper
                isLoading={employeesIsLoading}
            >
                <div
                    className='EmployeesContainer mb-md'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                    <div className='flex flex-column'>
                        <HoverPopup
                            style={{
                                marginRight: '1rem',
                                alignSelf: 'flex-start'
                            }}
                            onClick={() => setShowFilters(!showFilters)}
                            placement={'top'}
                            id='toggle-filters-popup-employee'
                            text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                        </HoverPopup>

                        <ConditionalRender renderIf={showFilters}>
                            <div className='flex flex-column filters mt-md'>
                                <SearchInput
                                    width={((isDesktop && isDetailsProject) || (isDesktop && isDashboard)) ? '50%' : ((isTablet && isDetailsProject) || (isTablet && isDashboard)) ? '50%' : '100%'}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeHolder={text?.employees?.landing?.search}
                                />

                            </div>
                        </ConditionalRender>
                    </div>

                    <ConditionalRender renderIf={truncate}>
                        <div className='flex flex-column align-start mt-md'>
                            <CustomTable
                                showLightColors
                                keyField="_id"
                                columns={isDetailsProject ? assignedEmployeesColumns({ theme, text, userRole, project, navigate }) : employeesColumns({ theme, text, navigate })}
                                rows={tableData?.filter((x) => x?._id !== user?._id)}
                                theme={theme}
                                noDataText={text?.employees?.landing?.noData}
                                onRowSelectionChange={handleRowSelectionChange}
                                checkboxSelection={checkboxSelection}
                            />
                            <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                                <div style={{
                                    color: colors.red,
                                    fontFamily: colors.openSans,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    marginBottom: '5px'
                                }} onClick={() => setToggleModal(true)} >
                                    {text?.employees?.landing?.deleteSelected}
                                </div>

                                <ConfirmModal
                                    onClick={handleDeleteSelection}
                                    text={text?.inventory?.landing?.table?.modal?.button}
                                    toggle={() => setToggleModal(!toggleModal)}
                                    isOpen={toggleModal}

                                    btnStyles={{
                                        color: colors.red
                                    }}

                                >
                                    <div>
                                        <h4 style={{ marginBottom: '15px' }}>
                                            {text?.inventory?.landing?.table?.modal?.title}
                                        </h4>
                                        <ul>
                                            {selectedItems?.map((x) => {
                                                return (
                                                    <li style={{ fontSize: '0.9rem' }}>
                                                        - {`${x?.firstName} ${x?.lastName}`}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </ConfirmModal>
                            </ConditionalRender>

                        </div>
                    </ConditionalRender>

                    <ConditionalRender renderIf={!isEmpty(error)}>
                        <span className='error'>
                            {error}
                        </span>
                    </ConditionalRender>

                </div>
            </Wrapper>
        </StyledDiv>
    )
}

export default EmployeesContainerExpanded
import request from '../request';

/**
 * Sends a fields request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateTemplate = async (templateId, body = {}) => {
    try {
        const { data, status } = await request('PATCH', `/inventory/update-template/${templateId}`, body);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to update template: ${error.message}`);
    }
};

export default updateTemplate;

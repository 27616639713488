import request from '../request';

/**
 * Sends a fields request to the API.
 * @param {Object} columns - The columns data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const deleteAllNotifications = async () => {
    try {
        const { data, status } = await request('DELETE', `/notification//delete-all-notifications`);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to delete notifications: ${error.message}`);
    }
};

export default deleteAllNotifications;

import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {String} projectId - the projectId from the url param
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const completeProject = async (projectId, updatedValues) => {
    try {
        const response = await request('PUT', `/project/complete-project/${projectId}`, updatedValues);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to update project: ${error.message}`);
    }
};

export default completeProject;

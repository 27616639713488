import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import moment from 'moment';

export const separateData = (arr) => {
    let stringsObjectsArray = [];
    let numbersObjectsArray = [];

    for (let i = 0; i < arr.length; i++) {
        let stringsObj = cloneDeep(arr[i]);
        let numbersObj = cloneDeep(arr[i]);

        for (let key in arr[i]) {
            const value = arr[i][key];

            if (typeof value === 'string' && isNaN(Number(value))) {
                delete numbersObj[key];
            } else if (typeof value === 'number' || !isNaN(Number(value))) {
                delete stringsObj[key];
            }

            if (key === 'inventoryName' || key === 'productId' || key === 'inventoryCategory') {
                numbersObj[key] = value;
                stringsObj[key] = value;
            }

            if (typeof value === 'object' && value !== null) {
                let stringsAdvancedObj = {};
                let numbersAdvancedObj = {};

                for (let advancedKey in value) {
                    const advancedValue = value[advancedKey];
                    const cleanedKey = advancedKey.replace(/^[^-]*-/, ''); // Remove prefix before the first hyphen

                    if (typeof advancedValue === 'string' && isNaN(Number(advancedValue))) {
                        stringsAdvancedObj[cleanedKey] = advancedValue;
                        numbersObj[cleanedKey] = advancedValue; // Move non-numeric to parent level
                    } else if (typeof advancedValue === 'number' || !isNaN(Number(advancedValue))) {
                        numbersAdvancedObj[cleanedKey] = advancedValue;
                    } else if (typeof advancedValue === 'object' && advancedValue !== null && advancedValue.value !== undefined) {
                        numbersAdvancedObj[cleanedKey] = { value: advancedValue.value };
                    }
                }

                // Keep numeric fields in advanceFields in numbersObj
                if (Object.keys(numbersAdvancedObj).length > 0) {
                    numbersObj[key] = { ...numbersAdvancedObj };
                } else {
                    delete numbersObj[key]; // Clean up empty advanceFields in numbersObj
                }

                // Ensure stringsObj retains only the appropriate values
                if (Object.keys(stringsAdvancedObj).length > 0) {
                    stringsObj[key] = { ...stringsAdvancedObj };
                } else {
                    delete stringsObj[key]; // Clean up empty advanceFields in stringsObj
                }
            }
        }

        stringsObjectsArray.push(stringsObj);
        numbersObjectsArray.push(numbersObj);
    }

    return [stringsObjectsArray, numbersObjectsArray];
};

export const combineData = (value, formattedData) => {
    const combinedDataMap = new Map();

    formattedData.forEach((item) => {
        const key = _.get(item, value)?.toLowerCase();

        if (key) {
            if (combinedDataMap.has(key)) {
                const combinedItem = combinedDataMap.get(key);

                Object.keys(item).forEach((itemKey) => {
                    if (itemKey !== value) {
                        const itemValue = _.get(item, itemKey);

                        if (typeof itemValue === 'object' && itemValue !== null) {
                            if (itemValue.value !== undefined) {
                                // Directly handle cases like inventoryQuantity: {value: 3}
                                const combinedValue = _.get(combinedItem, `${itemKey}.value`, 0);
                                const newValue = itemValue.value;

                                if (!isNaN(combinedValue) && !isNaN(newValue)) {
                                    _.set(combinedItem, `${itemKey}.value`, combinedValue + newValue);
                                }
                            } else {
                                // Handle nested objects like advanceFields
                                if (!_.get(combinedItem, itemKey)) {
                                    _.set(combinedItem, itemKey, {});
                                }

                                Object.keys(itemValue).forEach((subKey) => {
                                    const combinedSubValue = _.get(combinedItem, `${itemKey}.${subKey}.value`, 0);
                                    const newSubValue = _.get(item, `${itemKey}.${subKey}.value`, 0);

                                    if (!isNaN(combinedSubValue) && !isNaN(newSubValue)) {
                                        _.set(combinedItem, `${itemKey}.${subKey}.value`, combinedSubValue + newSubValue);
                                    }
                                });
                            }
                        }
                    }
                });
            } else {
                combinedDataMap.set(key, _.cloneDeep(item));
            }
        }
    });

    const newArray = Array.from(combinedDataMap.values()).map((x) => {
        if (x.advanceFields) {
            Object.entries(x.advanceFields).forEach(([key, value]) => {
                const iso8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;
                const militaryTime = /^([01]\d|2[0-3]):([0-5]\d)$/;

                if (iso8601.test(value)) {
                    x.advanceFields[key] = moment.utc(value).format('MM-DD-YYYY');
                }

                if (militaryTime.test(value)) {
                    x.advanceFields[key] = moment(value, 'HH:mm').format('hh:mm A');
                }
            });
        }
        return x;
    });

    return deepParseNumbers(newArray);
};

export const deepParseNumbers = (item) => {
    if (Array.isArray(item)) {
        return item.map(deepParseNumbers);
    } else if (item !== null && typeof item === 'object') {
        return Object.fromEntries(
            Object.entries(item).map(([key, value]) => {
                if (key === 'value' && typeof value === 'string') {
                    let number = Number(value);
                    return [key, isNaN(number) ? value : number];
                } else {
                    return [key, deepParseNumbers(value)];
                }
            })
        );
    } else {
        let number = Number(item);
        return isNaN(number) ? item : number;
    }
};

export const findMaxNumber = (formattedData, dataCheckedKeys) => {
    // First, filter out the unwanted keys
    const filteredData = formattedData.map(deepFilterFields);

    let maxNumber = Number.MIN_SAFE_INTEGER;

    dataCheckedKeys.forEach(key => {
        let values = deepSearch(filteredData, `${key}.value`); // Search specifically for the .value key
        values = values.map(value => parseInt(value)).filter(value => !isNaN(value)); // Parsing string numbers to actual numbers
        let maxKeyValue = Math.max(...values);
        if (!isNaN(maxKeyValue) && maxKeyValue > maxNumber) {
            maxNumber = maxKeyValue;
        }
    });

    return maxNumber;
};

const deepFilterFields = (obj) => {
    return Object.entries(obj).reduce((newObj, [k, v]) => {
        // Recursively deep filter if the value is an object
        if (v && typeof v === 'object') {
            newObj[k] = deepFilterFields(v);
            // filter out unwanted field types
        } else if (
            !k.startsWith('phone') &&
            !k.startsWith('date') &&
            !k.startsWith('time')) {
            // Only add to new object if the key does not start with 'phone', 'date'
            newObj[k] = isNaN(Number(v)) ? v : Number(v); // Parse numbers stored as strings
        }
        return newObj;
    }, {});
};

const deepSearch = (obj, key) => {
    let values = [];

    const helper = (obj, key) => {
        if (obj.hasOwnProperty(key)) {
            values.push(obj[key]);
        }

        for (let i = 0; i < Object.keys(obj).length; i++) {
            if (typeof obj[Object.keys(obj)[i]] === "object") {
                helper(obj[Object.keys(obj)[i]], key);
            }
        }
    };

    helper(obj, key);
    return values;
};

export const toPercent = (decimal, fixed = 0) => `${parseFloat((decimal * 100)?.toFixed(fixed))}%`;

export const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio, 2);
};

export const sortByKeyWithHighestValue = (dataCheckedKeys, formattedData) => {
    let highestKey = findKeyWithHighestValue(dataCheckedKeys, formattedData);

    // Additional check to handle case where no highest key was found
    if (!highestKey) return formattedData;

    return formattedData.sort((a, b) => {
        let aValue = (a[highestKey] !== undefined) ? a[highestKey] :
            (a.advanceFields?.[highestKey] !== undefined) ? a.advanceFields[highestKey] : 0;

        let bValue = (b[highestKey] !== undefined) ? b[highestKey] :
            (b.advanceFields?.[highestKey] !== undefined) ? b.advanceFields[highestKey] : 0;

        return aValue - bValue;  // sort in ascending order
    });
}


const findKeyWithHighestValue = (dataCheckedKeys, formattedData) => {
    let maxValues = {};

    for (let key of dataCheckedKeys) {
        for (let obj of formattedData) {
            // Check if the key exists at the top level and get its value
            if (obj[key] !== undefined && (maxValues[key] === undefined || obj[key] > maxValues[key])) {
                maxValues[key] = obj[key];
            }
            // Check if the key exists within advanceFields and get its value
            else if (obj.advanceFields?.[key]?.value !== undefined &&
                (maxValues[key] === undefined || obj.advanceFields[key].value > maxValues[key])) {
                maxValues[key] = obj.advanceFields[key].value;
            }
        }
    }

    // Sort keys by their maximum value
    let sortedKeys = Object.entries(maxValues).sort((a, b) => b[1] - a[1]);

    // Safety check to ensure sortedKeys isn't empty
    if (sortedKeys.length === 0) {
        console.error("No matching keys with maximum values found.");
        return null;
    }

    return sortedKeys[0][0];
}


export const sortKeysByData = (dataCheckedKeys, formattedData) => {
    const maxValueForKeys = {};

    for (let key of dataCheckedKeys) {
        let maxVal = -Infinity;

        for (let obj of formattedData) {
            let value;

            if (obj[key] !== undefined) {
                value = obj[key];
            } else if (obj.advanceFields && obj.advanceFields[key] !== undefined) {
                value = obj.advanceFields[key];
            }

            if (value !== undefined) {
                if (value > maxVal) maxVal = value;
            }
        }

        maxValueForKeys[key] = maxVal;
    }

    // Sort the dataCheckedKeys array based on the determined max values in descending order
    dataCheckedKeys.sort((a, b) => maxValueForKeys[a] - maxValueForKeys[b]);

    return dataCheckedKeys;
}

export const findMaxKey = (dataCheckedKeys, formattedData) => {
    let maxVal = -Infinity;  // Initializing with a value guaranteed to be lower than any expected value
    let resultKey = null;    // Key associated with maxVal

    // Go through each object in the data
    for (let obj of formattedData) {
        for (let key of dataCheckedKeys) {
            let value;
            // Check if key exists in the object or its nested advanceFields object
            if (obj[key] !== undefined) {
                value = obj[key];
            } else if (obj.advanceFields && obj.advanceFields[key] !== undefined) {
                value = obj.advanceFields[key];
                key = `advanceFields.${key}`;  // prepend 'advanceFields.' to the key
            }

            // If the found value is greater than our current maxVal, update maxVal and resultKey
            if (value !== undefined && value > maxVal) {
                maxVal = value;
                resultKey = key;
            }
        }
    }

    return resultKey;
}
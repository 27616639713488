import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {String} token - the token from the url param
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const resetPassword = async (password, token) => {
    try {
        const { data, status } = await request('PATCH', `/users/reset-password`, { password, token });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to reset users password: ${error.message}`);
    }
};

export default resetPassword;

import jsPDF from 'jspdf';

const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
};

const generatePDF = (logs, dates, formatDate) => {
    const doc = new jsPDF();
    const lineHeight = 10;
    let yPosition = 20;
    const pageHeight = doc.internal.pageSize.height;

    doc.setFontSize(12);
    doc.text("Daily Logs", 20, yPosition);
    yPosition += lineHeight;

    logs?.forEach((log, index) => {
        if (yPosition + lineHeight * 5 > pageHeight) {
            doc.addPage();
            yPosition = 20; // Reset yPosition for the new page
        }

        doc.text(`Project: ${log?.projectName}`, 20, yPosition);
        yPosition += lineHeight;
        doc.text(`User: ${log?.userName}`, 20, yPosition);
        yPosition += lineHeight;
        doc.text(`Clock In: ${formatDate(log?.clockIn, 'MM-DD-YYYY')}`, 20, yPosition); // Format using moment
        yPosition += lineHeight;
        doc.text(`Total Time: ${formatTime(log?.totalTime)}`, 20, yPosition);
        yPosition += lineHeight;
        doc.text(`Log: ${log?.dailyLog}`, 20, yPosition);
        yPosition += lineHeight + 5; // Add extra space between entries
    });

    const pdfData = doc.output('blob');
    const url = URL.createObjectURL(pdfData);
    const a = document.createElement('a');
    a.href = url;
    a.download = `daily-logs-${dates.startDate.toISOString().split('T')[0]}-to-${dates.endDate.toISOString().split('T')[0]}.pdf`;
    a.click();
    URL.revokeObjectURL(url);
};

export default generatePDF;
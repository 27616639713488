import request from '../request';

/**
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateDailyLog = async (projectId, dailyLog, time, id, isOlderThanToday) => {
    try {
        const { data, status } = await request('POST', '/time/update-daily-log', { projectId, dailyLog, time, id, isOlderThanToday });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to update logs: ${error.message}`);
    }
};

export default updateDailyLog;

import request from '../request';

/**
 * Sends a contact request to the API.
 * @param {Object} contactData - The contact data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const contactUs = async (contactData) => {
    try {
        const { data, status } = await request('POST', '/users/contact-us', contactData);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to create product: ${error.message}`);
    }
};

export default contactUs;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import styled from 'styled-components'
import { Field } from 'react-final-form';
import CheckBox from './CheckBox';
import { isEmpty } from 'lodash';
import ConditionalRender from '../ConditionalRender';
import classnames from 'classnames';
import Autocomplete from "react-google-autocomplete";
import colors from '../../globalStyles.scss'
import { useTheme } from '../../context/ThemeContext'
import { useLanguage } from '../../context/LanguageContext';

const StyledSearch = styled.div`
.form-group {
    input {
        border: none;
        border-bottom: ${(props) => props.value ? '1px solid' + colors.blue : '1px solid black'};
        border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
        border-bottom-color: ${props => props.showLightColors && colors.black};
        padding: 0.2rem;
        background-color: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightGray) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.white)};
        background-color: ${props => props.showLightColors && colors.white};

    }
        label {
            display: flex;
            transition: all 0.225s ease;
        }
        .custom-check {
            border-bottom: none;
        }
        input.error_message {
            border-bottom: ${(props) => props.value || (props.type === 'number' && props.value === 0) ? '1px solid' + colors.blue : '1px solid' + colors.red};
        }
    }
    input:focus,
    textarea:focus {
        outline: 0;
        border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
        border-bottom-color: ${props => props.showLightColors && colors.black};
    }
    input:focus ~ label,
    textarea:focus ~ label {
    font-size: 0.75rem;
    color: #999;
    top: -10px;
    transition: all 0.225s ease;
    }
    .input-wrapper {
        position: relative;
    }
    .suggestion {
        margin-top: 15px;
        li {
            cursor: pointer;
            background: ${colors.lighterGray};
            padding: 15px;
        }
    }
`


const LocationSearchInput = ({
    input,
    meta,
    type,
    label,
    width,
    block,
    style,
    disabled,
    wrapperStyles,
    setIsEditing,
    showMapLabel = 'Show Map',
    showLightColors,
    ...props
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [autocompleteValue, setAutocompleteValue] = useState('');
    const [infoWindowOpen, setInfoWindowOpen] = useState(true);

    useEffect(() => {
        if (input.value) {
            setAutocompleteValue(input.value);
            fetchInitialLocation(input.value);
        }
        if (input.value === '') {
            setAutocompleteValue('');
            setSelectedLocation('')
        }
    }, [input.value]);

    const fetchInitialLocation = (address) => {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
                handleSelectLocation(results[0]);
            } else {
                console.error('Geocode was not successful for the following reason: ' + status);
            }
        });
    };

    useMemo(() => {
        setAutocompleteValue(input.value);
        fetchInitialLocation(input.value);
    }, [input.value])

    const handleSelectLocation = useCallback((place) => {
        if (place.formatted_address && place.geometry && place.geometry.location) {
            setSelectedLocation(place);
            input.onChange(place.formatted_address);
            setAutocompleteValue(place.formatted_address);
        }
    }, [input]);

    const handleMarkerClick = () => {
        setInfoWindowOpen(!infoWindowOpen); // Toggle InfoWindow open/close state
    };

    const mapCenter = useMemo(() => {
        const lat = parseFloat(selectedLocation?.geometry?.location?.lat());
        const lng = parseFloat(selectedLocation?.geometry?.location?.lng());
        return {
            lat,
            lng
        };
    }, [selectedLocation]);

    const onLoad = useCallback((map) => {
        if (selectedLocation) {

            map.panTo(new window.google.maps.LatLng(mapCenter?.lat, mapCenter?.lng));
        }
    }, [mapCenter, selectedLocation]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <StyledSearch
            className='FieldWithValidation field-style'
            value={input?.value || ''}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            theme={theme}
            showLightColors={showLightColors}
        >
            <div className="form-group wide" style={wrapperStyles}>
                <div className='input-wrapper flex flex-column'>
                    <div className='flex items-center'>
                        <div className='flex flex-column flex-one'>
                            <label htmlFor={type}>{label}</label>
                            <Autocomplete
                                className={classnames({
                                    'error_message': meta.error && meta.touched
                                })}
                                disabled={disabled}
                                value={autocompleteValue}
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                onPlaceSelected={(place) => {
                                    if (place?.geometry) {
                                        handleSelectLocation(place);
                                        setAutocompleteValue(place.formatted_address);
                                    }
                                }}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    setAutocompleteValue(value);
                                    setIsEditing && setIsEditing(true);
                                    if (value === '') {
                                        setSelectedLocation(null);
                                        input.onChange(value)
                                    }
                                }}
                                options={{ types: ['geocode'] }}
                                placeholder=''
                            />

                            {meta?.error && meta?.touched && (
                                <span className='small error'>{meta?.error}</span>
                            )}
                        </div>
                        <CheckBox
                            checked={showMap}
                            onChange={(e) => setShowMap(e.target.checked)}
                            checkStyle={{
                                width: '15px'
                            }}
                            label={showMapLabel}
                        />
                    </div>
                </div>
            </div>
            <ConditionalRender renderIf={!isEmpty(selectedLocation)}>
                {showMap && selectedLocation ? (
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '400px',
                            marginTop: '15px'
                        }}
                        center={mapCenter}
                        onLoad={onLoad}
                        mapTypeId="hybrid"
                        zoom={18}
                        options={{
                            mapTypeId: 'hybrid', // Explicitly set the map type here
                            disableDefaultUI: false, // disable default map UI
                            draggable: true, // disable dragging (panning)
                            keyboardShortcuts: false, // disable keyboard shortcuts
                            scaleControl: false, // disable scaling (zooming in/out) control
                            scrollwheel: false, // disable scrolling (zooming in/out) control
                            zoomControl: true, // disable zoom control buttons
                            fullscreenControl: false,
                            streetViewControl: false,
                            version: false,
                            styles: [
                                {
                                    featureType: 'all',
                                    elementType: 'labels',
                                    stylers: [{ visibility: 'off' }] // Hide all labels
                                },
                                {
                                    featureType: 'landscape',
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'off' }] // Hide landscapes
                                },
                                {
                                    featureType: 'poi',
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'off' }] // Hide points of interest
                                },
                                {
                                    featureType: 'road',
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'simplified' }] // Show roads, but not in full detail
                                },
                                {
                                    featureType: 'road',
                                    elementType: 'labels.text',  // Specify the road's text element to modify the road names separately
                                    stylers: [{ visibility: 'on' }]  // Show road names
                                },
                                {
                                    featureType: 'transit',
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'off' }] // Hide transit
                                },
                                {
                                    featureType: 'water',
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'off' }] // Hide water
                                },
                                {
                                    featureType: 'administrative',
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'off' }] // Hide administrative buildings
                                },
                                {
                                    featureType: 'landscape.man_made', // Show only man-made landscapes (e.g., your building)
                                    elementType: 'geometry',
                                    stylers: [{ visibility: 'on' }]
                                }
                            ],
                        }}
                    >
                        <MarkerF
                            position={mapCenter}
                            onClick={handleMarkerClick} // Toggle InfoWindow on marker click
                        >
                            {infoWindowOpen && (
                                <InfoWindowF position={mapCenter} onCloseClick={() => setInfoWindowOpen(!infoWindowOpen)}>
                                    <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedLocation?.formatted_address)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: colors.blue }}
                                    >
                                        {selectedLocation?.formatted_address}
                                    </a>
                                </InfoWindowF>
                            )}
                        </MarkerF>

                    </GoogleMap>
                ) : null}
            </ConditionalRender>
        </StyledSearch>
    );
};


const InternalLocationSearchInput = ({ name, label, validate, ...props }) => {
    return (
        <Field name={name} validate={validate} {...props}>
            {({ input, meta }) => {
                return (
                    <LocationSearchInput
                        input={input}
                        meta={meta}
                        label={label}
                        {...props}
                    />
                );
            }}
        </Field>
    );
}

export default React.memo(InternalLocationSearchInput);

import React, { useCallback, memo } from 'react'
import { Form } from 'react-final-form';
import AutocompleteDropdown from './Inputs/AutocompleteDropdown';
import SelectInput from './SelectInput';
import useScreenSize from '../context/useScreenSize';

const CustomSelect = memo(({
    handleSelectInputChange,
    options,
    name,
    placeHolder,
    className
}) => {
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const onSubmit = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form
                    className={className}
                    onSubmit={(e) => {
                        handleSubmit();
                    }}>
                    <SelectInput
                        isSearchable={true}
                        isClearable={true}
                        isValidNewOption={() => false}
                        placeholder={placeHolder}
                        name={name}
                        onChange={(event) => {
                            handleSelectInputChange(event);
                        }}
                        options={options}
                        noFormat
                        width={isDesktop ? 30 : isTablet ? 50 : 100}
                    />
                </form>
            )}
        />
    );
});

export default CustomSelect
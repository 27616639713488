import React, { useState, useRef, useEffect } from 'react'
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { updateJustStatus } from '../utils/calls/updateJustStatus'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'

const UpdateStatus = () => {
    const [message, setMessage] = useState("");
    const { theme } = useTheme();

    const location = useLocation();

    // Extract the uuid and status from the query string
    let { uuid, status } = queryString.parse(location.search);
    const hasRun = useRef(false);

    useEffect(() => {
        const updateStatus = async () => {
            try {
                const res = await updateJustStatus(uuid, status);
                if (res.status === 200) {
                    setMessage(res?.data?.message);
                } else {
                    setMessage(res.data.message);
                }
            } catch (error) {
                console.error('error', error);
                setMessage(error.message);
            }
        };

        if (uuid && status && !hasRun.current) {
            updateStatus();
            hasRun.current = true;
        } else if (!uuid && !status) {
            setMessage("No status found");
        }
    }, [uuid, status]);
    return (
        <div className='flex items-center justify-center' style={{
            color: theme === 'dark' ? colors.white : colors.black,
            fontSize: '1.2rem',
            fontFamily: colors.openSans,
            height: '100%'
        }}>
            <div>{message}</div>
        </div>
    );
}

export default UpdateStatus
import React, { useState } from "react";
import { Form } from "react-final-form";
import FieldWithValidation from "../Core/FieldWithValidation";
import { composeValidators, isRequired, minLength, matchesPattern } from "../utils/validators";
import FormActions from "../Core/FormActions";
import colors from "../globalStyles.scss";

import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { resetPassword } from "../utils/calls";

const PasswordReset = () => {
    const [emailSent, setEmailSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const location = useLocation();
    const navigate = useNavigate();

    // Extract the token from the query string
    const { token } = queryString.parse(location.search);

    const onSubmit = async ({ password }) => {
        try {
            const res = await resetPassword(password, token)
            if (res.status === 200) {
                setEmailSent(true);
                setTokenExpired(false)
                return setTimeout(() => navigate("/login"), 2000);
            } else {
                setEmailSent(false);
                setTokenExpired(true);
            }
        } catch (error) {
            setEmailSent(false);
            setTokenExpired(true);
        }
    };

    const passwordValidator = composeValidators(
        isRequired("Password is required"),
        minLength(8)("Password must be at least 8 characters long"),
        matchesPattern(/\d/, "Password must contain at least one number"),
        matchesPattern(/[a-z]/, "Password must contain at least one lowercase letter"),
        matchesPattern(/[\W_]/, "Password must contain at least one special character")
    );

    const confirmPasswordValidator = (form) => (value) => {
        if (form.getState().values.password !== value) {
            return "Passwords do not match";
        }
    };

    return (
        emailSent ? (
            <div style={{ color: colors.white }}>
                {'your password was changed successfully'}
            </div>
        ) : (
            <div className="PasswordReset wrapper">
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <FieldWithValidation
                                className='field-style'
                                validate={passwordValidator}
                                label="New Password"
                                name="password"
                                component={"input"}
                                togglePasswordVisibility={togglePasswordVisibility}
                                type={showPassword ? 'text' : 'password'}
                            />
                            <FieldWithValidation
                                className='field-style'
                                validate={confirmPasswordValidator(form)}
                                label="Confirm Password"
                                name="confirmPassword"
                                component={"input"}
                                togglePasswordVisibility={togglePasswordVisibility}
                                type={showPassword ? 'text' : 'password'}
                            />
                            <FormActions
                                type="submit"
                                callToActionText={"RESET PASSWORD"}
                                position='center'
                                disabled={submitting}
                                btnStyle={{
                                    border: `2px solid ${colors.black}`,
                                    color: colors.white,
                                    hoverBackgroundColor: colors.white,
                                    padding: '0.5em 1em',
                                    fontWeight: '600',
                                }}
                            />
                            <div className='error small'>
                                {
                                    tokenExpired && 'Token expired. Send email again.'
                                }
                            </div>
                        </form>
                    )}
                />
            </div>
        )
    );
};

export default PasswordReset;

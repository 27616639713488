import React, { useState, useEffect } from "react";
import OutsideClick from "../Core/OutsideClick";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { modifySortOrder } from '../utils/calls'
import { useTheme } from "../context/ThemeContext";
import { useLanguage } from "../context/LanguageContext";

import './styles.scss'
import colors from '../globalStyles.scss'

const ColumnDropdown = ({
    columns,
    handleColumnToggle,
    setInventoryColumns,
    isProject
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [columnsDuringDrag, setColumnsDuringDrag] = useState(columns?.filter((x) => x?.fieldName !== 'projectName' && isProject));
    const [selectedColumns, setSelectedColumns] = useState(
        columns?.filter((column) => column?.isActive)?.map((column) => column?.fieldName)
    );
    const [tempSelectedColumns, setTempSelectedColumns] = useState(selectedColumns);

    useEffect(() => {
        const activeColumns = columns?.filter((column) => column?.isActive)?.map((column) => column?.fieldName);
        setColumnsDuringDrag(columns?.filter((x) => x?.fieldName !== 'projectName'));
        setSelectedColumns(activeColumns);
        setTempSelectedColumns(activeColumns);
    }, [columns]);

    const reset = async () => {
        const activeColumns = columns?.filter((column) => column?.isActive)?.map((column) => column?.fieldName);
        handleColumnToggle(tempSelectedColumns).then(() => {
            setInventoryColumns(columns);
            setSelectedColumns(activeColumns);
            setColumnsDuringDrag(columns);
            setIsOpen(false);
        });
    }

    const toggleDropdown = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(prevIsOpen => !prevIsOpen)
    };

    const handleColumnClick = fieldName => {
        const isColumnSelected = tempSelectedColumns?.includes(fieldName);
        const newSelectedColumns = isColumnSelected
            ? tempSelectedColumns?.filter(column => column !== fieldName)
            : [...tempSelectedColumns, fieldName];
        setTempSelectedColumns(newSelectedColumns);
    };

    const handleApply = async () => {
        const columnsRes = await modifySortOrder(columnsDuringDrag);
        if (columnsRes.status === 200) {
            await reset();
        }
    };

    const handleCancel = () => {
        const activeColumns = columns?.filter((column) => column?.isActive)?.map((column) => column?.fieldName);
        setColumnsDuringDrag(columns);
        setInventoryColumns(columns);
        setSelectedColumns(activeColumns);
        setTempSelectedColumns(activeColumns);
        setIsOpen(true);
    };

    const onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const column = columnsDuringDrag.find(col => col.fieldName === draggableId);
        let newColumns = Array.from(columnsDuringDrag);
        newColumns.splice(source.index, 1);
        newColumns.splice(destination.index, 0, column);

        newColumns = newColumns.map((column, index) => ({ ...column, sortOrder: index.toString() }));
        setSelectedColumns(newColumns);
        setColumnsDuringDrag(newColumns);
    };

    const dropdownStyle = {
        position: "relative",
        display: "inline-block",
        background: theme === 'dark' ? colors.secondary : colors.white,
    };

    const menuStyle = {
        position: "absolute",
        top: "100%",
        right: 0,
        minWidth: "200px",
        background: theme === 'dark' ? colors.white : colors.black,
        boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.2)",
        zIndex: 999999,
        marginTop: '10px'
    };

    const itemStyle = {
        cursor: "grab",
        display: 'flex',
        alignItems: 'center',
        padding: "5px 10px",
        justifyContent: 'space-between',
        backgroundColor: colors.white,
    };

    const checkboxStyle = {
        marginRight: "5px",
        verticalAlign: "middle",
        appearance: "none",
        width: "16px",
        height: "16px",
        borderRadius: "3px",
        border: "1px solid #ccc",
        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
        display: "inline-block",
        position: "relative",
        cursor: 'pointer'
    };

    const checkmarkStyle = {
        position: "absolute",
        top: "3px",
        left: "4px",
        width: "8px",
        height: "4px",
        border: "solid white",
        borderWidth: "0 2px 2px 0",
        transform: "rotate(45deg)",
        borderColor: colors.blue
    };

    const buttonsStyle = {
        margin: '0',
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: colors.white,
    };

    return (
        <OutsideClick onClick={() => setIsOpen(false)}>
            <DragDropContext onDragEnd={onDragEnd}>

                <div className="column-dropdown" style={dropdownStyle}>
                    <button style={{
                        fontFamily: colors.openSans,
                        background: isOpen && colors.darkGray,
                        padding: '15px'
                    }} className="dropdown-toggle" onClick={toggleDropdown}>
                        {text?.inventory?.landing?.table?.columns?.title}
                    </button>
                    {isOpen && (
                        <div className="" style={menuStyle}>
                            <Droppable droppableId="columns">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {columnsDuringDrag?.map((column, index) => (
                                            <Draggable key={column.fieldName} draggableId={column.fieldName} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div
                                                            key={column.fieldName}
                                                            className="dropdown-item"
                                                            style={itemStyle}
                                                            onClick={() => column.fieldName !== 'inventoryName' && handleColumnClick(column.fieldName)}
                                                        >
                                                            <span style={{ ...checkboxStyle, background: column.fieldName === 'inventoryName' && colors.lightGray, cursor: column.fieldName === 'inventoryName' ? 'default' : 'pointer' }}>
                                                                {tempSelectedColumns?.includes(column.fieldName) && <span style={checkmarkStyle}></span>}
                                                            </span>
                                                            <p style={{
                                                                fontFamily: colors.openSans,
                                                                fontSize: '0.9rem',
                                                                color: theme === 'dark' ? colors.black : colors.black,
                                                            }}>
                                                                {column.fieldLabel}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>

                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>

                            <div className="dropdown-buttons" style={buttonsStyle}>
                                <hr />
                                <button className="reset-button" onClick={handleCancel}>
                                    {text?.inventory?.landing?.table?.columns?.reset}
                                </button>
                                <button className="apply-button" onClick={handleApply}>
                                    {text?.inventory?.landing?.table?.columns?.apply}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </DragDropContext >

        </OutsideClick >
    );
};

export default ColumnDropdown;

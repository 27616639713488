import React from 'react'
import SubmitModal from '../Core/SubmitModal';
import { isRequired, isValidEmail, composeValidators } from '../utils/validators';
import MultiFields from '../Core/MultiFields';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';
import {
    createClient
} from '../utils/calls'


const CreateNewClientContainer = ({
    isOpen,
    toggle,
    fetchAllClients,
    setNewClient,
    clients
}) => {
    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    // Validator to check if the project name already exists
    const emailExists = value => {
        const nameExists = clients?.some(
            client => client?.email?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()
        );
        return nameExists ? text?.client?.create?.emailExists : undefined;
    };

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )
    const emailValidator = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
        isValidEmail(text?.employees?.createNew?.validation?.validEmail),
        emailExists
    )

    const onSubmit = async (values) => {
        try {
            toggle()
            const res = await createClient(values)
            if (res.status === 200) {
                fetchAllClients()
                setNewClient && setNewClient(res.data)
                notify(text?.notificationsUI?.clients?.created, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    return (
        <SubmitModal
            onClick={onSubmit}
            text={text?.client?.create?.button}
            toggle={toggle}
            isOpen={isOpen}
            width={isDesktop ? '80%' : '100%'}
            height={isDesktop ? '80%' : '100%'}
            btnStyles={{
                color: colors.blue
            }}
        >
            <h4>
                {text?.client?.create?.title}
            </h4>

            <MultiFields
                className='field-style'
                name="name"
                component="input"
                type="text"
                label={text?.client?.create?.name}
                block
                validate={required}
                showLightColors
            />
            <MultiFields
                className='field-style'
                name="email"
                component="input"
                type="text"
                label={text?.client?.create?.email}
                block
                validate={emailValidator}
                showLightColors
            />
            <MultiFields
                className='field-style'
                name="phone"
                component="phone"
                type="text"
                label={text?.client?.create?.phone}
                block
                showLightColors
            />
            <MultiFields
                className='field-style'
                name="address"
                component="location"
                type="text"
                label={text?.client?.create?.address}
                block
                showLightColors
                showMapLabel={text?.client?.create?.showMapLabel}
            />
            <MultiFields
                className='field-style'
                name="notes"
                component="textarea"
                type="text"
                label={text?.client?.create?.notes}
                block
                showLightColors
            />
        </SubmitModal>
    )
}

export default CreateNewClientContainer
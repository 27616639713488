import request from '../request';

/**
 * Sends a fields request to the API.
 * @param {Object} widget - The widget data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const saveWidget = async (widget) => {
    try {
        const { data, status } = await request('POST', '/analytics/save', { widget });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to save widget: ${error.message}`);
    }
};

export default saveWidget;

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../context/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose } from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../../context/LanguageContext';
import colors from '../../globalStyles.scss';

const StyledDiv = styled.div`
    .read-view {
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.dark};
        padding: 8px;
        min-height: 2.5em;
        display: flex;
        align-items: center;
        word-break: break-word;
        border-bottom: ${(props) => props.isEditing ? `1px solid ${props.theme === 'dark' ? colors.white : colors.dark}` : 'none'};
        cursor: ${(props) => props.allowEdit ? 'pointer' : 'default'};
    }
    .edit-view {
        display: flex;
        align-items: center;
        input {
            width: 100%;
            padding: 8px;
            border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.dark};
            background: ${(props) => props.theme === 'dark' ? colors.dark : colors.white};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.dark};
            margin-right: 8px;
        }
        svg {
            margin-left: 1em;
            border: none;
            cursor: pointer;
            color: ${(props) => props.theme === 'dark' ? colors.dark : colors.black};
        }
        svg.cancel {
            color: ${colors.red};
        }
    }
`;

const EditableField = ({ allowEdit = true, value, onSave, onCancel, placeholder }) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(value);
    const [confirmedValue, setConfirmedValue] = useState(value); // Track the last confirmed value
    const wrapperRef = useRef(null);

    useEffect(() => {
        setEditValue(value); // Sync editValue with the latest value prop when it changes
        setConfirmedValue(value); // Sync confirmedValue with the latest value prop when it changes
    }, [value]);

    const save = (currentValue) => {
        setConfirmedValue(currentValue); // Update the confirmed value
        onSave(currentValue);
        setIsEditing(false);
    };

    const cancel = () => {
        setEditValue(confirmedValue); // Reset to the last confirmed value
        onCancel();
        setIsEditing(false);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            save(editValue); // Use the latest editValue at the time of click
        }
    };

    useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing, editValue]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            save(editValue);
        } else if (e.key === 'Escape') {
            cancel();
        }
    };

    return (
        <StyledDiv theme={theme} isEditing={isEditing} allowEdit={allowEdit} ref={wrapperRef}>
            {isEditing ? (
                <div className="edit-view">
                    <input
                        type="text"
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                        onKeyDown={handleKeyDown} // Handle save on Enter key press
                        autoFocus
                        placeholder={placeholder}
                    />
                    <div className='flex items-center'>
                        <FontAwesomeIcon className='save button' onClick={() => save(editValue)} icon={faSave} />
                        <FontAwesomeIcon className='cancel button' onClick={cancel} icon={faClose} />
                    </div>

                </div>
            ) : (
                <div className="read-view" onClick={() => allowEdit && setIsEditing(true)}>
                    {confirmedValue || placeholder}
                </div>
            )}
        </StyledDiv>
    );
};

export default EditableField;

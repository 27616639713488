import React from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import { renderLabel } from '../Dashboard/Charts/helperFunctions';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { renderLengthLabel, renderWeightLabel, renderVolumeLabel, } from '../utils/helperFunctions';
import RenderImages from '../Core/RenderImages';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../context/useScreenSize';
import moment from 'moment';
import colors from '../globalStyles.scss';


const InventoryQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    noOverlay,
    inventoryColumns
}) => {
    const { text, formatDate } = useLanguage();
    const { isDesktop } = useScreenSize();

    return (
        <ConfirmModal
            toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
            isOpen={isQuickViewOpen}
            noOverlay={noOverlay}
            shaddow
            width={isDesktop ? '50%' : '100%'}
            height={isDesktop ? '70%' : '100%'}
        >
            <div className='w-100'>
                <h4 className='mb-md'>{quickViewData?.inventoryName}</h4>
                <ConditionalRender renderIf={!isEmpty(quickViewData?.projectId)}>
                    <FieldWrapper
                        name={text?.tasks?.home?.filters?.project}
                        label={quickViewData?.projectId?.projectName}
                    />
                </ConditionalRender>
                <FieldWrapper
                    name={text?.inventory?.details?.form?.category}
                    label={quickViewData?.inventoryCategory}
                />
                <FieldWrapper
                    name={text?.inventory?.details?.form?.quantity}
                    label={
                        <div className='flex flex-column'>
                            <span>
                                {quickViewData?.inventoryQuantity?.value}
                            </span>
                            <ConditionalRender renderIf={quickViewData?.inventoryQuantity?.notification?.isChecked}>
                                <FieldWrapper
                                    name={`${text?.inventory?.details?.form?.nodifyMe} ${text?.inventory?.details?.form?.numberBellow}`}
                                    label={quickViewData?.inventoryQuantity?.notification?.value}
                                    noHr
                                />
                            </ConditionalRender>
                        </div>
                    }
                />

                <ConditionalRender renderIf={!isEmpty(quickViewData?.advanceFields)}>
                    <h4 className='mb-md'>{text?.inventory?.details?.form?.title}</h4>
                    {
                        Object.entries(quickViewData?.advanceFields || {}).map(([key, value], index) => {
                            const keyName = key.split('-')[0];
                            const keyValue = key.split('-')[1];
                            console.log('keyName', keyName);
                            console.log('keyValue', keyValue);

                            if (keyName === 'number') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderLabel(inventoryColumns, keyValue)}
                                        label={
                                            <div className='flex flex-column'>
                                                <span>{value?.value}</span>
                                                <ConditionalRender renderIf={value?.notification?.isChecked}>
                                                    <FieldWrapper
                                                        name={`${text?.inventory?.details?.form?.nodifyMe} ${text?.inventory?.details?.form?.numberBellow}`}
                                                        label={value?.notification?.value}
                                                        noHr
                                                    />
                                                </ConditionalRender>
                                            </div>
                                        }
                                    />
                                );
                            } else if (keyName === 'length') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderLabel(inventoryColumns, keyValue)}
                                        label={`${value.split('-')[0]} ${renderLengthLabel(value.split('-')[1], text)}`}
                                    />
                                );
                            } else if (keyName === 'volume') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderLabel(inventoryColumns, keyValue)}
                                        label={`${value.split('-')[0]} ${renderVolumeLabel(value.split('-')[1], text)}`}
                                    />
                                );
                            } else if (keyName === 'weight') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderLabel(inventoryColumns, keyValue)}
                                        label={`${value.split('-')[0]} ${renderWeightLabel(value.split('-')[1], text)}`}
                                    />
                                );
                            }

                            return (
                                <FieldWrapper
                                    key={index}
                                    name={renderLabel(inventoryColumns, keyValue)}
                                    label={value}
                                />
                            );
                        })
                    }
                </ConditionalRender>




                <ConditionalRender renderIf={!isEmpty(quickViewData?.images)}>
                    <RenderImages
                        images={quickViewData?.images}
                        // roleName={user?.roleName}
                        disabled={true}
                        // isEditing={isEditing}
                        // removeImage={removeImage}
                        openModal={() => null}
                    // closeModal={closeModal}
                    // setActiveIndex={setActiveIndex}
                    // activeIndex={activeIndex}
                    // isModalOpen={isModalOpen}
                    />
                </ConditionalRender>
            </div>
        </ConfirmModal>
    )
}

export default InventoryQuickView

import request from '../request';

/**
 * get single task request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getTask = async (id) => {
    try {
        const { data, status } = await request('GET', `/tasks/${id}`);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get task: ${error.message}`);
    }
};

export default getTask;

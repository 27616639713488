import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useNavigate } from "react-router-dom";

import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { isEmpty } from 'lodash'
import HoverPopup from '../Core/HoverPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import CallToAction from '../Core/CallToAction';
import { useLocation } from "react-router-dom";
import TimeTracker from '../Core/TimeTracker'
import ConditionalRender from '../Core/ConditionalRender'
import SelectInput from '../Core/SelectInput'
import { Form } from 'react-final-form';
import { TimerContext } from '../context/TimeContext';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import moment from 'moment';
import classnames from 'classnames';
import { getRunningTimesheet } from '../utils/calls'
import LandingProjectContainer from '../Project/LandingProjectContainer';
import useScreenSize from '../context/useScreenSize';

const StyledSideMenu = styled.div`

    &.desktop {
        position: relative;
        width: ${(props) => props.isCollapsed ? '0' : '350px'};
        border-right: 10px solid ${props => props.theme === 'dark' ? colors.secondary : colors.background};
        transform: translateX(${(props) => props.isCollapsed ? '-100%' : '0'});
        height: 100%;
    }
    &.tablet, &.phone {
        position: fixed;
        top: 5%;
        height: 95%;
        width: 100%;
        z-index: 2;
        transform: translateX(${(props) => props.isCollapsed ? '-100%' : '0'});
    }
    padding: ${(props) => props.isCollapsed ? '0' : '0'};
    overflow: ${(props) => props.isCollapsed ? 'hidden' : 'visible'};
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${(props) => props.theme === 'dark' ? colors.background : colors.white};
    .wrapper {
        margin: 1rem;
        height: 100%;
    }
    .wrapper.scroll-container::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    .timeTracker {
        padding: ${(props) => props.isCollapsed ? '0' : '1em'};

    }
`;

const SideMenu = ({
    isLoggedIn,
    isCompanyPremium,
    projects,
    user,
    collapsed,
    setCollapsed
}) => {
    const {
        timesheet,
        isRunning
    } = useContext(TimerContext);
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [initialProject, setInitialProject] = useState();
    const [projectId, setProjectId] = useState(timesheet?.projectId);
    const navigate = useNavigate();



    const location = useLocation();
    const isHomePage = location.pathname === "/" || location.pathname === "/logs";

    const getGreetingMessage = () => {
        const currentHour = moment().hour();
        const userName = user ? `${user.firstName} ${user.lastName}` : '';

        let greeting;
        if (currentHour < 12) {
            greeting = text?.greetings?.morning;
        } else if (currentHour < 18) {
            greeting = text?.greetings?.afternoon;
        } else {
            greeting = text?.greetings?.evening;
        }

        return `${greeting} ${userName}`;
    };

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('timeTracker'));

        const fetchData = async () => {
            if (isEmpty(storedData)) {
                try {
                    const res = await getRunningTimesheet();
                    if (res.status === 200) {
                        const project = projects?.find(p => p._id === res.data?.projectId);
                        setInitialProject({
                            label: project?.projectName,
                            value: project?._id
                        });
                        setProjectId(project?._id);
                    } else if (res.status === 404) {
                        // Handle 404 status: set to first project if available
                        if (projects && projects?.length > 0) {
                            setInitialProject({
                                label: projects[0]?.projectName,
                                value: projects[0]?._id
                            });
                            setProjectId(projects[0]?._id);
                        }
                    }
                } catch (error) {
                    // Handle other errors or set default project
                    if (projects && projects?.length > 0) {
                        setInitialProject({
                            label: projects[0]?.projectName,
                            value: projects[0]?._id
                        });
                        setProjectId(projects[0]?._id);
                    }
                }
            }
        };

        fetchData();
    }, [projects]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('timeTracker'));

        if (storedData?.isRunning && !isEmpty(projects)) {
            const projectId = storedData?.projectId;
            setProjectId(projectId);
            const project = projects?.find(p => p._id === projectId);
            setInitialProject({
                label: project?.projectName,
                value: project?._id
            });
        } else {
            // In case there is no running project, but we still have the projectId stored
            const projectId = storedData?.projectId;
            const project = projects?.find(p => p._id === projectId);

            // If we find the project with the stored projectId, use that, otherwise set to empty
            if (project) {
                setInitialProject({
                    label: project.projectName,
                    value: project._id
                });
                setProjectId(project?._id);

            }
        }
    }, [projects, isRunning]);

    const handleChange = (e) => {
        setProjectId(e?.value)
    }

    return (
        <div
            className={classnames({
                'w-0': collapsed,
            })}
            style={{
                position: 'relative',
                height: '100%',
            }}>
            <StyledSideMenu
                className={isDesktop ? 'desktop side' : isTablet ? 'tablet side' : 'phone side'}
                isCollapsed={collapsed}
                theme={theme}
            >
                <div>
                    <img
                        src={user?.profilePhoto}
                        alt={'profile'}
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            margin: '1em auto',
                            display: 'block'
                        }}
                    />
                    <p style={{
                        marginTop: '1em',
                    }}>
                        {getGreetingMessage()}
                    </p>
                </div>
                <div className='scroll-container wrapper'>
                    <LandingProjectContainer
                        user={user}
                        projects={projects}
                        setCollapsed={setCollapsed}
                    />
                </div>

                <ConditionalRender renderIf={isDesktop && !isHomePage && isLoggedIn && isCompanyPremium}>
                    <div className='timeTracker'>
                        <Form
                            onSubmit={() => null}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <SelectInput
                                        width={100}
                                        menuPlacement='top'
                                        name='selectProject'
                                        isClearable={false}
                                        isSearchable
                                        isValidNewOption={() => false}
                                        initialValue={initialProject}
                                        controlWidth='100%'
                                        options={projects?.filter((x) => !x?.isCompleted)?.map((x) => {
                                            return {
                                                label: x?.projectName,
                                                value: x?._id
                                            }
                                        })}
                                        onChange={(e) => handleChange(e)}
                                        disabled={isRunning}
                                        style={{
                                            marginBottom: '1em',
                                            width: '100%'
                                        }}
                                    />
                                </form>
                            )}
                        />
                        <TimeTracker
                            disabled={!projectId}
                            projectId={projectId}
                            isSideMenu
                            isFlex={false}
                            projects={projects}
                            user={user}
                        />
                        <CallToAction
                            type="button"
                            onClick={() => {
                                navigate(`/logs`)
                            }}
                            text={text?.timeTracker?.manageAllLogs}
                        />
                    </div>
                </ConditionalRender>

                <ConditionalRender renderIf={!isDesktop}>
                    <HoverPopup
                        style={{
                            background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                            position: 'absolute',
                            right: '20px',
                            top: '50%',
                            padding: '0.5em',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        placement={'right'}
                        onClick={() => setCollapsed(!collapsed)}
                        id='toggle-side-two-popup'
                    >
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    </HoverPopup>
                </ConditionalRender>
            </StyledSideMenu>
            <ConditionalRender renderIf={isDesktop}>
                <HoverPopup
                    placement={'right'}
                    id={'collapsed'}
                    text={collapsed ? text?.sidebar?.expand : text?.sidebar?.collapse}
                    style={{
                        color: theme === 'dark' ? colors.secondary : colors.background,
                        cursor: 'pointer',
                        position: 'absolute',
                        background: 'transparent',
                        border: 'none',
                        hoverBackground: 'transparent',
                        right: collapsed ? '-25px' : '-10px',
                        top: '50%'
                    }}
                >
                    <FontAwesomeIcon
                        style={{
                            fontSize: '50px'
                        }}
                        onClick={() => setCollapsed(!collapsed)}
                        icon={collapsed ? faCaretRight : faCaretLeft}
                    />
                </HoverPopup>
            </ConditionalRender>
        </div>
    )
}

export default SideMenu
import React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components'
import { useTheme } from '../../context/ThemeContext'
import classnames from 'classnames';
import colors from '../../globalStyles.scss'

const StyledSearch = styled.div`
    input {
        width: ${(props) => props.width ? props.width : '100%'} !important;
        border: none;
        border-radius: 0;
        padding-left: 0.5em;
        outline: none;
        background: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
        border-bottom: ${(props) => props.value ? '1px solid' + colors.blue : '1px solid ' + colors.black};
        border-bottom-color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        &::placeholder {
            color: ${props => props.theme === 'dark' ? colors.darkGray : colors.black};
        }
            &:focus {
                outline: none;
                background: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
                color: ${props => props.theme === 'dark' ? colors.white : colors.black};
                box-shadow: none;
            }
            border: none;
            border-bottom: 1px solid ${colors.black};
            border-bottom-color: ${props => props.theme === 'dark' ? colors.white : colors.black};
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};

        }
    }
`


const SearchInput = ({
    value,
    onChange,
    placeHolder,
    className,
    style,
    width = '100%'
}) => {
    const { theme } = useTheme();

    return (
        <StyledSearch
            value={value}
            className={classnames({
                [className]: className,
                'SearchInput': true,
            })}
            style={style}
            theme={theme}
            width={width}
        >
            <Input
                type="text"
                value={value}
                onChange={onChange}
                placeholder={placeHolder}
            />
        </StyledSearch>
    )
}

export default SearchInput
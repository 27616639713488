import React, { useState } from 'react'
import styled from 'styled-components';
import ConfirmModal from '../Core/ConfirmModal';
import { useNavigate } from "react-router-dom";
import { renderLabel } from '../Dashboard/Charts/helperFunctions';
import { renderLengthLabel, renderWeightLabel, renderVolumeLabel } from '../utils/helperFunctions';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import globalStyles from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';


const StyledDiv = styled.div`
    display: flex;
    .categories {
        border-right: 1px solid ${globalStyles.lightGray};
        .category {
            font-family: ${globalStyles.openSans};
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: ${globalStyles.blue};
            }
        }
    }
    .name {
        margin-left: 1rem;
        cursor: pointer;
        &:hover {
            color: ${globalStyles.blue};
        }
    }

`;

const CategoryContainer = ({
    categories,
    inventory,
    inventoryColumns
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();


    const [filteredInventory, setFilteredInventory] = useState([]);
    const [selectProduct, setSelectedProduct] = useState({});
    const [isOpen, setIsOpen] = useState(false)


    const countItemsInCategory = (category) => {
        return inventory.filter(item => item.inventoryCategory === category).length;
    };

    // Function to filter inventory by category
    const filterCategory = (category) => {
        const filtered = inventory.filter(item => item.inventoryCategory === category);
        setFilteredInventory(filtered);
    };

    const handleSelectProduct = (product) => {
        setIsOpen(!isOpen)
        setSelectedProduct(product)
    }


    return (
        <StyledDiv>
            <div className='flex flex-column flex-one categories'>
                {
                    categories.map((category) => {
                        const itemCount = countItemsInCategory(category);
                        return (
                            <ConditionalRender renderIf={itemCount > 0}>
                                <span
                                    onClick={() => filterCategory(category)}
                                    key={category}
                                    className='category align-self-start mt-md'
                                >
                                    {`${category} - x${itemCount}`}
                                </span>
                            </ConditionalRender>
                        );
                    })
                }
            </div>
            <div className='w-50 flex flex-column'>
                {
                    filteredInventory?.map((x) => {
                        return (
                            <span
                                onClick={() => handleSelectProduct(x)}
                                className='name align-self-start mt-sm'
                            >
                                {x?.inventoryName}
                            </span>
                        )
                    })
                }
            </div>

            <ConfirmModal
                className='flex flex-column'
                onClick={() => navigate(`/inventory/details?id=${selectProduct?.productId}`)}
                text={text?.category?.button}
                toggle={() => {
                    setIsOpen(!isOpen);
                    setSelectedProduct({})
                }}
                isOpen={isOpen}
                btnStyles={{
                    color: globalStyles.blue
                }}
            >
                <div className='flex flex-column w-100'>
                    <div className='flex'>
                        <span style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                        }}>
                            {text?.category?.name}
                        </span>
                        <span>
                            {selectProduct?.inventoryName}
                        </span>
                    </div>
                    <div>
                        <span style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                        }}>
                            {text?.category?.category}
                        </span>
                        <span>
                            {selectProduct?.inventoryCategory}
                        </span>
                    </div>
                    <div>
                        <span style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                        }}>
                            {text?.category?.quantity}
                        </span>
                        <span>
                            {selectProduct?.inventoryQuantity?.value || 0}
                        </span>
                    </div>
                    <ConditionalRender renderIf={!isEmpty(selectProduct?.advanceFields)}>
                        <h3 className='mt-md'>
                            {text?.category?.advanceFields}
                        </h3>
                        <div>
                            {selectProduct?.advanceFields && Object.entries(selectProduct?.advanceFields)?.map(([key, value]) => {
                                const name = key.split('-');
                                return (
                                    <div>
                                        <span style={{
                                            marginRight: '1rem',
                                            fontWeight: 'bold'
                                        }}>
                                            {renderLabel(inventoryColumns, name[1])}
                                        </span>
                                        <span>
                                            {

                                                name[0] === 'length' ?
                                                    `${value.split('-')[0]} ${renderLengthLabel(value.split('-')[1], text)}`
                                                    : name[0] === 'weight' ?
                                                        `${value.split('-')[0]} ${renderWeightLabel(value.split('-')[1], text)}`
                                                        : name[0] === 'volume' ?
                                                            `${value.split('-')[0]} ${renderVolumeLabel(value.split('-')[1], text)}`
                                                            : name[0] === 'number' ?
                                                                value.value
                                                                :
                                                                value
                                            }
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </ConditionalRender>
                </div>
            </ConfirmModal>
        </StyledDiv>
    )
}

export default CategoryContainer
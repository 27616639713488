import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { useToast } from './ToastContext';
import { useLanguage } from './LanguageContext';
import { GlobalContext } from './GlobalContext';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, userId }) => {
    const { setHasNewNotifications } = useContext(GlobalContext);
    const { notify } = useToast();
    const { text } = useLanguage();
    const [notifications, setNotifications] = useState([]);
    const ws = useRef(null);
    const websocketUrl = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        ws.current = io(websocketUrl, {
            transports: ['websocket'],
        });

        ws.current.on('connect', () => {
            console.log('WebSocket connection established');
            ws.current.emit('register', userId); // Send user ID in initial message
        });

        ws.current.on('notifications', (existingNotifications) => {
            setNotifications(existingNotifications);
            const oldNotificationCount = parseInt(localStorage.getItem('notificationCount'), 10) || 0;
            if (
                (existingNotifications.length > oldNotificationCount) || (existingNotifications.length > 0 && oldNotificationCount === 0)
            ) {
                setHasNewNotifications(true);
            }
            localStorage.setItem('notificationCount', JSON.stringify(existingNotifications.length));
        });

        ws.current.on('notification', (newNotification) => {
            notify(text?.notificationsUI?.notification, 'info');
            setNotifications((prev) => {
                const updatedNotifications = [newNotification, ...prev];
                localStorage.setItem('notificationCount', JSON.stringify(updatedNotifications.length));
                return updatedNotifications;
            });
        });

        ws.current.on('disconnect', () => {
            console.log('WebSocket connection closed');
        });

        ws.current.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
        });

        return () => {
            if (ws.current) {
                ws.current.disconnect();
            }
        };
    }, [websocketUrl, userId, setHasNewNotifications, notify, text]);

    return (
        <WebSocketContext.Provider value={{
            notifications,
            setNotifications
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};

import React, { useState, useEffect, useMemo } from 'react'
import { Form, Field } from 'react-final-form';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { getEmployee, updateEmployee } from '../utils/calls';
import ConditionalRender from '../Core/ConditionalRender';
import CallToAction from '../Core/CallToAction';
import FieldWithValidation from '../Core/FieldWithValidation';
import ConfirmModal from '../Core/ConfirmModal';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import EnabledDisabledToggle from '../Core/EnabledDisabledToggle';
import { useToast } from '../context/ToastContext';
import styled from 'styled-components';
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';

import CheckBox from '../Core/Inputs/CheckBox';
import {
    forgotPassword,
    removeEmployee
} from '../utils/calls'

import FormActions from '../Core/FormActions';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';


const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    .project-wrapper {
        display: flex;
        flex-direction: column;
    }
`;

const DetailsEmployeeContainer = ({
    projects,
    company,
    fetchCompanyProjects,
    projectsIsLoading,
    fetchCompanyEmployees,
    userRole
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();
    const { notify } = useToast();

    const [memberId, setMemberId] = useState('');
    const [employee, setEmployee] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(!isOpen)
    }

    const location = useLocation();
    const { id } = queryString.parse(location.search);
    const [error, setError] = useState('');

    const handleDisabledToggle = () => {
        setDisabled(!disabled);
    };

    const emailValidator = composeValidators(
        isRequired(text?.profile?.validation?.required),
        isValidEmail(text?.profile?.validation?.validEmail),
    )
    const required = composeValidators(
        isRequired(text?.settings?.validation?.required),
    )

    const fetchEmployeeData = async () => {
        try {
            setIsLoading(true);
            const res = await getEmployee(memberId);
            if (res.status === 200) {
                setEmployee(res.data);
                setIsLoading(false);
            }
        } catch (error) {
            setError(text?.employees?.details?.notAvailable);
            setIsLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEmployeeData();
        setError('')
    }, [memberId]);

    useMemo(() => {
        setMemberId(id);
    }, [id]);

    const onSubmit = async (values) => {
        setIsLoading(true);
        const updatedValues = {
            ...values
        };
        try {
            const res = await updateEmployee(memberId, updatedValues);
            if (res.status === 200) {
                fetchCompanyProjects(company?.id);
                setIsEditing(false);
                setIsLoading(false);
                fetchCompanyEmployees()
                fetchEmployeeData()
                notify(text?.notificationsUI?.employee?.saved, 'success');
            } else {
                setIsLoading(false);
                setError('There was an error updating the employee');
                notify(text?.notificationsUI?.employee?.error, 'error');
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setError(error.message);
            notify(`Error: ${error.message}`, 'error');
        }
    };

    const submitPasswordReset = async () => {
        try {
            const res = await forgotPassword(employee?.email, '24 hours');
            if (res.status === 200) {
                setEmailSent(true);
                notify(text?.employees?.details?.emailSent, 'success');
            } else {
                setEmailSent(false);
                notify(text?.employees?.details?.errorSendingEmail, 'error');
            }
        } catch (error) {
            setEmailSent(false);
            notify(text?.employees?.details?.errorSendingEmail, 'error');
        }
    }

    const handleRemoveEmployee = async () => {
        try {
            const res = await removeEmployee(id, {});
            if (res.status === 200) {
                setIsOpen(!isOpen)
                fetchCompanyEmployees()
                navigate(`/`)
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <ConditionalRender renderIf={true} isLoading={isLoading}>
            <StyledDiv>
                <ConditionalRender renderIf={false}>
                    <div className='flex justify-end'>
                        <EnabledDisabledToggle
                            disabled={disabled}
                            handleDisabledToggle={handleDisabledToggle}
                            isEditing={isEditing}
                        />
                    </div>
                </ConditionalRender>

                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit}>
                            <div className='mb-lg tx-left'>
                                <h4>
                                    {text?.projects?.details?.details}
                                </h4>
                                <hr />
                            </div>
                            <div>
                                <FieldWithValidation
                                    className='field-style'
                                    setIsEditing={setIsEditing}
                                    label={text?.employees?.details?.firstName}
                                    name="firstName"
                                    validate={required}
                                    component={"input"}
                                    type="text"
                                    initialValue={employee?.firstName}
                                    block
                                />
                                <FieldWithValidation
                                    className='field-style'
                                    setIsEditing={setIsEditing}
                                    label={text?.employees?.details?.lastName}
                                    name="lastName"
                                    validate={required}
                                    component={"input"}
                                    type="text"
                                    initialValue={employee?.lastName}
                                    block
                                />
                                <FieldWithValidation
                                    className='field-style'
                                    setIsEditing={setIsEditing}
                                    label={text?.employees?.details?.email}
                                    name="email"
                                    component={"input"}
                                    validate={emailValidator}
                                    type="text"
                                    initialValue={employee?.email}
                                    block
                                />
                                <Field
                                    name={`isAdmin`}
                                    type="checkbox"
                                    initialValue={employee?.roleName === 'Admin'}
                                    component="input"
                                >
                                    {({ input, meta }) => (
                                        <CheckBox
                                            checked={input.checked}
                                            label={text?.employees?.details?.adminInfo}
                                            // disabled={disabled}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setIsEditing(true)
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Link to={`/logs?id=${memberId}`}>
                                {text?.employees?.details?.logs}
                            </Link>
                            <hr />
                            {
                                (!disabled && isEditing) && (
                                    <FormActions
                                        form={form}
                                        floating
                                        type="submit"
                                        submitText={text?.employees?.details?.save}
                                        btnStyle={{
                                            color: colors.blue
                                        }}
                                    />
                                )
                            }
                        </form>

                    )}
                />
                <ConditionalRender renderIf={!disabled}>
                    {emailSent ?
                        <p>
                            {text?.employees?.details?.emailSent}
                        </p> :
                        <CallToAction style={{ alignSelf: 'flex-start', flex: '1' }} onClick={(e) => submitPasswordReset(e)} text={text?.employees?.details?.resetPassword} />
                    }
                </ConditionalRender>
                <ConditionalRender renderIf={userRole === 'Admin'}>
                    <CallToAction
                        style={{
                            marginTop: '1rem', alignSelf: 'stretch', flex: '1', backgroundColor: colors.red
                        }}
                        onClick={openModal}
                        text={text?.employees?.details?.removeEmployee}
                    />
                    time<ConfirmModal
                        onClick={handleRemoveEmployee}
                        text={text?.employees?.details?.modal?.button}
                        toggle={() => setIsOpen(!isOpen)}
                        isOpen={isOpen}
                        btnStyles={{
                            color: colors.red
                        }}
                    >
                        <h4>
                            {text?.employees?.details?.modal?.title}
                        </h4>
                    </ConfirmModal>
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(error)}>
                    <span className='error'>
                        {error}
                    </span>
                </ConditionalRender>
            </StyledDiv>
        </ConditionalRender>
    );
};

export default DetailsEmployeeContainer;

import React, { useState, useMemo, useEffect } from 'react';
import FieldWithValidation from '../Core/FieldWithValidation';
import ExtraFields from './ExtraFields';
import { composeValidators, isRequired, requiredNumber } from '../utils/validators';
import CheckBox from '../Core/Inputs/CheckBox';
import SelectInput from '../Core/SelectInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FormSpy } from 'react-final-form';
import ConditionalRender from '../Core/ConditionalRender';
import HoverPopup from '../Core/HoverPopup';
import { faClose, faCaretRight, faCaretDown, faStar, faCopy, faRefresh } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss'
import styled from 'styled-components';
import AutocompleteDropdown from '../Core/Inputs/AutocompleteDropdown';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, capitalize } from 'lodash';
import { toCamelCase } from '../utils/helperFunctions';
import TemplateModal from './Modals/TemplateModal';
import CallToAction from '../Core/CallToAction';
import {
    saveProductTemplate,
    updateTemplate
} from '../utils/calls';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames'

const FieldWrapper = styled.div`
        display: flex;
        justify-content: center;
        padding: 10px;
        flex: 1;
        .fields-general {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-products: center;
            align-items: flex-end;
        }
  `

const StyledDiv = styled.div``

const ProductForm = ({
    id,
    removeProduct,
    productCount,
    change,
    onDrop,
    uploadedImages,
    setUploadedImages,
    removeImage,
    form,
    categories,
    resetChild,
    resetChildDone,
    fieldNames,
    setFieldNameAndValues,
    fieldNameAndValues, // this holds the advance fields
    setColumnNameAndValues,
    columnNameAndValues,
    setDuplicatedFields,
    duplicateProduct,
    newFields, // this holds the advance fields
    onFieldRemove,
    productId,
    products,
    index,
    imageError,
    handleImageUpload,
    projects,
    fetchCompanyProjects,
    isCreateProject,
    inventoryColumns,
    inventory,
    templates,
    fetchTemplates,
    templatesIsLoading,
    companyIndustry
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();

    const [templateIsOpen, setTemplateIsOpen] = useState(false)
    const [saveTemplateIsOpen, setSaveTemplateIsOpen] = useState(false)

    const [showExtraParams, setShowExtraParams] = useState(true);
    const [fields, setFields] = useState(newFields);
    const [isPriority, setIsPriority] = useState(false);
    const [initialId, setInitialId] = useState(id)
    const [uniqueProductId] = useState(uuidv4()); // Generate a unique product ID only once
    const productIndex = products.findIndex(p => p.productId === productId);
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    useMemo(() => {
        setFields(newFields)
    }, [newFields])

    useEffect(() => {
        setInitialId(id)
    }, [id])

    useEffect(() => {
        if (!isEmpty(newFields)) {
            setShowExtraParams(true)
        }
    }, [newFields])

    const togglePriority = () => {
        form.change(`products[${productIndex}].isPriority`, !isPriority); // This line updates the field value in the form
        setIsPriority(!isPriority);
    };

    const toggleExtraParams = (e) => {
        e.preventDefault();
        setShowExtraParams(!showExtraParams);
    };

    const requiredValidator = composeValidators(
        isRequired('Required')
    );

    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [previousTemplate, setPreviousTemplate] = useState({});

    const toggleTemplate = (e) => {
        if (!e) return;

        const findTemplate = templates.find(template => template?.id === e?.value);

        if (findTemplate?.id !== selectedTemplate.id) {
            // Save the current selected template as the previous template if different
            setPreviousTemplate(selectedTemplate);
        }

        setTemplateIsOpen(!templateIsOpen);
        setSelectedTemplate(findTemplate);
    };

    const handleTemplateToggle = () => {
        setTemplateIsOpen(!templateIsOpen);

        if (templateIsOpen) { // When closing
            if (previousTemplate.id) { // Check if there is a valid previous template
                form.change(`products[${productIndex}].template`, {
                    value: previousTemplate.id,
                    label: previousTemplate.templateName
                });
                setSelectedTemplate(previousTemplate);
            } else {
                form.change(`products[${productIndex}].template`, null);
                setSelectedTemplate({});
            }
        }
    };

    const handleUpdateTemplate = async () => {
        const product = form?.getState()?.values?.products?.[productIndex];
        const templateId = product?.template?.value;
        console.log(product)

        const { id, productId, template, ...rest } = product;

        if (product) {
            try {
                const res = await updateTemplate(templateId, {
                    ...rest
                })
                if (res.status === 200) {
                    fetchTemplates();

                }
            } catch (error) {
                console.error('Failed to update template', error);
            }
        }
    }

    const resetFields = () => {
        const currentUploadedImages = uploadedImages[productIndex] || [];

        setFields([])
        setFieldNameAndValues([])
        setColumnNameAndValues([])
        setDuplicatedFields({})
        form.change(`products[${productIndex}].inventoryName`, null)
        form.change(`products[${productIndex}].inventoryCategory`, null)
        form.change(`products[${productIndex}].inventoryQuantity.value`, null)
        form.change(`products[${productIndex}].inventoryQuantity.notification.isChecked`, false)
        form.change(`products[${productIndex}].inventoryQuantity.notification.value`, null)
        form.change(`products[${productIndex}].isPriority`, false)
        setIsPriority(false)
        form.change(`products[${productIndex}].advanceFields`, {})
        // form.change(`products[${productIndex}].template`, null)
        // setSelectedTemplate({})
        // setPreviousTemplate({})
        currentUploadedImages.forEach((image, index) => { removeImage(productIndex, index) })
        setShouldRenderCallToAction(false)
    }

    const handleSelectTemplate = () => {
        const findTemplate = templates.find(template => template?.id === selectedTemplate?.id)
        resetFields()
        if (findTemplate) {
            change(`products[${productIndex}].inventoryName`, findTemplate.inventoryName)
            change(`products[${productIndex}].inventoryCategory`, findTemplate.inventoryCategory)
            change(`products[${productIndex}].inventoryQuantity.value`, findTemplate.inventoryQuantity.value)
            change(`products[${productIndex}].inventoryQuantity.notification.isChecked`, findTemplate.inventoryQuantity.notification.isChecked)
            change(`products[${productIndex}].inventoryQuantity.notification.value`, findTemplate.inventoryQuantity.notification.value)
            change(`products[${productIndex}].isPriority`, findTemplate.isPriority)
            setIsPriority(findTemplate.isPriority)
            form.change(`products[${productIndex}].template`, null)

            const advanceFields = findTemplate.advanceFields;
            const newFields = []; // Temporary array to collect all new fields

            if (!isEmpty(advanceFields)) {
                Object.entries(advanceFields)?.forEach(([key, value]) => {
                    const newId = uuidv4();

                    const type = key.split('-')[0]
                    const name = key.split('-')[1]

                    const newField = {
                        id: newId,
                        fullName: key,
                        label: capitalize(name),
                        name: toCamelCase(name),
                        inputType: type
                    };
                    newFields.push(newField);

                    setDuplicatedFields((prevFields) => {
                        // If the product id exists in the state, append the new field to it, otherwise create a new array
                        const updatedFieldsForProduct = prevFields[id] ? [...prevFields[id], newField] : [newField];

                        return { ...prevFields, [id]: updatedFieldsForProduct };
                    });

                    setFieldNameAndValues((prevFieldNameAndValues) => {
                        return [
                            ...prevFieldNameAndValues,
                            {
                                id: newId,
                                fullName: key,
                                label: capitalize(name),
                                name: toCamelCase(name),
                            }
                        ];
                    })
                    setColumnNameAndValues((prevColumnNameAndValues) => {
                        const maxSortOrder = prevColumnNameAndValues.reduce(
                            (max, column) => {
                                const sortOrder = parseInt(column?.sortOrder, 10);
                                return sortOrder > max ? sortOrder : max;
                            },
                            0
                        );
                        const newSortOrder = maxSortOrder + 1;

                        return [
                            ...prevColumnNameAndValues,
                            {
                                id: newId,
                                fieldLabel: capitalize(name),
                                fieldName: toCamelCase(name),
                                fullFieldName: key,
                                isActive: false,
                                sortOrder: newSortOrder.toString(), // Convert back to string
                            },
                        ];
                    });
                    change(`products[${productIndex}].advanceFields.${key}`, value)
                });
            }


            setFields(newFields);

            if (!isEmpty(selectedTemplate) && (findTemplate.value !== selectedTemplate?.id)) {
                form.change(`products[${productIndex}].template`, {
                    value: selectedTemplate?.id,
                    label: selectedTemplate?.templateName
                });
            }

        }
        setTemplateIsOpen(!templateIsOpen)
    }

    const handleSelectedTemplate = () => {
        const product = form?.getState()?.values?.products?.[productIndex];

        if (!product) return;

        // Exclude advanceFields from the product to prepare for appending transformed advanceFields
        const { advanceFields, ...otherFields } = product;

        if (!isEmpty(advanceFields)) {
            const formattedAdvanceFields = Object.entries(advanceFields).reduce((acc, [key, value]) => {
                // Handling 'length, weight, volume' fields with units
                if ((key.startsWith('length') || key.startsWith('weight') || key.startsWith('volume')) && typeof value === 'string') {
                    const unitKey = `${key}-unit`;
                    const unitValue = advanceFields[unitKey]?.label || '';
                    const formattedValue = `${value} ${unitValue}`;
                    acc[key] = formattedValue;
                }
                // Handling 'number' fields without units but with nested value structure
                else if (key.startsWith('number') && typeof value === 'object' && value.value !== null) {
                    acc[key] = value || '';
                }
                // Exclude unit specifications and directly add other values
                else if (!key.endsWith('-unit')) {
                    acc[key] = value || '';
                }
                return acc;
            }, {});

            // Merge other fields with the transformed advanceFields
            const updatedProduct = {
                ...otherFields,
                advanceFields: formattedAdvanceFields
            };

            return updatedProduct;
        }

        return product;
    };

    const handleSaveTemplate = async (values) => {
        const { templateName } = values;
        const product = form?.getState()?.values?.products?.[productIndex];
        const { productId, id, ...rest } = product

        if (product) {
            try {
                const res = await saveProductTemplate({
                    ...rest,
                    templateName
                })
                if (res.status === 200) {
                    setSaveTemplateIsOpen(!saveTemplateIsOpen)
                    setSelectedTemplate({})
                    fetchTemplates()
                }
            } catch (error) {
                console.error('Failed to save template', error);
            }
        }

    }

    const [shouldRenderCallToAction, setShouldRenderCallToAction] = useState(false);

    const handleShouldRenderCallToAction = () => {
        const state = form?.getState()?.values;
        const products = state?.products?.[productIndex];

        if (products) {
            const { id, productId, fieldName, inputType, advanceFields, template, inventoryQuantity, ...rest } = products;

            // Check for non-empty fields excluding specified ones.
            const hasOtherFields = Object.keys(rest).some(key => {
                const value = rest[key];
                if (!value) return false;
                return true;
            });

            // Correctly handle advanceFields with 'number-' prefix
            const hasNonEmptyAdvanceFields = advanceFields && Object.keys(advanceFields).length > 0 && Object.keys(advanceFields).some(key => {
                if (key.startsWith('number-')) {
                    const field = advanceFields[key];
                    // Condition for fields prefixed with 'number-'
                    if (field?.notification) {
                        // Check similar to isValidInventoryQuantity but for each 'number-' prefixed field
                        return field?.notification?.isChecked === false || (field.notification?.isChecked === true && field?.notification?.value);
                    }
                }
                return true; // Consider non-'number-' prefixed fields as valid by default
            });

            // InventoryQuantity check
            const isValidInventoryQuantity = (
                (inventoryQuantity?.value && !inventoryQuantity?.notification) || (inventoryQuantity?.value &&
                    (inventoryQuantity?.notification?.isChecked === false || (inventoryQuantity?.notification?.isChecked === true && inventoryQuantity?.notification?.value)))
            );

            // Combine checks to determine if CallToAction should render.
            return hasOtherFields || isValidInventoryQuantity || hasNonEmptyAdvanceFields
        }

        return false;
    };

    useEffect(() => {
        setShouldRenderCallToAction(handleShouldRenderCallToAction());
    }, [form?.getState()?.values?.products?.[productIndex]]);


    return (
        <StyledDiv
            className={classnames({
                'file-uploader': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
            theme={theme}
        >
            <ConditionalRender renderIf={true}>
                <div
                    className={classnames({
                        'flex': true,
                        'flex-column': isPhone
                    })}
                >
                    <ConditionalRender renderIf={!isEmpty(templates)}>
                        <ConditionalRender
                            renderIf={!templatesIsLoading}
                            isLoading={templatesIsLoading}
                            customStyled={{
                                width: '100%',
                            }}
                        >
                            <SelectInput
                                className='mr-md'
                                name={`products[${productIndex}].template`}
                                placeholder={text?.inventory?.create?.form?.selectTemplate}

                                options={templates?.map((template, index) => {
                                    return {
                                        value: template.id,
                                        label: template.templateName
                                    }
                                })}
                                onChange={(e) => toggleTemplate(e)}
                                handleClear={resetFields}
                                isClearable={false}
                                width={isDesktop ? 30 : isTablet ? 50 : 80}
                                isValidNewOption={() => false}
                            />
                        </ConditionalRender>
                    </ConditionalRender>
                    <ConditionalRender renderIf={shouldRenderCallToAction}>
                        <ConditionalRender renderIf={!isEmpty(selectedTemplate)}>
                            <CallToAction
                                className={classnames({
                                    'mt-md': isPhone,
                                    'mr-md': !isPhone
                                })}
                                text={text?.inventory?.details?.form?.button}
                                onClick={() => handleUpdateTemplate()}
                                btnWidth={isDesktop ? 30 : isTablet ? 50 : 80}
                                style={
                                    isPhone ?
                                        {
                                            justifyContent: 'flex-start',
                                        } : {}}

                            />
                        </ConditionalRender>
                        <CallToAction
                            className={classnames({
                                'mt-md': isPhone
                            })}
                            text={text?.inventory?.create?.form?.saveTemplate}
                            onClick={() => setSaveTemplateIsOpen(!saveTemplateIsOpen)}
                            btnWidth={isDesktop ? 30 : isTablet ? 50 : 80}
                            style={
                                isPhone ?
                                    {
                                        justifyContent: 'flex-start',
                                    } : {}}

                        />
                    </ConditionalRender>
                </div >
                <ConditionalRender renderIf={form?.getState()?.values?.products?.[productIndex]}>
                    <TemplateModal
                        isOpen={saveTemplateIsOpen}
                        toggle={() => {
                            setSaveTemplateIsOpen(!saveTemplateIsOpen)
                        }}
                        selectedTemplate={handleSelectedTemplate()}
                        inventoryColumns={[...inventoryColumns, ...(fields && fields.length > 0 ? fields : [])]}
                        header={text?.inventory?.create?.form?.saveTemplateModal?.title}
                        confirmText={text?.inventory?.create?.form?.saveTemplateModal?.button}
                        isForm
                        onSubmit={(values) => handleSaveTemplate(values)}
                        extraFields={() => (
                            <FieldWithValidation
                                name={`templateName`}
                                component="input"
                                type="text"
                                label={text?.inventory?.create?.form?.saveTemplateModal?.name}
                                validate={requiredValidator}
                                showLightColors
                            />
                        )}
                    />
                </ConditionalRender>
                <TemplateModal
                    onClick={handleSelectTemplate}
                    isOpen={templateIsOpen}
                    selectedTemplate={selectedTemplate}
                    inventoryColumns={[...inventoryColumns, ...(fields && fields.length > 0 ? fields : [])]}
                    toggle={handleTemplateToggle}
                    header={text?.inventory?.create?.form?.loadTemplateModal?.title}
                    description={text?.inventory?.create?.form?.loadTemplateModal?.description}
                    confirmText={text?.inventory?.create?.form?.loadTemplateModal?.button}
                />
            </ConditionalRender >

            <div
                className='wrapper flex mt-md'
            >
                <ConditionalRender renderIf={isDesktop}>
                    <div
                        style={{
                            marginRight: '15px',
                            height: '0%'
                        }}
                        className={`product-number product-number-${initialId + 1}`}
                        onClick={toggleExtraParams}>
                        <div style={{
                            cursor: 'pointer'
                        }}>
                            <HoverPopup
                                placement={'right'}
                                id={`popup-${initialId + 1}`}
                                text={isDesktop && text?.inventory?.create?.form?.toggleAdvanceFields}
                                disabled={showExtraParams}
                            >
                                <FontAwesomeIcon icon={showExtraParams ? faCaretDown : faCaretRight} />
                            </HoverPopup>
                        </div>
                    </div>
                </ConditionalRender>
                <FieldWrapper
                    className='fieldWrapper'
                    id={`fieldWrapper_${initialId}`}
                >
                    <div style={{
                        width: '100%'
                    }}>
                        <div className='fields-general'>
                            {/* keeping a record of the id */}
                            <Field
                                name={`products[${productIndex}].id`}
                                initialValue={`${initialId}`}
                            >
                                {({ input }) => {
                                    return (
                                        <div className="form-group wide" >
                                            <input
                                                {...input}
                                                type={'text'}
                                                disabled
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    )
                                }}
                            </Field>

                            {/* Product Id */}
                            <Field
                                name={`products[${productIndex}].productId`}
                                initialValue={productId || uniqueProductId}
                            >
                                {({ input }) => {
                                    return (
                                        <div
                                            className="form-group wide"
                                        >
                                            <input
                                                {...input}
                                                type={'text'}
                                                disabled
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    )
                                }}
                            </Field>

                            <Field
                                name={`products[${productIndex}].isPriority`}
                                component="input"
                                type="checkbox"
                                onChange={() => togglePriority()}
                            />
                            <div className='flex items-center'>
                                <HoverPopup
                                    onClick={togglePriority}
                                    placement={'top'}
                                    id={`toggle-priority-popup-${initialId}`}
                                    text={isDesktop && text?.inventory?.create?.form?.setPriority}
                                    style={{ marginLeft: '15px' }}
                                >
                                    <FontAwesomeIcon
                                        icon={faStar}
                                        style={{ color: isPriority ? colors.yellow : colors.white }}
                                    />
                                </HoverPopup>

                                {/* Icons */}
                                <div className='ml-md'>
                                    <button style={{
                                        color: colors.blue,
                                        alignSelf: 'flex-start',
                                    }} y
                                        type="button"
                                        onClick={(e) => duplicateProduct(e, initialId, form, productIndex)}
                                    >
                                        <HoverPopup
                                            style={{
                                                background: 'transparent',
                                                hoverBackground: 'transparent',
                                                border: 'none',
                                                hoverColor: colors.black,
                                                color: theme === 'dark' ? colors.white : colors.black,
                                                padding: '0'
                                            }}
                                            placement={'top'}
                                            id={`toggle-cone-chat-popup-${initialId}`}
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </HoverPopup>
                                    </button>
                                    <button style={{
                                        color: colors.blue,
                                        alignSelf: 'flex-start',
                                    }}
                                        type="button"
                                        onClick={() => {
                                            resetFields();
                                            form.change(`products[${productIndex}].template`, null)
                                            setSelectedTemplate({})
                                            setPreviousTemplate({})
                                        }}
                                    >
                                        <HoverPopup
                                            style={{
                                                background: 'transparent',
                                                hoverBackground: 'transparent',
                                                border: 'none',
                                                hoverColor: colors.black,
                                                color: theme === 'dark' ? colors.white : colors.black,
                                                padding: '0'
                                            }}
                                            placement={'top'}
                                            id={`toggle-reset-chat-popup-${initialId}`}
                                        >
                                            <FontAwesomeIcon icon={faRefresh} />
                                        </HoverPopup>
                                    </button>
                                    <ConditionalRender
                                        renderIf={productCount !== 1 || isCreateProject}
                                    >
                                        <button style={{
                                            color: colors.red,
                                            alignSelf: 'flex-end',
                                        }}
                                            type="button"
                                            onClick={(e) => {
                                                removeProduct(form, initialId, productId, index);
                                            }}
                                            disabled={!isCreateProject && productCount === 1}
                                        >
                                            <HoverPopup
                                                style={{
                                                    background: 'transparent',
                                                    hoverBackground: 'transparent',
                                                    border: 'none',
                                                    hoverColor: colors.red,
                                                    color: colors.red,
                                                    padding: '0'
                                                }}
                                                placement={'top'}
                                                id={`toggle-edit-chat-popup-${initialId}`}
                                            >
                                                <FontAwesomeIcon icon={faClose} />
                                            </HoverPopup>
                                        </button>

                                    </ConditionalRender>
                                </div>
                            </div>
                            <FieldWithValidation
                                className='field-style'
                                validate={requiredValidator}
                                name={`products[${productIndex}].inventoryName`}
                                component="input"
                                fieldName={`inventoryName`}
                                suggestionData={inventory}
                                fields={inventoryColumns}
                                type="text"
                                label={text?.inventory?.create?.form?.name}
                                autoComplete
                            />
                            <AutocompleteDropdown
                                name={`products[${productIndex}].inventoryCategory`}
                                label={text?.inventory?.create?.form?.category}
                                options={categories?.filter((x) => x)}
                                placeholder={text?.inventory?.create?.form?.categoryPlaceholder}
                                width={isDesktop ? 30 : isTablet ? 50 : 100}
                                style={{
                                    alignSelf: 'flex-start'
                                }}
                            />
                            <div className='flex flex-column w-100'>
                                <FieldWithValidation
                                    className='field-style'
                                    name={`products[${productIndex}].inventoryQuantity.value`}
                                    component="input"
                                    type="number"
                                    min="0"
                                    label={text?.inventory?.create?.form?.quantity}
                                    width={isDesktop ? 30 : isTablet ? 50 : 100}
                                />

                                <div>
                                    <FormSpy>
                                        {({ values }) => {
                                            // Access the specific checkbox value using the path to the field
                                            const isChecked = values.products?.[productIndex]?.inventoryQuantity?.notification?.isChecked;
                                            const hasValue = values.products?.[productIndex]?.inventoryQuantity?.value;

                                            return (
                                                <>
                                                    {
                                                        (hasValue || hasValue === 0) && (
                                                            <Field name={`products[${productIndex}].inventoryQuantity.notification.isChecked`}>
                                                                {({ input, meta }) => (
                                                                    <CheckBox
                                                                        {...input}
                                                                        name={`products[${productIndex}].inventoryQuantity.notification.isChecked`}
                                                                        type="checkbox"
                                                                        checked={input.value}
                                                                        onChange={input.onChange}
                                                                        label={text?.inventory?.create?.form?.notifyMe}
                                                                        checkStyle={{
                                                                            background: colors.blue
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )
                                                    }

                                                    {
                                                        (isChecked && (hasValue || hasValue === 0)) && (
                                                            <div className='flex items-center'>
                                                                <p className='mr-md'>
                                                                    {text?.inventory?.create?.form?.numberBellow}
                                                                </p>
                                                                <FieldWithValidation
                                                                    validate={composeValidators(
                                                                        requiredNumber(text?.inventory?.create?.form?.required)
                                                                    )}
                                                                    className='field-style'
                                                                    name={`products[${productIndex}].inventoryQuantity.notification.value`}
                                                                    component="input"
                                                                    type="number"
                                                                    min="0"
                                                                    label={text?.inventory?.create?.form?.quantity}
                                                                    width={30}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }}
                                    </FormSpy>
                                </div>
                            </div>
                        </div>
                        {
                            showExtraParams && (
                                <ExtraFields
                                    id={id}
                                    productIndex={productIndex}
                                    uploadedImages={uploadedImages}
                                    handleImageUpload={handleImageUpload}
                                    onDrop={onDrop}
                                    fields={fields}
                                    setFields={setFields}
                                    removeImage={removeImage}
                                    change={change}
                                    form={form}
                                    resetChild={resetChild}
                                    resetChildDone={resetChildDone}
                                    setUploadedImages={setUploadedImages}
                                    fieldNames={fieldNames}
                                    setFieldNameAndValues={setFieldNameAndValues}
                                    setColumnNameAndValues={setColumnNameAndValues}
                                    fieldNameAndValues={fieldNameAndValues}
                                    columnNameAndValues={columnNameAndValues}
                                    onFieldRemove={onFieldRemove}
                                    setDuplicatedFields={setDuplicatedFields}
                                    imageError={imageError}
                                    companyIndustry={companyIndustry}
                                    withTimePicker
                                />
                            )
                        }
                    </div>
                </FieldWrapper>
            </div>
            <hr />
        </StyledDiv>
    )
}

export default ProductForm
import React from 'react';
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import ConditionalRender from '../Core/ConditionalRender';


const InfoWrapper = styled.div`
visibility: ${({ paymentIsLoading }) => paymentIsLoading ? 'hidden' : 'visible'};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Total = styled.div`
  font-size: 1.5rrem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Info = ({ selectedPlan, startTrial, paymentIsLoading }) => {
    const { text } = useLanguage();

    const { type, productName, value } = selectedPlan;

    const renderTrialMessage = () => (
        <div>
            <p style={{
                fontFamily: colors.openSans
            }}>
                {text?.payment?.info?.textOne}
            </p>
            <p style={{
                fontFamily: colors.openSans,
                marginLeft: '5px'
            }}>
                {startTrial ? ` ${value}` : ` ${value}`}.
            </p>
            <p style={{
                fontFamily: colors.openSans
            }}>
                {text?.payment?.info?.textTwo}
            </p>
        </div>
    );

    const renderTotal = () => (
        <div style={{
            marginTop: '30px'
        }}>
            <p>
                {type === 'month' ? text?.payment?.monthly : text?.payment?.yearly}
            </p>
            <div>
                <span>
                    {'Total:'} {startTrial ? <span style={{ textDecoration: 'line-through' }}>{value}</span> : value}{' '}
                </span>
                <p>
                    {startTrial && <div>{text?.payment?.info?.today} {'$0'}</div>}
                </p>
            </div>
        </div>
    );

    return (
        <ConditionalRender renderIf={true} isLoading={paymentIsLoading}>
            <InfoWrapper paymentIsLoading={paymentIsLoading}>
                <h3 className='mb-md'>
                    {text?.payment?.info?.title}
                </h3>
                <div>
                    {startTrial && renderTrialMessage()}
                    {!startTrial && (
                        <div>
                            <span style={{
                                fontFamily: colors.openSans
                            }}>
                                {`${text?.payment?.info?.charge} ${value} ${text?.payment?.info?.today.toLowerCase()}.`}
                            </span>
                        </div>
                    )}
                    <Total>{renderTotal()}</Total>
                </div>
            </InfoWrapper>
        </ConditionalRender>
    );
};

export default Info;

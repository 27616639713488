import request from '../request';

/**
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateComment = async (updatedValues) => {
    try {
        const response = await request('PUT', `/tasks/update-comment`, updatedValues);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to update comment: ${error.message}`);
    }
};

export default updateComment;

const generatePrimaryColors = (count) => {
    const primaryColors = [];
    const hueIncrement = 300 / count;
    let hue = 0;

    for (let i = 0; i < count; i++) {
        const color = `hsl(${hue}, 100%, 40%)`;
        primaryColors.push(color);
        hue += hueIncrement;
    }

    return primaryColors;
};

export default generatePrimaryColors

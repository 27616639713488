import React, { useState, useEffect, useMemo } from 'react'
import ConfirmModal from '../Core/ConfirmModal'
import ConditionalRender from '../Core/ConditionalRender'
import { useLanguage } from '../context/LanguageContext'
import useScreenSize from '../context/useScreenSize'
import isEmpty from 'lodash/isEmpty'
import HoverPopup from '../Core/HoverPopup'
import CustomSelect from '../Core/CustomSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import SearchInput from '../Core/Inputs/SearchInput'
import PaginatedData from '../Core/PaginatedData'
import colors from '../globalStyles.scss'
import SelectableBox from '../Core/SelectableBox'

const RunningTimeSheets = ({
    runningTimesheets,
    setIsOpen,
    isOpen
}) => {
    const { text } = useLanguage()
    const { isDesktop } = useScreenSize()

    const [timeSheets, setTimeSheets] = useState(runningTimesheets)
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [projects, setProjects] = useState(runningTimesheets?.map((timesheet) => {
        return {
            label: timesheet?.projectId?.projectName,
            value: timesheet?.projectId?._id
        }
    }))

    useMemo(() => {
        setProjects(runningTimesheets?.map((timesheet) => {
            return {
                label: timesheet?.projectId?.projectName,
                value: timesheet?.projectId?._id
            }
        }))
    }, [runningTimesheets])

    const handleSearch = (e) => {
        setFilters({
            ...filters,
            searchQuery: e.target?.value
        })
    }

    const handleSelectInputChange = (e, name) => {
        setFilters({ ...filters, [name]: e?.value });
    };

    const filterTableData = async () => {
        const filterOptions = {};

        if (filters?.searchQuery) {
            filterOptions.searchQuery = filters?.searchQuery.toLowerCase()
        }

        if (filters?.projects) {
            filterOptions.projects = filters?.projects
        }

        let filteredTimeSheets = runningTimesheets;

        if (filterOptions?.searchQuery) {
            filteredTimeSheets = runningTimesheets.filter((timesheet) => {
                const fullName = `${timesheet?.userId?.firstName} ${timesheet?.userId?.lastName}`.toLowerCase();
                return fullName.includes(filterOptions?.searchQuery)
            });
        }

        if (filterOptions?.projects) {
            filteredTimeSheets = filteredTimeSheets.filter((timesheet) => {
                return timesheet?.projectId?._id === filterOptions?.projects
            });
        }

        setTimeSheets(filteredTimeSheets);
    }

    useEffect(() => {
        filterTableData();
    }, [filters, runningTimesheets]);

    return (
        <ConfirmModal
            height={isDesktop ? '80%' : '100%'}
            width={isDesktop ? '80%' : '100%'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggle={() => {
                setIsOpen(!isOpen)
                setShowFilters(false)
                setFilters({})
            }}
        >
            <div className='flex flex-column mt-md w-100'>
                <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                    <div className='mb-md'>
                        <h4 className='mb-md'>
                            {`${runningTimesheets?.length} ${text?.dashboard?.timeClockInfo}`}
                        </h4>

                        <div className='flex flex-column'>
                            <HoverPopup
                                style={{
                                    marginRight: '1rem',
                                    alignSelf: 'flex-start'
                                }}
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'}
                                id='toggle-filters-popup-employee'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>

                            <ConditionalRender renderIf={showFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        style={{
                                            marginBottom: '1rem'
                                        }}
                                        value={filters?.searchQuery}
                                        onChange={handleSearch}
                                        placeHolder={text?.employees?.landing?.search}
                                    />

                                </div>
                                <CustomSelect
                                    handleSelectInputChange={(e) => handleSelectInputChange(e, 'projects')}
                                    options={projects}

                                    name={'projects'}
                                    placeHolder={text?.tasks?.home?.filters?.project}
                                />
                            </ConditionalRender>
                        </div>

                        <PaginatedData
                            data={timeSheets}
                            ItemComponent={({ item }) => {
                                return (
                                    <SelectableBox
                                        showLightColors
                                    >
                                        <div className='flex' key={item?._id}>
                                            <p className='mr-sm'>
                                                {`${item?.userId?.firstName} ${item?.userId?.lastName}`}
                                            </p>
                                            <p className='mr-sm'>
                                                {`${text?.dashboard?.in} ${item?.projectId?.projectName}`}
                                            </p>
                                        </div>
                                    </SelectableBox>
                                )
                            }}
                        />
                    </div>
                </ConditionalRender>
            </div>
        </ConfirmModal>
    )
}

export default RunningTimeSheets

import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import CallToAction from '../Core/CallToAction';
import { useTheme } from '../context/ThemeContext'


import colors from '../globalStyles.scss'

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;

    span, div {
        font-family: ${colors.openSans};
        padding: 0 5px;
    }

    span {
        font-size: 6rem;
    }

    div {
        font-size: 2rem;
    }

    .message {
        font-size: 1rem;
        color: ${colors.gray};
        margin: 20px 0;
    }

    a {
        font-size: 1rem;
        color: ${colors.blue};
        text-decoration: none;
        border: 1px solid ${colors.blue};
        padding: 10px 15px;
        border-radius: 5px;
        transition: background 0.3s;

        &:hover {
            background: ${colors.blue};
            color: white;
        }
    }
`

const NotFound = ({ isCompanyPremium }) => {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const { theme } = useTheme();


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500);

        return () => clearTimeout(timer);  // Cleanup to avoid any potential issues if component unmounts before timeout
    }, []);

    if (!isVisible) return null;

    return (
        <StyledDiv>
            <div>
                <span style={{ color: theme === 'dark' ? colors.white : colors.black }}>{'4'}</span>
                <span style={{ color: colors.red }}>{'0'}</span>
                <span style={{ color: theme === 'dark' ? colors.white : colors.black }}>{'4'}</span>
            </div>
            <h1>{'Page Not Found'}</h1>
            <p className="message">
                {`Oops! The page you're looking for might have been moved or deleted, or perhaps it never existed.`}
            </p>
            <CallToAction style={{ alignSelf: 'center' }} onClick={() => navigate(`/`)} text={`Return to ${isCompanyPremium ? 'Dashboard' : 'Home'}`} />
        </StyledDiv>
    );
}


export default NotFound;

import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {Object} registrationData - The user registration data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const registerCompany = async (registrationData) => {
    try {
        const response = await request('POST', '/company/register', registrationData);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(error.message);
    }
};

export default registerCompany;

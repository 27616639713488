import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import ConfirmModal from '../Core/ConfirmModal'
import ConditionalRender from '../Core/ConditionalRender'
import CallToAction from '../Core/CallToAction'
import SearchInput from '../Core/Inputs/SearchInput'
import PaginatedData from '../Core/PaginatedData'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import HoverPopup from '../Core/HoverPopup'
import { isEmpty } from 'lodash'
import EmployeeItem from './EmployeeItem'
import CreateNewEmployeeContainer from '../Employees/CreateNewEmployeeContainer'
import SelectableBox from '../Core/SelectableBox'
import useScreenSize from '../context/useScreenSize'

const StyledEmployeeModal = styled.div`
    width: 100%;
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        width: 30%;
        a, span {
            color: ${colors.white};
        }
    }
`

const StyledClientModal = styled.div`
    width: 100%;
    .client_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        width: 30%;
        cursor: pointer;
        transition: all 0.2s ease 0s;
        &:hover {
            background: ${colors.secondaryLight};
           p {
            color: ${colors.blue};
            }
        }
        p {
            color: ${colors.white};
        }
    }

`

const CreateProjectModals = ({
    assignEmployee,
    handleAssignEmployee,
    assignClient,
    handleAssignClient,
    text,
    employees,
    employeesIsLoading,
    employeesList,
    user,
    addEmployee,
    setAddEmployee,
    addClient,
    setAddClient,
    setRoleType,
    roleType,
    createNewEmployee,
    showEmployeesFilters,
    setShowEmployeesFilters,
    searchEmployeeQuery,
    handleSearchEmployeeChange,
    selectedEmployees,
    adminEmployees,
    handleEmployeeSelection,
    handleAdminSelection,
    fetchAllClients,
    showClientsFilters,
    handleSearchClientChange,
    clientsIsLoading,
    clients,
    setShowClientsFilters,
    searchClientQuery,
    clientsList,
    selectedClients,
    setSelectedClients,
    handleClientSelection,
    setNewClient
}) => {
    const { isDesktop } = useScreenSize();

    return (

        <>

            {/* Employees */}
            <ConditionalRender renderIf={assignEmployee}>
                <ConfirmModal
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                    toggle={handleAssignEmployee}
                    isOpen={assignEmployee}

                    btnStyles={{
                        color: colors.blue
                    }}
                >
                    <StyledEmployeeModal>
                        <h4 className='flex justify-center'>
                            {text?.projects?.details?.assignModal?.title}
                        </h4>
                        <p className='flex justify-center mb-md'>
                            {text?.projects?.details?.assignModal?.text}
                        </p>
                        <CallToAction
                            className='mb-md'
                            text={text?.employees?.landing?.createNew}
                            type='button'
                            onClick={() => setAddEmployee(!addEmployee)}
                        />
                        <ConditionalRender isLoading={employeesIsLoading} loadingCount={employees?.length} renderIf={!isEmpty(employees)}>
                            <HoverPopup style={{
                                marginRight: '1rem',
                            }} onClick={() => setShowEmployeesFilters(!showEmployeesFilters)} placement={'top'} id='toggle-filters-popup-employee_create_project_employee' text={text?.inventory?.landing?.table?.showFilters}>
                                <FontAwesomeIcon icon={faFilter} style={{ color: showEmployeesFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>

                            <ConditionalRender renderIf={showEmployeesFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        value={searchEmployeeQuery}
                                        onChange={handleSearchEmployeeChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />
                                </div>
                            </ConditionalRender>
                            <PaginatedData
                                className='mt-md'
                                data={employeesList?.filter((x) => x?._id !== user?._id)}
                                dataIsLoading={employeesIsLoading}
                                itemsPerPage={5}
                                noDataText={text?.employees?.landing?.noData}
                                searching={searchEmployeeQuery}
                                showLightColors
                                ItemComponent={({ item }) =>
                                    <EmployeeItem
                                        key={item?._id}
                                        employee={item}
                                        selectedEmployees={selectedEmployees}
                                        adminEmployees={adminEmployees}
                                        handleEmployeeSelection={handleEmployeeSelection}
                                        handleAdminSelection={handleAdminSelection}
                                        text={text}
                                    />}
                            />
                        </ConditionalRender>
                    </StyledEmployeeModal>
                </ConfirmModal>

                <ConditionalRender renderIf={addEmployee}>
                    <CreateNewEmployeeContainer
                        isOpen={addEmployee}
                        onClick={createNewEmployee}
                        toggle={() => setAddEmployee(false)}
                        setRoleType={setRoleType}
                        roleType={roleType}
                        employees={employees}
                    />
                </ConditionalRender>
            </ConditionalRender>

            {/* Clients */}
            <ConditionalRender renderIf={assignClient}>
                <ConfirmModal
                    toggle={handleAssignClient}
                    isOpen={assignClient}
                    width='80%'
                    height='80%'
                    btnStyles={{
                        color: colors.blue
                    }}
                >
                    <StyledClientModal>
                        <h4 className='flex justify-center'>
                            {text?.client?.assign}
                        </h4>

                        <ConditionalRender isLoading={clientsIsLoading} loadingCount={clients?.length} renderIf={!isEmpty(clients)}>
                            <HoverPopup style={{
                                marginRight: '1rem',
                            }}
                                onClick={() => setShowClientsFilters(!showClientsFilters)}
                                placement={'top'}
                                id='toggle-filters-popup-employee_create_project_client'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showClientsFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>
                            <ConditionalRender renderIf={showClientsFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        value={searchClientQuery}
                                        onChange={handleSearchClientChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />
                                </div>
                            </ConditionalRender>

                            <PaginatedData
                                className='mt-md'
                                data={clientsList}
                                dataIsLoading={clientsIsLoading}
                                itemsPerPage={5}
                                noDataText={'No Clients Found'}
                                searching={searchClientQuery}
                                showLightColors
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className={'flex justify-space-between'}
                                            showLightColors
                                            onClick={() => {
                                                setNewClient(item)
                                                handleAssignClient()
                                            }}
                                        >
                                            <p>
                                                {`${item.name} (${item.email})`}
                                            </p>
                                        </SelectableBox>
                                    )
                                }}
                            />
                        </ConditionalRender>
                    </StyledClientModal>
                </ConfirmModal>
            </ConditionalRender>
        </>
    )
}

export default CreateProjectModals
import React, { useContext, useEffect, useState } from 'react';
import { TimerContext } from '../context/TimeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from './ConfirmModal';
import CallToAction from './CallToAction';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import classNames from 'classnames';
import MultiFields from './MultiFields';
import { updateDailyLog, getTimesheetById } from '../utils/calls';
import styled from 'styled-components';

const StyledDiv = styled.div`
    display: ${props => props.isFlex ? 'flex' : 'block'};
    justify-content: space-between;
    items-center;

    .TimeTracker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time {
            font-family: ${colors.roboto};
            margin-right: 1rem;
        }
    }
`


const TimeTracker = ({
    projectId,
    isSideMenu,
    isFlex = true,
    disabled,
    projects,
    user,
    showLightColors
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useTheme();
    const { text, formatDate } = useLanguage();

    // Access the timer state and functions from the context
    const {
        isRunning,
        elapsedTime,
        handleStart,
        handlePause,
        handleStop,
        setProjectId,
        timesheet,
        setTimesheet,
        fetchTimeLogs
    } = useContext(TimerContext);
    const [dailyLog, setDailyLog] = useState(timesheet?.dailyLog)

    useEffect(() => {
        setDailyLog(timesheet?.dailyLog)
    }, [timesheet?.dailyLog, projectId])

    const handleSaveDailyLog = async ({ dailyLog }) => {
        try {
            const res = await updateDailyLog(projectId, dailyLog, timesheet?.clockIn);
            if (res.status === 200) {
                const { data, status } = await getTimesheetById(timesheet?._id);
                if (status === 200) {
                    setTimesheet(data)
                    setDailyLog(data.dailyLog)
                }
                handleOpenDailyLog();
                const isAdmin = projects?.some(project => project._id === projectId &&
                    (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin'));
                fetchTimeLogs(null, projectId, isAdmin)
            } else {
                console.error(res.message)
                handleOpenDailyLog()
            }

        } catch (error) {
            console.error(error)
        }
    }

    const handleOpenDailyLog = () => {
        setIsOpen(!isOpen)
    }

    const formatTime = (time) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(seconds).padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    };

    useEffect(() => {
        setProjectId(projectId)
    }, [projectId])

    const iconColor = theme === 'light' ? colors.black : colors.secondary;

    return (
        <StyledDiv isFlex={isFlex}>
            <div className='w-100'>
                <div className='TimeTracker mb-md'>
                    <p className={classNames({
                        time: true,
                        'black': showLightColors
                    })}>
                        {formatTime(elapsedTime)}
                    </p>
                    {!isRunning && (
                        <FontAwesomeIcon
                            onClick={() => !disabled && handleStart(projectId)}
                            icon={faPlay}
                            style={{
                                color: iconColor,
                                fontSize: '2rem',
                                cursor: disabled ? 'default' : 'pointer'
                            }}
                        />
                    )}
                    {isRunning && (
                        <FontAwesomeIcon
                            onClick={!disabled && handlePause}
                            icon={faStop}
                            style={{
                                color: iconColor,
                                fontSize: '2rem',
                                cursor: disabled ? 'default' : 'pointer'
                            }}
                        />
                    )}
                </div>
                <div className='log mb-md'>
                    <CallToAction
                        type="button"
                        onClick={handleOpenDailyLog}
                        text={text?.timeTracker?.dailyLogTitle}
                        style={{
                            alignSelf: 'start'
                        }}
                        disabled={!timesheet || elapsedTime === 0}
                    />
                </div>
            </div>
            <ConfirmModal
                text={text?.timeTracker?.modal?.button}
                toggle={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
                btnStyles={{
                    color: colors.blue
                }}
                projectId={projectId}
                onSubmit={handleSaveDailyLog}
                isForm
            >
                <div className="editor w-100">
                    <MultiFields
                        name="dailyLog"
                        component="textarea"
                        label={`${text?.timeTracker?.modal?.dailyLog} ${formatDate(timesheet?.clockIn)}`}
                        block
                        initialValue={dailyLog}
                    />
                </div>
            </ConfirmModal>
        </StyledDiv>
    );
};


export default TimeTracker;

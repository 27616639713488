import request from '../request';

/**
 * Sends a user registration request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateJustStatus = async (uuid, updatedStatus) => {
    try {
        const { data, status } = await request('PATCH', `/proposal/update-just-status`, { uuid, status: updatedStatus });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to update status : ${error.message}`);
    }
};

export default updateJustStatus;

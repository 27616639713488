import React, { useRef, useEffect } from 'react';

const OutsideClickHandler = ({ children, onOutsideClick, className }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        // Function to check if clicked outside of the component
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, onOutsideClick]); // Ensure effect runs only once or if dependencies change

    return <div className={className} ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler;

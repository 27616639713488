import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from './ConditionalRender';
import SearchInput from '../Core/Inputs/SearchInput';
import HoverPopup from '../Core/HoverPopup';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import ConfirmModal from './ConfirmModal';
import { isEmpty } from 'lodash';
import PdfViewer from './PdfViewer';
import useScreenSize from '../context/useScreenSize';

const StyledDiv = styled.div`
    .uploaded-files {
        .file-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0;
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
            padding: 1rem;
                .file-name {
                    margin-right: 1rem;
                }
                .remove {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: ${colors.red};
                    svg {
                        color: ${colors.white};
                        font-size: 1rem;
                    }
                }

        }
    }
`

const RenderFiles = ({
    files,
    removeFile,
    projectIsComplete,
    handleDownloadFile,
    readOnly
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();

    const [tableData, setTableData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const { isDesktop } = useScreenSize();

    useEffect(() => {
        if (files === 0) {
            setTableData([]);
        } else {
            setTableData(files);
        }
    }, [files]);

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const filterTableData = () => {
        let filteredData = files;
        if (filters.query) {
            filteredData = filteredData.filter((file) => {
                return file.originalName.toLowerCase().includes(filters.query.toLowerCase());
            });
        }
        setTableData(filteredData);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, files]);

    const openPdfViewer = (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedFile(file);
    };

    const closePdfViewer = () => {
        setSelectedFile(null);
    };

    const FileActionButton = ({ file }) => {
        const extension = file.originalName.split('.').pop();

        if (extension === 'txt') {
            return (
                <button onClick={(e) => handleDownloadFile(e, file)} className="file-name-button">
                    <p>{file.originalName}</p>
                </button>
            );
        } else if (extension === 'pdf') {
            return (
                <button onClick={(e) => openPdfViewer(e, file)} className="file-name-button">
                    <p>{file.originalName}</p>
                </button>
            );
        }
        return null;
    };

    return (
        <StyledDiv theme={theme}>
            <div className='flex flex-column'>
                <ConditionalRender renderIf={!projectIsComplete && !isEmpty(files)}>
                    <HoverPopup
                        className='align-self-start'
                        style={{ marginRight: '1rem' }}
                        onClick={() => setShowFilters(!showFilters)}
                        placement={'top'}
                        id='toggle-filters-popup-tasks'
                        text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                    >
                        <FontAwesomeIcon
                            icon={faFilter}
                            style={{ color: showFilters ? colors.yellow : colors.white }}
                        />
                    </HoverPopup>
                </ConditionalRender>
                <ConditionalRender renderIf={showFilters}>
                    <div className='flex items-center justify-around filters mt-md'
                        style={{
                            flexWrap: 'wrap'
                        }}>
                        <SearchInput
                            style={{ marginRight: '1em', marginBottom: '1em' }}
                            value={filters.query}
                            onChange={handleSearchChange}
                            placeHolder={text?.tasks?.home?.filters?.search}
                        />
                    </div>
                </ConditionalRender>
            </div>
            <div className='uploaded-files'>
                {tableData?.map((file, index) => (
                    <div key={index} className='file-wrapper'>
                        <FileActionButton file={file} />
                        <ConditionalRender renderIf={!readOnly}>
                            <button
                                className='remove'
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeFile && removeFile(index);
                                }}
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </ConditionalRender>
                    </div>
                ))}
            </div>
            {selectedFile && (
                <ConfirmModal
                    isOpen={!!selectedFile}
                    toggle={closePdfViewer}
                    width={'80%'}
                    height={'80%'}
                >
                    <PdfViewer pdfFile={selectedFile.url} />
                </ConfirmModal>
            )}
        </StyledDiv>
    );
}

export default RenderFiles
import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {String} id - the id of the field
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateField = async (id, values) => {
    try {
        const response = await request('PATCH', `/attributes/update-field/${id}`, values);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to update Field: ${error.message}`);
    }
};

export default updateField;

import React, { useState, useEffect, useRef } from 'react'
import CallToAction from '../Core/CallToAction'
import { Form, FormSpy } from 'react-final-form';
import { useNavigate } from "react-router-dom";
import ImageUploader from '../Core/ImageUploader';
import FileUploader from '../Core/FileUploader';
import InternalLocationSearchInput from '../Core/Inputs/LocationSearchInput';
import SelectableBox from '../Core/SelectableBox';
import MultiFields from '../Core/MultiFields';
import Info from '../Core/Info';
import FormActions from '../Core/FormActions';
import { isRequired, composeValidators } from '../utils/validators';
import CreateNewClientContainer from '../Clients/CreateNewClientContainer';
import ConditionalRender from '../Core/ConditionalRender';
import ConfirmModal from '../Core/ConfirmModal';

import AutocompleteDropdown from '../Core/Inputs/AutocompleteDropdown';
import CreateProjectModals from './CreateProjectModals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '../Core/Wrapper';
import {
    createUser,
    setRole,
    createProjectImages,
    createProjectFiles
} from '../utils/calls'
import InventoryCreateContainer from '../Invetory/InventoryCreateContainer';
import base64ToBlob from '../utils/base64ToBlob';
import { isEmpty } from 'lodash';
import { createProject } from '../utils/calls'
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import { useToast } from '../context/ToastContext'
import colors from '../globalStyles.scss'

const CreateProjectContainer = ({
    user,
    company,
    fetchCompanyEmployees,
    employees,
    employeesIsLoading,
    fetchCompanyProjects,
    fieldNames,
    categories,
    inventory,
    inventoryColumns,
    fetchInventoryProducts,
    fetchInventoryCategories,
    fetchAttributes,
    projects,
    categoryRecommendationData,
    fetchAllClients,
    clients,
    clientsIsLoading
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop } = useScreenSize();
    const formRef = useRef();


    const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
    const [assignEmployee, setAssignEmployee] = useState(false);
    const [assignClient, setAssignClient] = useState(false);
    const [addEmployee, setAddEmployee] = useState(false);
    const [addClient, setAddClient] = useState(false);

    const [roleType, setRoleType] = useState('Employee');
    const [error, setError] = useState('');
    const [errorFields, setErrorFields] = useState([]); // State to store error fields

    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedClients, setSelectedClients] = useState({});
    const [adminEmployees, setAdminEmployees] = useState([]);
    const [selectedDesigns, setSelectedDesigns] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [newProject, setNewProject] = useState({})
    const [features, setFeatures] = useState({
        inventory: true,
        client: true,
        proposals: true
    })

    const required = composeValidators(
        isRequired(text?.projects?.create?.validations?.required),
    );

    // Validator to check if the project name already exists
    const projectNameExists = value => {
        const nameExists = projects?.some(
            project => project?.projectName.toLowerCase() === value?.toLowerCase()
        );
        return nameExists ? text?.projects?.create?.validations?.exists : undefined;
    };

    const nameValidation = composeValidators(
        isRequired(text?.projects?.create?.validations?.required),
        projectNameExists

    )

    const handleDesignsSelect = (imageData) => {
        setSelectedDesigns(imageData)
    };

    const handleFilesSelect = (files) => {
        setSelectedFiles(files)
    };

    const handleAssignEmployee = () => {
        setAssignEmployee(!assignEmployee)
    };

    const handleAssignClient = () => {
        setAssignClient(!assignClient)
    };

    const setNewClient = (client) => {
        setSelectedClients(client)
    }

    const createNewEmployee = async (values) => {
        values.companyId = company?.id
        values.companyName = company?.companyName
        values.companyIndustry = {
            value: company?.companyIndustry
        }
        const permissions = ['read', 'write']
        setAddEmployee(false)

        setRole({
            companyId: company?.id,
            roleName: roleType,
            permissions
        })
            .then((userRes) => {
                const roleId = userRes?.data?.id
                const roleName = userRes?.data?.roleName
                const companyId = userRes?.data?.companyId
                return createUser({
                    ...values,
                    roleId,
                    roleName,
                    companyId
                })
            }).then((res) => {
                if (res.status === 200) {
                    const newEmployee = res.data?.user
                    fetchCompanyEmployees(company?.id)
                    handleEmployeeSelection(newEmployee)
                }
            })
            .catch((error) => {
                console.error(error);
                setError(error);
            });
    };


    const [showEmployeesFilters, setShowEmployeesFilters] = useState(false);
    const [showClientsFilters, setShowClientsFilters] = useState(false);
    const [searchEmployeeQuery, setSearchEmployeeQuery] = useState('');
    const [searchClientQuery, setSearchClientQuery] = useState('');
    const [employeesList, setEmployeesList] = useState(employees);
    const [clientsList, setClientsList] = useState(clients);

    const handleSearchEmployeeChange = (e) => {
        setSearchEmployeeQuery(e.target.value);
    };

    const handleSearchClientChange = (e) => {
        setSearchClientQuery(e.target.value);
    };

    const handleFeaturesChange = (feature) => {
        setFeatures((prev) => ({
            ...prev,
            [feature]: !prev[feature]
        }));
    };

    const handleEmployeeSelection = (employee) => {
        if (selectedEmployees.some((e) => e._id === employee._id)) {
            setSelectedEmployees((prev) => prev.filter((e) => e._id !== employee._id));
            setAdminEmployees((prev) => prev.filter((e) => e._id !== employee._id)); // Remove from adminEmployees if unchecked
        } else {
            setSelectedEmployees((prev) => [...prev, employee]);
        }
    };

    const handleClientSelection = (client) => {
        setSelectedClients(client);
    }

    const handleAdminSelection = (employee) => {
        const isAlreadyAdmin = adminEmployees.some((e) => e._id === employee._id);

        if (isAlreadyAdmin) {
            setAdminEmployees((prev) => prev.filter((e) => e._id !== employee._id));
        } else {
            if (selectedEmployees.some((e) => e._id === employee._id)) {
                setAdminEmployees((prev) => [...prev, employee]);
            } else {
                console.error("Employee must be selected before being marked as an admin.");
            }
        }
    };

    useEffect(() => {
        if (searchEmployeeQuery) {
            const query = searchEmployeeQuery.toLowerCase();
            const filteredEmployees = employees.filter((employee) => {
                // Combine first name and last name for comprehensive search
                const fullName = `${employee?.firstName} ${employee?.lastName}`.toLowerCase();
                return fullName.includes(query);
            });
            setEmployeesList(filteredEmployees);
        } else {
            setEmployeesList(employees);
        }

        if (searchClientQuery) {
            const query = searchClientQuery.toLowerCase();
            const filteredClients = clients.filter((client) => {
                return client?.name?.toLowerCase().includes(query);
            });
            setClientsList(filteredClients);
        } else {
            setClientsList(clients);
        }
    }, [searchEmployeeQuery, employees, clients, searchClientQuery]);

    const [inventoryIsCreating, setInventoryIsCreating] = useState(false);

    /*
        // Project Form Submission
    */
    const onSubmit = async (values) => {

        values.assignedEmployees = selectedEmployees?.map((employee) => {
            return {
                id: employee._id
            };
        });

        values.adminEmployees = adminEmployees?.map((employee) => {
            return {
                id: employee._id
            };
        });

        if (!isEmpty(selectedClients)) {
            values.client = selectedClients?._id
        }

        values.features = features;

        try {
            setIsLoading(true); // Set loading state to true

            const res = await createProject(values);
            if (res.status === 200) {
                const projectId = res.data?.project?.id;
                setNewProject(res.data?.project);

                // Process and upload product images
                const imageUploadPromises = selectedDesigns?.map(async (image) => {
                    const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
                    const formData = new FormData();
                    formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
                    formData.append("projectId", projectId);

                    console.log("Uploading image...");

                    // Send the image to a separate endpoint
                    return createProjectImages(formData)
                        .then((imageRes) => {
                            if (imageRes?.status === 200) {
                                console.log("Image uploaded");
                            } else {
                                throw new Error("Image upload failed");
                            }
                        });
                }) || [];

                // Create file upload promises
                const fileUploadPromises = selectedFiles?.map(async (file) => {
                    const formData = new FormData();
                    formData.append('file', file); // Add the file to FormData
                    formData.append('projectId', projectId);

                    console.log("Uploading file...");

                    // Send the file to the createProjectFiles endpoint
                    return createProjectFiles(formData)
                        .then((fileRes) => {
                            if (fileRes?.status === 200) {
                                console.log("File uploaded");
                            } else {
                                throw new Error("File upload failed");
                            }
                        });
                }) || [];

                // Combine both image and file upload promises into a single array
                const allUploadPromises = [
                    ...imageUploadPromises,
                    ...fileUploadPromises
                ];

                // Wait for all uploads to complete
                await Promise.all(allUploadPromises);
                notify(text?.notificationsUI?.project?.created, 'success');

                const fetchPromises = [];
                if (fetchCompanyProjects) {
                    fetchPromises.push(fetchCompanyProjects(company?.id));
                }
                await Promise.all(fetchPromises);

                if (!inventoryIsCreating) {
                    if (projectId === '000000000000000000000000') {
                        setIsLoading(false);
                        navigate('/')
                    } else {
                        setIsLoading(false);
                        navigate(`/project/details?id=${projectId}`); // Navigate to /project
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
            notify(`Error: ${error.message}`, 'error');
            console.error("Error submitting form:", error);
        }
    };

    const [toggleModal, setToggleModal] = useState(false);


    const handleToggle = (e) => {
        e?.preventDefault()
        setToggleModal(prevToggleModal => !prevToggleModal);
    }
    const resetForm = () => {
        formRef?.current.reset();
        handleToggle();
        navigate('/')

    }

    const [spyValues, setSpyValues] = useState();

    return (
        <ConditionalRender renderIf={true}>
            <div style={{ width: '100%' }}>
                {
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, form, submitting, pristine }) => {
                            formRef.current = form;

                            return (
                                <form onSubmit={handleSubmit}>
                                    <FormSpy subscription={{ values: true }}>
                                        {({ values, form }) => {
                                            setSpyValues(values);
                                        }}
                                    </FormSpy>
                                    <div>
                                        <h4 className='tx-left'>
                                            {text?.projects?.landing?.createNew}
                                        </h4>
                                        <p className='small mt-md tx-left'>
                                            {text?.projects?.create?.employeeInfo}
                                        </p>
                                        <hr />
                                    </div>

                                    {/* Info */}
                                    <Wrapper
                                        isLoading={isLoading}
                                    >
                                        <MultiFields
                                            className='field-style'
                                            name="projectName"
                                            component="input"
                                            type="text"
                                            label={text?.projects?.create?.name}
                                            block
                                            validate={nameValidation}
                                        />
                                        <AutocompleteDropdown
                                            className='field-style'
                                            validate={required}
                                            name={`projectCategory`}
                                            label={text?.projects?.create?.category}
                                            options={categoryRecommendationData}
                                            placeholder={text?.projects?.create?.selectCategory}
                                            noFormat
                                        />
                                        <InternalLocationSearchInput
                                            name="projectLocation"
                                            type={'input'}
                                            label={text?.projects?.create?.location}
                                            showMapLabel={text?.projects?.create?.showMap}
                                        />
                                        <MultiFields
                                            className='field-style'
                                            name="projectDescription"
                                            component="textarea"
                                            type="text"
                                            label={text?.projects?.create?.description}
                                            block
                                            info={<Info
                                                text={text?.projects?.create?.descriptionInfo}
                                                id='projectDescriptionInfo'
                                                placement={'top'}
                                                className='flex justify-center items-center'
                                            />}
                                        />
                                    </Wrapper>

                                    <hr />

                                    {/* Employees */}
                                    <Wrapper
                                        isLoading={isLoading}
                                        title={text?.projects?.create?.employees}
                                        button={<CallToAction
                                            text={text?.projects?.create?.employeeButton}
                                            type='button'
                                            onClick={() => handleAssignEmployee()}
                                        />
                                        }
                                    >
                                        <ConditionalRender renderIf={!isEmpty(selectedEmployees)}>

                                            <div className='flex flex-column align-items-start mb-md '>
                                                {
                                                    selectedEmployees?.map((employee) => (
                                                        <SelectableBox
                                                            block
                                                            key={employee._id}
                                                            className='flex items-center'
                                                        >
                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    setSelectedEmployees(selectedEmployees.filter((e) => e._id !== employee._id))
                                                                    setAdminEmployees(adminEmployees.filter((e) => e._id !== employee._id))
                                                                }}
                                                                icon={faClose} style={{
                                                                    color: colors.red,
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                            <p className='ml-md'>
                                                                {`${employee.firstName} ${employee.lastName}`}
                                                                {adminEmployees.some((e) => e._id === employee._id) && ` (${text?.projects?.create?.admin})`}
                                                            </p>
                                                        </SelectableBox>
                                                    ))
                                                }
                                            </div>
                                        </ConditionalRender>

                                        <ConditionalRender renderIf={isEmpty(selectedEmployees)}>
                                            <p className='mb-md'>
                                                {text?.projects?.create?.notFoundMsgs?.employees}
                                            </p>
                                        </ConditionalRender>
                                    </Wrapper>

                                    <hr />

                                    {/* Clients */}
                                    <Wrapper
                                        isLoading={isLoading}
                                        title={text?.projects?.create?.client}
                                        button={
                                            <CallToAction
                                                text={
                                                    isEmpty(selectedClients) ?
                                                        text?.projects?.details?.clientModal?.assignClient : text?.projects?.details?.clientModal?.changeClient
                                                }
                                                type='button'
                                                onClick={() => handleAssignClient()}
                                            />
                                        }
                                        secondaryButton={
                                            <ConditionalRender renderIf={isEmpty(selectedClients)}>
                                                <CallToAction
                                                    className='ml-md'
                                                    text={text?.client?.create?.title}
                                                    type='button'
                                                    onClick={() => setAddClient(!addClient)}
                                                />
                                            </ConditionalRender>
                                        }
                                    >
                                        <ConditionalRender renderIf={!isEmpty(selectedClients)}>
                                            <SelectableBox className='flex items-center mb-md'>
                                                <FontAwesomeIcon
                                                    onClick={() => setSelectedClients({})}
                                                    icon={faClose} style={{
                                                        color: colors.red,
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                                <p className='ml-md'>
                                                    {`${selectedClients?.name}(${selectedClients?.email})`}
                                                </p>
                                            </SelectableBox>
                                        </ConditionalRender>

                                        <ConditionalRender renderIf={isEmpty(selectedClients)}>
                                            <p className='mb-md'>
                                                {text?.projects?.create?.notFoundMsgs?.client}
                                            </p>
                                        </ConditionalRender>

                                        <ConditionalRender renderIf={addClient}>
                                            <CreateNewClientContainer
                                                isOpen={addClient}
                                                toggle={() => setAddClient(false)}
                                                user={user}
                                                fetchAllClients={fetchAllClients}
                                                setNewClient={setNewClient}
                                                clients={clients}
                                            />
                                        </ConditionalRender>
                                    </Wrapper>

                                    <hr />

                                    {/* designs */}
                                    <ImageUploader
                                        onImageUpload={handleDesignsSelect}
                                        maxFiles={10}
                                        title={text?.projects?.create?.design}
                                        customMessage={text?.inventory?.details?.uploadImages}
                                        isLoading={isLoading}
                                    />
                                    <ConditionalRender isLoading={isLoading} renderIf={isEmpty(selectedDesigns)}>
                                        <p className='mb-md'>
                                            {text?.projects?.create?.notFoundMsgs?.images}
                                        </p>
                                    </ConditionalRender>

                                    <hr />

                                    {/* files */}
                                    <FileUploader
                                        onFileUpload={handleFilesSelect}
                                        maxFiles={10}
                                        files={selectedFiles}
                                        customMessage={text?.projects?.create?.orDrag}
                                        title={text?.projects?.details?.filesTitle}
                                        isLoading={isLoading}
                                    />
                                    <hr />

                                    <FormActions
                                        floating
                                        type="submit"
                                        submitText={text?.projects?.create?.button}
                                        secondaryText={text?.projects?.create?.cancel}
                                        form={form}
                                        btnStyle={{
                                            color: colors.blue,
                                        }}
                                        onClick={handleToggle}
                                    />
                                </form>
                            )
                        }
                        }
                    />
                }

                {/* Inventory */}
                <InventoryCreateContainer
                    company={company}
                    isCreateProject
                    fieldNames={fieldNames}
                    categories={categories}
                    inventory={inventory}
                    inventoryColumns={inventoryColumns}
                    user={user}
                    fetchInventoryProducts={fetchInventoryProducts}
                    fetchInventoryCategories={fetchInventoryCategories}
                    fetchCompanyProjects={fetchCompanyProjects}
                    fetchAttributes={fetchAttributes}
                    projects={projects}
                    newProject={newProject}
                    projectValues={spyValues}
                    setInventoryIsCreating={setInventoryIsCreating}
                    title={text?.projects?.create?.inventory}
                    info={text?.projects?.create?.inventoryInfo}
                    toggle={() => handleFeaturesChange('inventory')}
                    toggleState={features?.inventory}
                    projectIsLoading={isLoading}
                />

                <hr />

                <CreateProjectModals
                    assignEmployee={assignEmployee}
                    handleAssignEmployee={handleAssignEmployee}
                    assignClient={assignClient}
                    handleAssignClient={handleAssignClient}
                    text={text}
                    employees={employees}
                    employeesIsLoading={employeesIsLoading}
                    employeesList={employeesList}
                    user={user}
                    addEmployee={addEmployee}
                    setAddEmployee={setAddEmployee}
                    addClient={addClient}
                    setAddClient={setAddClient}
                    setRoleType={setRoleType}
                    roleType={roleType}
                    createNewEmployee={createNewEmployee}
                    showEmployeesFilters={showEmployeesFilters}
                    setShowEmployeesFilters={setShowEmployeesFilters}
                    searchEmployeeQuery={searchEmployeeQuery}
                    handleSearchEmployeeChange={handleSearchEmployeeChange}
                    selectedEmployees={selectedEmployees}
                    adminEmployees={adminEmployees}
                    handleEmployeeSelection={handleEmployeeSelection}
                    handleAdminSelection={handleAdminSelection}
                    fetchAllClients={fetchAllClients}
                    showClientsFilters={showClientsFilters}
                    clientsIsLoading={clientsIsLoading}
                    clients={clients}
                    setShowClientsFilters={setShowClientsFilters}
                    searchClientQuery={searchClientQuery}
                    handleSearchClientChange={handleSearchClientChange}
                    clientsList={clientsList}
                    handleClientSelection={handleClientSelection}
                    selectedClients={selectedClients}
                    setNewClient={setNewClient}
                />
            </div>
            <ConfirmModal
                toggle={handleToggle}
                isOpen={toggleModal}
                text={text?.inventory?.create?.modal?.button}
                onClick={() => resetForm()}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '50%' : '100%'}
            >
                <div>
                    <h4 className='mb-md'>
                        {text?.inventory?.create?.modal?.title}
                    </h4>
                    <p>
                        {text?.inventory?.create?.modal?.subtitle}
                    </p>
                </div>
            </ConfirmModal>
        </ConditionalRender >
    )
}

export default CreateProjectContainer
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ConditionalRender from './ConditionalRender'

const StyledDiv = styled.div`
    display: flex;
    .wrapper {
        .name {
            font-weight: bold;
            margin-right: 1rem;
        }
    }
`

const FieldWrapper = ({
    label,
    name,
    goto,
    noHr,
    className = '',
    labelClassName = '',
    nameClassName = '',
    style,
    callToAction
}) => {
    return (
        <StyledDiv>
            <div className={`${className} wrapper`} style={style}>
                <span className={`name ${nameClassName}`}>
                    {name}
                </span>
                <ConditionalRender renderIf={goto}>
                    <Link to={goto}>
                        {label}
                    </Link>
                </ConditionalRender>
                <ConditionalRender renderIf={!goto}>
                    <span className={`label mr-md ${labelClassName}`}>
                        {label}
                    </span>
                </ConditionalRender>
                <ConditionalRender renderIf={callToAction}>
                    {callToAction}
                </ConditionalRender>
                <ConditionalRender renderIf={!noHr}>
                    <hr />
                </ConditionalRender>
            </div>
        </StyledDiv >
    )
}

export default FieldWrapper
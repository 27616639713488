import { compareStrings } from "../../utils/helperFunctions"
import _ from 'lodash';
import { cloneDeep } from 'lodash';
import moment from "moment";


// Your renderLabel function
export const renderLabel = (arr, entry) => {
    const label = entry && arr?.find((x) =>
        (compareStrings(x?.fieldName, entry?.dataKey)) ||
        (compareStrings(x?.fullName, entry)) ||
        (compareStrings(x?.fieldName, entry)) ||
        (compareStrings(x?.fullFieldName, entry)) ||
        (compareStrings(x?.fullFieldName && x?.fullFieldName, entry?.dataKey?.split('.')[1])) ||
        (compareStrings(x?.fullFieldName && x?.fullFieldName, typeof entry === 'string' && entry.split('.')[1]))
    );
    return label?.fieldLabel || label?.label;
};

export const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => value && value[key], obj);
};

export const separateData = (arr) => {
    let stringsObjectsArray = [];
    let numbersObjectsArray = [];

    for (let i = 0; i < arr.length; i++) {
        let stringsObj = cloneDeep(arr[i]);
        let numbersObj = cloneDeep(arr[i]);

        for (let key in arr[i]) {
            if (typeof arr[i][key] === 'string' && isNaN(Number(arr[i][key]))) {
                delete numbersObj[key];
            } else if (typeof arr[i][key] === 'number' || !isNaN(Number(arr[i][key]))) {
                delete stringsObj[key];
            }
            if (key === 'inventoryName') {
                numbersObj['inventoryName'] = arr[i][key];
                stringsObj['inventoryName'] = arr[i][key];
            }
            if (key === 'productId') {
                numbersObj['productId'] = arr[i][key];
                stringsObj['productId'] = arr[i][key];
            }
            if (key === 'inventoryCategory') {
                numbersObj['inventoryCategory'] = arr[i][key];
                stringsObj['inventoryCategory'] = arr[i][key];
            }

            if (typeof arr[i][key] === 'object') {
                let stringsAdvancedObj = stringsObj[key];
                let numbersAdvancedObj = numbersObj[key];
                for (let advancedKey in arr[i][key]) {
                    if (
                        typeof arr[i][key][advancedKey] === 'string' &&
                        isNaN(Number(arr[i][key][advancedKey]))
                    ) {
                        delete numbersAdvancedObj[advancedKey];
                    } else if (
                        typeof arr[i][key][advancedKey] === 'number' ||
                        !isNaN(Number(arr[i][key][advancedKey]))
                    ) {
                        delete stringsAdvancedObj[advancedKey];
                    }
                }
                numbersObj[key] = { ...numbersAdvancedObj, ...stringsAdvancedObj };
            }
        }

        stringsObjectsArray.push(stringsObj);
        numbersObjectsArray.push(numbersObj);
    }

    return [stringsObjectsArray, numbersObjectsArray];
}

export const numbersData = (arr) => {
    let numbersObjectsArray = [];

    if (arr) {
        for (let i = 0; i < arr?.length; i++) {
            let numbersObj = {};

            for (let key in arr[i]) {
                if (key === 'isPriority' || key === 'productId') {
                    continue; // Skip isPriority field
                }

                if (typeof arr[i][key] === 'object') {
                    let numbersAdvancedObj = {};
                    for (let advancedKey in arr[i][key]) {
                        if (advancedKey.split('-')[0] === 'phone') {
                            continue; // Skip this field
                        }

                        let value = arr[i][key][advancedKey];
                        if (typeof value !== 'number' && !isNaN(Number(value))) {
                            value = Number(value);
                        }

                        if (
                            typeof value === 'number' ||
                            !isNaN(Number(value))
                        ) {
                            numbersAdvancedObj[advancedKey] = value;
                        }
                    }
                    numbersObj[key] = numbersAdvancedObj;
                } else {
                    let value = arr[i][key];
                    if (typeof value !== 'number' && !isNaN(Number(value))) {
                        value = Number(value);
                    }

                    if (
                        typeof value === 'number' ||
                        !isNaN(Number(value))
                    ) {
                        numbersObj[key] = value;
                    }
                }
            }

            numbersObjectsArray.push(numbersObj);
        }
    }

    return [numbersObjectsArray];
};

export const stringData = (arr) => {
    let stringsObjectsArray = [];
    if (arr) {

        const isFloat = (value) => {
            return !isNaN(parseFloat(value));
        };

        for (let i = 0; i < arr?.length; i++) {
            let stringsObj = {};

            for (let key in arr[i]) {
                if (key === 'isPriority' || key === 'productId' || key === 'user' || key === 'updatedAt') {
                    continue; // Skip isPriority and productId fields
                }

                if (typeof arr[i][key] === 'object') {
                    let stringsAdvancedObj = {};
                    for (let advancedKey in arr[i][key]) {
                        if (advancedKey.split('-')[0] === 'phone') {
                            continue; // Skip this field
                        }

                        let value = arr[i][key][advancedKey];

                        if (typeof value === 'string' && (!isFloat(value) || advancedKey.includes('date') || advancedKey.includes('time'))) {
                            stringsAdvancedObj[advancedKey] = value;
                        }
                    }
                    stringsObj[key] = stringsAdvancedObj;
                } else {
                    let value = arr[i][key];
                    if (typeof value === 'string' && (!isFloat(value) || key.includes('date') || key.includes('time'))) {
                        stringsObj[key] = value;
                    }
                }
            }

            stringsObjectsArray.push(stringsObj);
        }
    }
    return [stringsObjectsArray];
};


export const deepParseNumbers = (item) => {
    if (Array.isArray(item)) {
        return item.map(deepParseNumbers);
    } else if (item !== null && typeof item === 'object') {
        return Object.fromEntries(
            Object.entries(item).map(([key, value]) => [key, deepParseNumbers(value)])
        );
    } else {
        let number = Number(item);
        return isNaN(number) ? item : number;
    }
}

export const combineData = (value, formattedData) => {
    const combinedDataMap = new Map();

    formattedData.forEach((item) => {
        const key = _.get(item, value)?.toLowerCase();

        if (key) {
            if (combinedDataMap.has(key)) {
                const combinedItem = combinedDataMap.get(key);
                Object.keys(item).forEach((key) => {
                    if (key !== value) {
                        if (typeof _.get(item, key) === 'object' && _.get(item, key) !== null) {
                            if (!_.get(combinedItem, key)) {
                                _.set(combinedItem, key, {});
                            }
                            Object.keys(_.get(item, key)).forEach((subKey) => {
                                if (!_.get(combinedItem, `${key}.${subKey}`)) {
                                    _.set(combinedItem, `${key}.${subKey}`, '0');
                                }
                                let oldValue = Number(_.get(combinedItem, `${key}.${subKey}`));
                                let newValue = Number(_.get(item, `${key}.${subKey}`));
                                if (!isNaN(oldValue) && !isNaN(newValue)) {
                                    _.set(combinedItem, `${key}.${subKey}`, (oldValue + newValue).toString());
                                }
                            });
                        } else {
                            let oldValue = Number(_.get(combinedItem, key));
                            let newValue = Number(_.get(item, key));
                            if (!isNaN(oldValue) && !isNaN(newValue)) {
                                _.set(combinedItem, key, oldValue + newValue);
                            }
                        }
                    }
                });
            } else {
                combinedDataMap.set(key, _.cloneDeep(item));
            }
        }
    });
    const newArray = Array.from(combinedDataMap.values()).map((x) => {
        if (x.advanceFields) {
            Object.entries(x.advanceFields).forEach(([key, value]) => {
                // Define a regular expression for ISO 8601 dates
                const iso8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;

                // Define a regular expression for military time (HH:mm)
                const militaryTime = /^([01]\d|2[0-3]):([0-5]\d)$/;

                // Check if the value matches the ISO 8601 format
                if (iso8601.test(value)) {
                    x.advanceFields[key] = moment.utc(value).format('MM-DD-YYYY');
                }

                // Check if the value matches the military time format
                if (militaryTime.test(value)) {
                    x.advanceFields[key] = moment(value, 'HH:mm').format('hh:mm A');
                }
            });
        }
        return x;
    });
    return deepParseNumbers(newArray);

};

const deepFilterFields = (obj) => {
    return Object.entries(obj).reduce((newObj, [k, v]) => {
        // Recursively deep filter if the value is an object
        if (v && typeof v === 'object') {
            newObj[k] = deepFilterFields(v);
            // filter out unwanted field types
        } else if (
            !k.startsWith('phone') &&
            !k.startsWith('date') &&
            !k.startsWith('time') &&
            !k.startsWith('productId')
        ) {
            // Only add to new object if the key does not start with 'phone', 'date'
            newObj[k] = isNaN(Number(v)) ? v : Number(v); // Parse numbers stored as strings
        }
        return newObj;
    }, {});
};


const deepSearch = (obj, key) => {
    let values = [];

    const helper = (obj, key) => {
        if (obj.hasOwnProperty(key)) {
            values.push(obj[key]);
        }

        for (let i = 0; i < Object.keys(obj).length; i++) {
            if (typeof obj[Object.keys(obj)[i]] === "object") {
                helper(obj[Object.keys(obj)[i]], key);
            }
        }
    };

    helper(obj, key);
    return values;
};

export const findMaxNumber = (formattedData, dataCheckedKeys) => {
    // First, filter out the unwanted keys
    const filteredData = formattedData.map(deepFilterFields);

    let maxNumber = Number.MIN_SAFE_INTEGER;

    dataCheckedKeys.forEach(key => {
        let values = deepSearch(filteredData, key);
        values = values.map(value => parseInt(value)).filter(value => !isNaN(value)); // Parsing string numbers to actual numbers
        let maxKeyValue = Math.max(...values);
        if (!isNaN(maxKeyValue) && maxKeyValue > maxNumber) {
            maxNumber = maxKeyValue;
        }
    });

    return maxNumber;
};


export const toPercent = (decimal, fixed = 0) => `${parseFloat((decimal * 100)?.toFixed(fixed))}%`;

export const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio, 2);
};

export const processObject = (obj, parentKey) => {
    let output = [];
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // if the value is another object, recursively process it
            output.push(...processObject(obj[key], `${parentKey}${key}.`));
        } else {
            // if the value is not an object, add key-value pair to the text
            output.push({ key: `${parentKey}${key}`, value: obj[key] });
        }
    }
    return output;
};

export const findMaxKey = (dataCheckedKeys, formattedData) => {
    let maxVal = -Infinity;  // Initializing with a value guaranteed to be lower than any expected value
    let resultKey = null;    // Key associated with maxVal

    // Go through each object in the data
    for (let obj of formattedData) {
        for (let key of dataCheckedKeys) {
            let value;
            // Check if key exists in the object or its nested advanceFields object
            if (obj[key] !== undefined) {
                value = obj[key];
            } else if (obj.advanceFields && obj.advanceFields[key] !== undefined) {
                value = obj.advanceFields[key];
                key = `advanceFields.${key}`;  // prepend 'advanceFields.' to the key
            }

            // If the found value is greater than our current maxVal, update maxVal and resultKey
            if (value !== undefined && value > maxVal) {
                maxVal = value;
                resultKey = key;
            }
        }
    }

    return resultKey;
}


const findKeyWithHighestValue = (dataCheckedKeys, formattedData) => {
    let maxValues = {};

    for (let key of dataCheckedKeys) {
        for (let obj of formattedData) {
            if (obj[key] !== undefined && (maxValues[key] === undefined || obj[key] > maxValues[key])) {
                maxValues[key] = obj[key];
            } else if (obj.advanceFields && obj.advanceFields[key] !== undefined &&
                (maxValues[key] === undefined || obj.advanceFields[key] > maxValues[key])) {
                maxValues[key] = obj.advanceFields[key];
            }
        }
    }

    // Sort keys by their maximum value
    let sortedKeys = Object.entries(maxValues).sort((a, b) => b[1] - a[1]);

    // Safety check to ensure sortedKeys isn't empty
    if (sortedKeys.length === 0) {
        console.error("No matching keys with maximum values found.");
        return null;
    }

    return sortedKeys[0][0];
}

export const sortByKeyWithHighestValue = (dataCheckedKeys, formattedData) => {
    let highestKey = findKeyWithHighestValue(dataCheckedKeys, formattedData);

    // Additional check to handle case where no highest key was found
    if (!highestKey) return formattedData;

    return formattedData.sort((a, b) => {
        let aValue = a[highestKey] || (a.advanceFields && a.advanceFields[highestKey]) || 0;
        let bValue = b[highestKey] || (b.advanceFields && b.advanceFields[highestKey]) || 0;
        return aValue - bValue;  // sort in ascending order
    });
}

export const sortKeysByData = (dataCheckedKeys, formattedData) => {
    const maxValueForKeys = {};

    for (let key of dataCheckedKeys) {
        let maxVal = -Infinity;

        for (let obj of formattedData) {
            let value;

            if (obj[key] !== undefined) {
                value = obj[key];
            } else if (obj.advanceFields && obj.advanceFields[key] !== undefined) {
                value = obj.advanceFields[key];
            }

            if (value !== undefined) {
                if (value > maxVal) maxVal = value;
            }
        }

        maxValueForKeys[key] = maxVal;
    }

    // Sort the dataCheckedKeys array based on the determined max values in descending order
    dataCheckedKeys.sort((a, b) => maxValueForKeys[a] - maxValueForKeys[b]);

    return dataCheckedKeys;
}

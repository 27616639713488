// RadioButton.js
import React from 'react';
import styled from 'styled-components';
import ConditionalRender from '../ConditionalRender';
import HoverPopup from '../HoverPopup';
import { useTheme } from '../../context/ThemeContext';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';
import colors from '../../globalStyles.scss';

const RadioButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    background-color: ${colors.backgroundSecondary};
    padding: 1em;
    width: ${(props) => props.width || 'auto'};
    .UncontrolledTooltip {
    #client-popup-radio-button {
        color: ${colors.white};
        background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
        background: ${(props) => props.showLightColors && colors.backgroundSecondary};
        a {
            color: ${(props) => props.theme === 'dark' ? colors.lightGray : colors.black};
            color: ${(props) => props.showLightColors && colors.white};
        }
    }
    }


  input[type="radio"] {
    margin-right: 1em;
    appearance: auto;
    cursor: pointer;
    }

    label {
    cursor: pointer;
    color: ${colors.white};
  }
`;

const RadioButton = ({
    id,
    name,
    value,
    checked,
    onChange,
    label,
    width,
    showLightColors,
    goto
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();


    return (
        <RadioButtonWrapper
            width={width}
            showLightColors={showLightColors}
            theme={theme}
        >
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <ConditionalRender renderIf={!goto}>
                <label htmlFor={id}>
                    {label}
                </label>
            </ConditionalRender>
            <ConditionalRender renderIf={goto}>
                <HoverPopup
                    style={{
                        display: 'flex',
                        background: theme === 'dark' ? colors.backgroundSecondary : colors.darkGray,
                        background: showLightColors && colors.backgroundSecondary,
                        color: theme === 'dark' ? colors.white : colors.black,
                        color: showLightColors && colors.white,
                    }}
                    placement={'top'}
                    id={'client-popup-radio-button'}
                    className={'hover-popup'}
                    text={text?.inventory?.landing?.table?.columns?.details}
                >
                    <Link to={goto}>
                        {label}
                    </Link>
                </HoverPopup>
            </ConditionalRender>
        </RadioButtonWrapper>
    );
};

export default RadioButton;

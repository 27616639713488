import React from 'react'
import ConfirmModal from '../../Core/ConfirmModal'
import colors from '../../globalStyles.scss'
import { renderLabel } from '../../../src/Dashboard/Charts/helperFunctions';
import useScreenSize from '../../context/useScreenSize';

const TemplateModal = ({
    onClick,
    isOpen,
    selectedTemplate,
    inventoryColumns,
    toggle,
    header,
    description,
    confirmText,
    extraFields,
    ...props
}) => {
    const { isDesktop } = useScreenSize();
    return (
        <ConfirmModal
            onClick={onClick}
            text={confirmText}
            toggle={toggle}
            isOpen={isOpen}
            width={isDesktop ? '50%' : '100%'}
            height={isDesktop ? '50%' : '100%'}
            btnStyles={{
                color: colors.blue
            }}
            {...props}
        >
            <div>
                <h3 className='mb-md'>
                    {header}
                </h3>
                {
                    description && <p>{description}</p>
                }
                {extraFields && extraFields()}
                <div className='mt-md'>
                    {Object.entries(selectedTemplate).map(([key, value], index, array) => {
                        let content = null;
                        if (key === 'productId' || key === 'template' || key === 'id' || key === 'templateName' || key === 'createdBy') return null;
                        if (!value) return null;
                        // if (!value.value && key !== 'inventoryQuantity') return null;


                        if (key === 'inventoryCategory') {
                            content = <p>{`${renderLabel(inventoryColumns, key)}: ${value.label}`}</p>;
                        } else if (key === 'inventoryQuantity') {
                            if (!value.value) return null;
                            content = (
                                <div>
                                    <p>{`${renderLabel(inventoryColumns, key)}: ${value.value}`}</p>
                                    <div className='ml-md'>
                                        <p>{`Notify me: ${value?.notification?.isChecked ? 'Yes' : 'No'}`}</p>
                                        {
                                            value?.notification?.isChecked && <p>{`If number is below: ${value?.notification?.value || ''}`}</p>
                                        }
                                    </div>
                                </div>
                            );
                        } else if (key === 'isPriority') {
                            content = <p>{`${renderLabel(inventoryColumns, key)}: ${value ? 'Yes' : 'No'}`}</p>;
                        } else if (key === 'advanceFields') {
                            content = (
                                <div>
                                    {Object.entries(value).map(([advKey, advValue], advIndex) => {
                                        const type = advKey.split('-')[0];
                                        if (type === 'number') {
                                            return (
                                                <div key={advIndex}>
                                                    <p>{`${renderLabel(inventoryColumns, advKey)}: ${advValue.value || 0}`}</p>
                                                    <div className='ml-md'>
                                                        <p>{`Notify me: ${advValue.notification?.isChecked ? 'Yes' : 'No'}`}</p>
                                                        {
                                                            advValue.notification?.isChecked && <p>{`If number is below: ${advValue.notification?.value || ''}`}</p>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return <p key={advIndex}>{`${renderLabel(inventoryColumns, advKey)}: ${advValue}`}</p>;
                                    })}
                                </div>
                            );
                        } else {
                            content = <p>{`${renderLabel(inventoryColumns, key)}: ${value}`}</p>;
                        }
                        return (
                            <React.Fragment key={index}>
                                {content}
                                {index < array.length - 1 && <hr style={{
                                    margin: '0.5rem 0',
                                    border: 'none',
                                    borderBottom: `2px solid ${colors.lightGray}`
                                }} />}
                                {
                                    array.length < 1 && <p>No data to display</p>
                                }
                            </React.Fragment>
                        );
                    })}
                </div>

            </div>
        </ConfirmModal>
    )
}

export default TemplateModal
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { FormSpy, Field } from 'react-final-form';
import { isEqual } from 'lodash';
import FormActions from '../Core/FormActions';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import CheckBox from '../Core/Inputs/CheckBox';

import FieldWithValidation from '../Core/FieldWithValidation'
import CustomField from '../Core/CustomField'
import inputTypeOptions from './inputTypeOptions';
import AutocompleteDropdown from '../Core/Inputs/AutocompleteDropdown'
import ConditionalRender from '../Core/ConditionalRender';
import SelectInput from '../Core/SelectInput'
import MultiFields from '../Core/MultiFields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { composeValidators, isRequired } from '../utils/validators';
import { isEmpty } from 'lodash';
import CallToAction from '../Core/CallToAction';
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';


const StyledInventoryAdvanceFields = styled.div`
display: flex;
flex-direction: column;
margin-top: 30px;
.render-fields {
    display: flex;
    align-items: center;
    background: ${colors.lightGray};
    padding: 10px;
}
    .title {
        align-self: start;
        display: flex;
        margin: 30px 0;
        font-size: 1.5rem;
        font-family: ${colors.openSans};
    }
    .form-group {
        margin: 0.2rem;
    }
    .wrapper {
        .delete {
            svg {
                color: ${colors.red};
            }
        }
    }
`;

const CustomForm = ({
    handleSubmit,
    product,
    disabled,
    newFieldName,
    addField,
    handleOnChange,
    fieldError,
    newAdvanceFields,
    errorMessage,
    handleInputChange,
    updatedKeys,
    removeField,
    setInitialValues,
    initialValues,
    setNewAdvanceFields,
    setUpdatedKeys,
    setProduct,
    fieldNames,
    categories,
    inventory,
    inventoryColumns,
    setIsEditing,
    isEditing,
    onFormChange,
    onSubmit,
    projects,
    form,
    user,
    isAdmin,
    ...rest
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isPhone, isTablet } = useScreenSize();
    const [categoryInitial, setCategoryInitial] = useState({})
    const [projectInitial, setProjectInitial] = useState({ label: product?.projectId?.projectName || text?.inventory?.details?.form?.unassigned, value: product?.projectId?.id || '000000000000000000000000' })
    // Pass the form instance up to the parent component using a callback prop
    useEffect(() => {
        onFormChange(form);
    }, []);

    useMemo(() => {
        if (product?.inventoryCategory) {
            setCategoryInitial({
                label: product?.inventoryCategory,
                value: product?.inventoryCategory
            })
        } else {
            setCategoryInitial({
                label: 'Uncategorized',
                value: 'uncategorized'
            })
        }

    }, [product?.inventoryCategory])


    useMemo(() => {
        setProjectInitial({ label: product?.projectId?.projectName || text?.inventory?.details?.form?.unassigned, value: product?.projectId?.id || '000000000000000000000000' })
    }, [])

    const handleChange = (e, fieldName) => {
        if (e?.value) {
            if (e?.label === '' && e?.value === '') {
                setProduct((prevProduct) => ({ ...prevProduct, [fieldName]: { label: null, value: null } }));
            } else {
                setProduct((prevProduct) => ({ ...prevProduct, [fieldName]: { label: e?.label, value: e?.value } }));
            }
        } else {
            setProduct((prevProduct) => ({ ...prevProduct, [fieldName]: { label: null, value: null } }));
        }
    };

    const nameValidator = composeValidators(
        isRequired(text?.inventory?.details?.form?.validations?.required)
    );

    const [shouldUpdate, setShouldUpdate] = useState(false);

    const updateInitialValues = useCallback((formState) => {
        const newValues = { ...formState.values };

        // Only update initialValues if they're different
        if (!isEqual(formState.initialValues, newValues)) {

            const updatedNewAdvanceFields = newAdvanceFields.map((field) => {
                return {
                    ...field,
                    value: newValues[field.fullFieldName],
                };
            });

            const updatedUpdatedKeys = updatedKeys.map((field) => {
                return {
                    ...field,
                    value: newValues[field.fullFieldName],
                };
            });

            if (!isEqual(updatedNewAdvanceFields, newAdvanceFields)) {
                setNewAdvanceFields(updatedNewAdvanceFields);
            }

            if (!isEqual(updatedUpdatedKeys, updatedKeys)) {
                setUpdatedKeys(updatedUpdatedKeys);
            }
            setShouldUpdate(false);
            setInitialValues(newValues);
        }
        if (!shouldUpdate) return;
    }, [
        updatedKeys,
        newAdvanceFields,
        setNewAdvanceFields,
        setUpdatedKeys,
        setInitialValues,
        shouldUpdate
    ]);
    useEffect(() => {
        setShouldUpdate(true);
    }, []);

    const renderAdvanceFields = (updatedKeys, newAdvanceFields) => {
        const allFields = updatedKeys ? [...newAdvanceFields, ...updatedKeys] : [];

        return allFields?.map((field) => {
            const renderLabel = fieldNames?.find((x) => x.name === field.fieldValue);


            return (
                <div className='flex flex-column mt-md'>
                    <div className='flex wrapper'>
                        {
                            field.fieldName === 'number' ? (
                                <div className='flex flex-column w-100'>
                                    <MultiFields
                                        name={`${field?.fullFieldName}.value`}
                                        label={renderLabel?.label || field.fieldValue}
                                        component={field?.fieldName}
                                        min={field?.fieldName === 'number' && { value: "0" }}
                                        setIsEditing={setIsEditing}
                                        form={form}
                                        width={field?.fieldName === 'number' && isDesktop ? 30 : isTablet ? 50 : 100}
                                        block
                                        edit
                                    />
                                    <FormSpy>
                                        {({ values }) => {
                                            const hasValue = values?.[field?.fullFieldName]?.value;
                                            const isChecked = values?.[field?.fullFieldName]?.notification?.isChecked;


                                            return (
                                                <>
                                                    {
                                                        (hasValue || hasValue === 0) && (
                                                            <Field name={`${field?.fullFieldName}.notification.isChecked`} >
                                                                {({ input, meta }) => (
                                                                    <CheckBox
                                                                        {...input}
                                                                        name={`${field?.fullFieldName}.notification.isChecked`}
                                                                        type="checkbox"
                                                                        checked={input.value}
                                                                        onChange={(value) => {
                                                                            input.onChange(value);
                                                                            setIsEditing(true)
                                                                        }}
                                                                        disabled={disabled}
                                                                        label={text?.inventory?.details?.form?.nodifyMe}
                                                                        checkStyle={{
                                                                            background: colors.blue
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )
                                                    }
                                                    {
                                                        (isChecked && (hasValue || hasValue === 0)) ? (
                                                            <div className='flex items-center'>
                                                                <p className='mr-md'>{text?.inventory?.details?.form?.numberBellow}</p>
                                                                <FieldWithValidation
                                                                    validate={categoryValidator}
                                                                    disabled={disabled}
                                                                    setIsEditing={setIsEditing}
                                                                    className='field-style'
                                                                    name={`${field?.fullFieldName}.notification.value`}
                                                                    component="input"
                                                                    type="number"
                                                                    min="0"
                                                                    label={text?.inventory?.details?.form?.quantity}
                                                                />
                                                            </div>
                                                        ) : null
                                                    }
                                                </>
                                            )
                                        }}
                                    </FormSpy>
                                </div>
                            ) : (
                                <MultiFields
                                    name={field?.fullFieldName}
                                    label={renderLabel?.label || field.fieldValue}
                                    component={field?.fieldName}
                                    disabled={disabled}
                                    fieldName={field?.fullFieldName}
                                    setIsEditing={setIsEditing}
                                    form={form}
                                    suggestionData={inventory}
                                    autoComplete
                                    fields={inventoryColumns}
                                    block
                                    edit
                                    withTimePicker
                                />
                            )
                        }
                        {
                            !disabled && (
                                <button className='delete' style={{
                                    display: 'flex',
                                    marginLeft: '15px',
                                    height: '20%'
                                }} type="button" onClick={() => removeField(field, field.id)}>
                                    <FontAwesomeIcon icon={faClose} />
                                </button>
                            )
                        }
                    </div>

                    <hr />
                </div>
            )
        });
    };

    const categoryValidator = composeValidators(
        isRequired(text?.inventory?.details?.form?.validations?.required)
    )

    return (
        <StyledInventoryAdvanceFields>
            <form onSubmit={handleSubmit}>
                <FormSpy onChange={updateInitialValues} />
                <div style={{ display: 'none' }}>
                    <FieldWithValidation
                        label={'ID'}
                        name={'productId'}
                        text={product?.productId}
                        initialValue={product?.productId}
                        disabled
                    />
                </div>
                <ConditionalRender renderIf={isAdmin}>
                    <SelectInput
                        name='projectId'
                        placeholder={text?.inventory?.details?.form?.selectProject}
                        validate={categoryValidator}
                        onChange={(e) => handleChange(e, 'projectId')}
                        isValidNewOption={() => false}
                        initialValue={projectInitial}
                        setIsEditing={setIsEditing}
                        options={[
                            ...(user?.roleName === 'Admin' ? [{ label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' }] : []), // Add this option conditionally based on user role
                            ...(projects ? projects?.filter((x) => !x.isCompleted && x?.features?.inventory)?.map((x) => ({
                                label: x?.projectName,
                                value: x?.id
                            })) : [])
                        ]}
                    />
                </ConditionalRender>
                <FieldWithValidation
                    className='field-style'
                    label={text?.inventory?.details?.form?.name}
                    name={'inventoryName'}
                    fieldName={`inventoryName`}
                    suggestionData={inventory}
                    fields={inventoryColumns}
                    text={product?.inventoryName}
                    initialValue={product?.inventoryName}
                    disabled={disabled}
                    validate={nameValidator}
                    setIsEditing={setIsEditing}
                    autoComplete
                />
                <AutocompleteDropdown
                    className='field-style'
                    name='inventoryCategory'
                    label={text?.inventory?.details?.form?.category}
                    options={
                        categories
                            ?.filter((x) => x) // Remove null/undefined items
                            ?.reduce((unique, item) => {
                                const lowercaseItem = item.toLowerCase();
                                // Check if the item is already in the unique array
                                if (!unique.some(existingItem => existingItem.toLowerCase() === lowercaseItem)) {
                                    unique.push(item); // Add the original case item if not found
                                }
                                return unique;
                            }, [])
                    }
                    onChange={(e) => handleChange(e, 'inventoryCategory')}
                    initialValue={categoryInitial}
                    placeholder='Category'
                    disabled={disabled}
                    setIsEditing={setIsEditing}
                />

                <div>
                    <FieldWithValidation
                        className='field-style'
                        label={text?.inventory?.details?.form?.quantity}
                        name={'inventoryQuantity.value'}
                        text={product?.inventoryQuantity?.value}
                        setIsEditing={setIsEditing}
                        initialValue={product?.inventoryQuantity?.value}
                        disabled={disabled}
                        type='number'
                        min="0"
                        allowNegatives={false}
                        width={isDesktop ? 30 : isTablet ? 50 : 100}
                    />

                    <Field name={'inventoryQuantity.notification.isChecked'} type={'checkbox'}>
                        {({ input, meta }) => (
                            <CheckBox
                                {...input}
                                {...meta}
                                name={'inventoryQuantity.notification.isChecked'}
                                checked={input.checked}
                                disabled={disabled}
                                type="checkbox"
                                onChange={(value) => {
                                    input.onChange(value);
                                    setIsEditing(true);
                                }}
                                label={text?.inventory?.details?.form?.nodifyMe}
                            />
                        )}
                    </Field>

                    <FormSpy>
                        {({ values }) => {
                            const isChecked = values?.inventoryQuantity?.notification?.isChecked;

                            return isChecked ? (
                                <div className='flex items-center'>
                                    <p className='mr-md'>{text?.inventory?.details?.form?.numberBellow}</p>
                                    <FieldWithValidation
                                        validate={categoryValidator}
                                        disabled={disabled}
                                        setIsEditing={setIsEditing}
                                        className='field-style'
                                        name={'inventoryQuantity.notification.value'}
                                        component="input"
                                        type="number"
                                        min="0"
                                        label={text?.inventory?.details?.form?.quantity}
                                        width={50}
                                    />
                                </div>
                            ) : null;
                        }}
                    </FormSpy>

                </div>
                <>
                    <ConditionalRender renderIf={!isEmpty(product?.advanceFields) || !disabled}>
                        <h3 className='title'>{text?.inventory?.details?.form?.title}</h3>
                    </ConditionalRender>

                    {
                        !disabled && (
                            <div className='flex flex-column'>
                                <CustomField
                                    value={newFieldName}
                                    placeholder={text?.inventory?.details?.form?.selectFieldName}
                                    onChange={handleOnChange}
                                    errorMessage={fieldError}
                                    type={'text'}
                                    wrapperStyles={{ width: 'auto' }}
                                    fieldNames={fieldNames?.map((x) => x?.label)}
                                    newFieldName={newFieldName}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                                <SelectInput
                                    name={'customFieldType'}
                                    options={inputTypeOptions(text)}
                                    onChange={(e) => handleInputChange(e, form)}
                                    placeholder={text?.inventory?.details?.form?.fieldType}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                                <CallToAction
                                    wrapperClassName={classnames({
                                        'mt-md': true
                                    })}
                                    type="button"
                                    onClick={addField}
                                    disabled={!isEmpty(fieldError)}
                                    text={text?.inventory?.details?.form?.addField}
                                    style={{
                                        alignSelf: 'flex-end',
                                        backgroundColor: theme === 'dark' ? colors.secondary : colors.black
                                    }}
                                />
                                <span className='small error mt-md align-self-end'>
                                    {errorMessage}
                                </span>
                                <hr />
                            </div>
                        )
                    }
                    {renderAdvanceFields(updatedKeys, newAdvanceFields)}
                </>
                {
                    (!disabled && isEditing) && (
                        <FormActions
                            form={form}
                            type="submit"
                            floating
                            submitText={text?.inventory?.details?.form?.button}
                            btnStyle={{
                                color: colors.blue
                            }}
                        />
                    )
                }
            </form>
        </StyledInventoryAdvanceFields>
    );
}

export default CustomForm
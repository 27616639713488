import React from 'react';
import { Field } from 'react-final-form';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';

import 'ace-builds/src-noconflict/ext-searchbox';


const CodeEditorField = ({ name, ...props }) => {
    return (
        <Field name={name}>
            {({ input }) => (
                <AceEditor
                    {...input}
                    placeholder="// extracted code"
                    mode="javascript"
                    theme="monokai"
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    style={{
                        width: '100%',
                        minWidth: '600px'
                    }}
                    wrapEnabled
                    highlightActiveLine={true}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        useWorker: false
                    }}
                    {...props}
                />
            )}
        </Field>
    );
};

export default CodeEditorField;

// ToastContext.js
import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

const toastOptions = {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    position: 'bottom-left',
};

const notify = (message, type = "default", autoClose = 2000) => {
    if (type === 'error') {
        autoClose = false;
    }
    switch (type) {
        case "success":
            toast.success(message, { ...toastOptions, autoClose });
            break;
        case "error":
            toast.error(message, { ...toastOptions, autoClose });
            break;
        case "info":
            toast.info(message, { ...toastOptions, autoClose });
            break;
        case "warning":
            toast.warn(message, { ...toastOptions, autoClose });
            break;
        default:
            toast(message, { ...toastOptions, autoClose });
            break;
    }
};

export const ToastProvider = ({ children }) => {
    return (
        <ToastContext.Provider value={{ notify }}>
            {children}
            <ToastContainer />
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
    getProduct,
    updateProductImages,
    deleteProduct,
    updateProducts,
    updateFields,
    updateColumns,
    createProduct
} from '../utils/calls'
import { isEmpty } from 'lodash';
import queryString from "query-string";
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from "react-router-dom";
import NotFound from '../Home/NotFound';
import Loader from '../Core/Loader';
import RenderImages from '../Core/RenderImages';
import { capitalize } from 'lodash';
import { toCamelCase, compareStrings } from '../utils/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-final-form';
import HoverPopup from '../Core/HoverPopup';
import { useDropzone } from 'react-dropzone';
import Wrapper from '../Core/Wrapper';
import { noSpecialChars, noNumbers } from '../utils/validators';
import CustomForm from './CustomForm';
import base64ToBlob from '../utils/base64ToBlob';
import styled from 'styled-components'
import { btnUnderline } from '../mixins';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import EnabledDisabledToggle from '../Core/EnabledDisabledToggle';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext'
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';

import colors from '../globalStyles.scss'

const StyledContainer = styled.div`
    padding-bottom: 30px;
    &.desktop {
        .form-wrapper {
            display: flex;
            .custom-form {
                flex: 1;
            }
        }
    }
    .form-wrapper {
        .custom-form {
            width: 100%;
            text-align: center;
            margin-right: 1rem;
            .UncontrolledTooltip {
                display: flex;
            }
            .delete {
                padding: 15px 0 5px;
                position: relative;
                display: flex;
                cursor: pointer;
                color: ${colors.red};
                font-family: ${colors.openSans};
                ${btnUnderline(colors.red)};
            }
            .clone {
                padding: 15px 0 5px;
                position: relative;
                display: flex;
                cursor: pointer;
                font-family: ${colors.openSans};
            }
        }
    .image-uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dropzone {
            display: flex;
            flex-direction: column;
            margin: 0.5em 0;
            padding: 1em;
            background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                background: ${colors.lightGray};
            }
            span {
                font-family: ${colors.roboto};
                color: ${props => props.theme === 'dark' ? colors.black : colors.black};
            }
        }
    }
    }
`;

const InventoryDetailsContainer = ({
    fieldNames,
    categories,
    inventory,
    fetchAttributes,
    fetchInventoryProducts,
    inventoryColumns,
    showChat,
    handleToggleChat,
    handleSendMessage,
    user,
    projects,
    maxFiles = 10
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const { edit } = queryString.parse(location.search);

    const [product, setProduct] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [uploadedImages, setUploadedImages] = useState(product?.images);
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false)
    const [toggleCloneModal, setToggleCloneModal] = useState(false)

    const [newFieldName, setNewFieldName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldError, setFieldError] = useState('');

    const [fieldNameAndValues, setFieldNameAndValues] = useState([]);
    const [columnNameAndValues, setColumnNameAndValues] = useState(inventoryColumns);

    const [selectedFieldType, setSelectedFieldType] = useState('Field Type'); // New state for selected input type

    const [disabled, setDisabled] = useState(false);

    const [isPriority, setIsPriority] = useState(product?.isPriority);

    const [imageError, setImageError] = useState('')

    const queryId = queryString.parse(location.search).id;
    const [id, setId] = useState(queryId)

    useEffect(() => {
        setId(queryString.parse(location.search).id)
    }, [queryId])


    useMemo(() => {
        setIsPriority(product?.isPriority);
    }, [product?.isPriority])
    useEffect(() => {
        if (edit === 'true') {
            setDisabled(false)
        }
        fetchInventoryProducts()
    }, [])

    const [updatedKeys, setUpdatedKeys] = useState([]);
    const [newAdvanceFields, setNewAdvanceFields] = useState([]);

    const [formattedFieldNames, setFormattedFieldNames] = useState(
        fieldNames?.map((x) => {
            return {
                name: x.name,
                label: x.label
            }
        })
    );

    const [formInstance, setFormInstance] = useState(null);
    const [initialValues, setInitialValues] = useState({});


    const handleFormChange = (form) => {
        setFormInstance(form);
    };

    useMemo(() => {
        if (product?.advanceFields) {
            const field = Object.entries(product?.advanceFields).map(([key, value]) => {
                const field = key?.split('-')[0];
                const fieldValue = key?.split('-')[1];
                return {
                    fieldName: field,
                    fullFieldName: key,
                    fieldValue: fieldValue,
                    value
                };
            });
            setUpdatedKeys(field);
        }
    }, [product?.advanceFields]);

    useMemo(() => {
        setColumnNameAndValues(inventoryColumns)
    }, [inventoryColumns])

    const removeField = useCallback(
        (fieldToRemove, fieldId) => {
            setNewAdvanceFields((prevFields) =>
                prevFields.filter((field) => field.fullFieldName !== fieldToRemove.fullFieldName)
            );
            setFieldNameAndValues((prev) => {
                return prev.filter(field => field.fullFieldName !== fieldToRemove.fullFieldName)
            });

            setColumnNameAndValues((prevColumnNameAndValues) => {

                const removedColumn = prevColumnNameAndValues.find((column) => column.fullFieldName === fieldToRemove.fullFieldName);
                const removedSortOrder = parseInt(removedColumn?.sortOrder, 10);

                const maxSortOrder = prevColumnNameAndValues.reduce((max, column) => {
                    const sortOrder = parseInt(column?.sortOrder, 10);
                    return sortOrder > max ? sortOrder : max;
                }, 0);

                let adjustedColumnNameAndValues = prevColumnNameAndValues;

                if (removedSortOrder < maxSortOrder) {
                    adjustedColumnNameAndValues = prevColumnNameAndValues.map((column) => {
                        if (parseInt(column?.sortOrder, 10) > removedSortOrder) {
                            return {
                                ...column,
                                sortOrder: (parseInt(column?.sortOrder, 10) - 1).toString(),
                            };
                        }
                        return column;
                    });
                }

                return adjustedColumnNameAndValues.filter((column) => column.fullFieldName !== fieldToRemove.fullFieldName);
            });
            setUpdatedKeys((prevKeys) =>
                prevKeys.filter((field) => field.fullFieldName !== fieldToRemove.fullFieldName)
            );
            setInitialValues((prevValues) => {
                const newValues = { ...prevValues };
                delete newValues[fieldToRemove.fullFieldName];
                return newValues;
            });
            setIsEditing(true)
        },
        [setNewAdvanceFields, setUpdatedKeys]
    );

    const newInitialUpdatedValues = useMemo(() => {
        let newValues = {};
        if (updatedKeys && updatedKeys.length > 0) {
            updatedKeys.forEach(({ fullFieldName, value }) => {
                newValues[fullFieldName] = value;
            });
        }
        return newValues;
    }, [updatedKeys]);

    const newInitialAdvanceValues = useMemo(() => {
        let newValues = {};
        if (newAdvanceFields && newAdvanceFields.length > 0) {
            newAdvanceFields.forEach(({ fullFieldName, value }) => {
                newValues[fullFieldName] = value;
            });
        }
        return newValues;
    }, [newAdvanceFields]);

    useEffect(() => {
        setInitialValues((prevValues) => ({ ...prevValues, ...newInitialUpdatedValues, ...newInitialAdvanceValues }));
    }, [newInitialUpdatedValues, newInitialAdvanceValues]);


    const addField = useCallback(() => {
        if (newFieldName && selectedFieldType !== 'Field Type') {
            if (
                updatedKeys?.some((field) => compareStrings(field.fullFieldName.split('-')[1], newFieldName)) ||
                newAdvanceFields?.some((field) => compareStrings(field.fullFieldName.split('-')[1], newFieldName))
            ) {
                setFieldError(text?.inventory?.details?.validations?.exists);
            } else {
                const newId = uuidv4(); // Generate a new UUID

                const newField = {
                    fullFieldName: `${selectedFieldType}-${toCamelCase(newFieldName)}`,
                    label: capitalize(newFieldName),
                    fieldName: toCamelCase(selectedFieldType),
                    fieldValue: capitalize(newFieldName),
                    value: null,
                };
                setNewAdvanceFields([newField, ...newAdvanceFields]);
                setFieldNameAndValues([
                    ...newAdvanceFields,
                    {
                        id: newId,
                        label: capitalize(newFieldName),
                        name: toCamelCase(newFieldName),
                        fullFieldName: `${selectedFieldType}-${toCamelCase(newFieldName)}`,

                    }
                ]);
                setColumnNameAndValues((prevColumnNameAndValues) => {
                    const maxSortOrder = prevColumnNameAndValues.reduce(
                        (max, column) => {
                            const sortOrder = parseInt(column.sortOrder, 10); // Convert sortOrder to number
                            return sortOrder > max ? sortOrder : max;
                        },
                        0
                    );
                    const newSortOrder = maxSortOrder + 1; // Increment sortOrder as a number

                    return [
                        ...prevColumnNameAndValues,
                        {
                            id: newId,
                            fieldLabel: capitalize(newFieldName),
                            fieldName: toCamelCase(newFieldName),
                            fullFieldName: `${selectedFieldType}-${toCamelCase(newFieldName)}`,

                            isActive: false,
                            sortOrder: newSortOrder.toString(), // Convert back to string
                        },
                    ];
                });
                setInitialValues((prevValues) => {
                    const newValues = { ...prevValues };
                    newValues[newField.fullFieldName] = newField.value;
                    return newValues;
                    // temporary fix to clear fields
                }, setTimeout(() => {
                    formInstance.change('customFieldName', null);
                    formInstance.change(`customFieldType`, null);
                    setNewFieldName('');
                    setErrorMessage('');
                    setSelectedFieldType('Field Type');
                }, 500));

                setIsEditing(true);
            }
        } else {
            if (!newFieldName) {
                setErrorMessage(text?.inventory?.details?.validations?.fieldName);
            } else {
                setErrorMessage(text?.inventory?.details?.validations?.fieldType);
            }
            return null;
        }
    }, [selectedFieldType, newFieldName]);


    const handleOnChange = (e) => {
        const newValue = e?.value;
        setNewFieldName(newValue);
        const eValue = e?.value.replace(/\s/g, ""); // remove spaces from e.value
        const fieldExists = updatedKeys?.some((field) => compareStrings(field.fieldValue, eValue));

        if (fieldExists) {
            setFieldError(text?.inventory?.details?.validations?.exists);
        } else {
            setFieldError(
                // noSpaces(newValue) ||
                noNumbers(newValue) ||
                noSpecialChars(newValue)
            );
        }
        setIsEditing(true)
    };

    const handleInputChange = (e, form) => {
        if (!e?.value) {
            setSelectedFieldType('Field Type');
            form.change('customFieldType', null);
        } else {
            setSelectedFieldType(e?.value);
        }
    }

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.inventory?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setUploadedImages((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];

                return updatedImages;
            });
            setIsEditing(true)
            setImageError('')
        }
    }, []);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });


    const onSubmit = async (values) => {
        setIsLoading(true)
        const combinedFields = [...updatedKeys, ...newAdvanceFields];
        const advanceFieldsKeys = combinedFields.map(({ fullFieldName }) => fullFieldName);
        let advanceFields = advanceFieldsKeys.reduce((acc, key) => {
            if (values[key]) {
                acc[key] = values[key];
                // Include corresponding unit keys
                if ((
                    key.startsWith('length') && !key.endsWith('unit')
                ) || (
                        key.startsWith('weight') && !key.endsWith('unit')
                    ) || (
                        key.startsWith('volume') && !key.endsWith('unit')
                    )
                ) {
                    const unitKey = `${key}-unit`;
                    if (values[unitKey]) {
                        acc[unitKey] = values[unitKey];
                    }
                }
            }
            return acc;
        }, {});

        const processedAdvanceFields = {};

        Object.keys(advanceFields).forEach((key) => {
            const value = advanceFields[key];
            const parts = key.split('-');
            const prefix = parts[0];
            const attribute = parts[parts.length - 1];

            if (prefix === 'number') {
                processedAdvanceFields[key] = {
                    value: Number(value?.value),
                    notification: {
                        isChecked: value?.notification?.isChecked,
                        value: Number(value?.notification?.value)
                    }
                };
            } else if (prefix === 'amount') {
                processedAdvanceFields[key] = isNaN(value) ? value : Number(value);
            } else if ((
                prefix === 'length' && attribute !== 'unit'
            ) || (
                    prefix === 'weight' && attribute !== 'unit'
                ) || (
                    prefix === 'volume' && attribute !== 'unit'
                )
            ) {
                let sizeValue = value;
                const unitKey = `${parts[0]}-${parts[1]}-unit`;

                const newUnit = advanceFields[unitKey]?.value;

                if (newUnit) {
                    const existingUnitIndex = sizeValue.lastIndexOf('-');
                    if (existingUnitIndex > -1) {
                        sizeValue = sizeValue.substring(0, existingUnitIndex);
                    }
                    sizeValue = `${sizeValue}-${newUnit}`;
                }

                processedAdvanceFields[key] = sizeValue;
            } else if (attribute !== 'unit') {
                processedAdvanceFields[key] = value;
            }
        });

        const { customFieldName, ...updatedValues } = values;
        updatedValues.advanceFields = processedAdvanceFields;
        // Remove length-*-unit fields from updatedValues
        Object.keys(updatedValues).forEach(key => {
            if (key.endsWith('-unit')) {
                delete updatedValues[key];
            }
            if (key.startsWith('length')) {
                delete updatedValues[key];
            }
            if (key.startsWith('weight')) {
                delete updatedValues[key];
            }
            if (key.startsWith('volume')) {
                delete updatedValues[key];
            }
        });

        updatedValues.isPriority = isPriority;
        if (updatedValues?.inventoryCategory) {
            if (updatedValues?.inventoryCategory?.value) {
                updatedValues.inventoryCategory = updatedValues?.inventoryCategory?.value?.toLowerCase()
            } else {
                updatedValues.inventoryCategory = values?.inventoryCategory.toLowerCase()
            }
        }
        if (updatedValues?.projectId) {
            updatedValues.projectId = updatedValues?.projectId?.value
        }
        if (!updatedValues?.inventoryQuantity?.value) {
            updatedValues.inventoryQuantity = {
                value: 0
            }
        }


        const processProductImages = async () => {
            const formData = new FormData();
            uploadedImages.forEach((image) => {
                if (image.new) {
                    const imageBlob = base64ToBlob(image?.url);
                    formData.append('newImages', imageBlob, image?.originalName);
                } else {
                    formData.append('oldImages', JSON.stringify(image));
                }
            });

            formData.append('productId', id);
            const res = await updateProductImages(formData)
            if (res.status === 200) {
                await fetchAttributes();
                setIsLoading(false)
                setIsEditing(false);
                handleGetProduct()
                setNewAdvanceFields([])
            } else {
                throw new Error("Images update failed");
            }

            return res;
        };

        const res = await updateProducts(id, updatedValues)
        const projectId = values.projectId
        if (res.status === 200) {
            const fieldRes = await updateFields(fieldNameAndValues, projectId);
            const columnsRes = await updateColumns(columnNameAndValues, projectId);

            if (fieldRes.status !== 200) {
                notify(fieldRes?.data?.message, 'error');
                console.error(fieldRes?.data?.message)
            }
            if (columnsRes.status !== 200) {
                notify(columnsRes?.data?.message, 'error');
                console.error(columnsRes?.data?.message);
            }
            notify(text?.notificationsUI?.inventory?.updated, 'success');
            processProductImages();
        }
    };


    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleDisabledToggle = () => {
        setDisabled(!disabled);
        // remove the edit in the url
        if (edit) {
            const parsed = queryString.parse(location.search);

            delete parsed.edit;
            const newSearch = queryString.stringify(parsed);
            navigate('/inventory/details?' + newSearch);
        }
    };

    const handleGetProduct = async () => {
        try {
            setIsLoading(true)
            if (id) {
                const res = await getProduct(id);
                if (res.status === 200) {
                    const product = res.data;
                    setUploadedImages(res?.data?.images.map((x) => ({ ...x, new: false })))
                    setProduct({ ...product });
                    setIsLoading(false);
                } else {
                    console.error('error: ', res.message)
                    setIsLoading(false)
                }
            }
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleGetProduct()
    }, [id])

    const togglePriority = () => {
        setIsPriority(!isPriority);
        setIsEditing(true)
    }
    const removeImage = (imageIndex) => {
        setUploadedImages((prevImages) => {
            const updatedImages = prevImages.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
        setIsEditing(true)
    };

    const handleDeleteProduct = async () => {
        try {
            const res = await deleteProduct(product?.productId);
            if (res.status === 200) {
                navigate("/"); // Navigate to dashboard
                notify(text?.notificationsUI?.inventory?.deleted, 'success');

            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    };

    const handleCloneProduct = async () => {
        // Create a cloned product by destructuring and omitting unnecessary keys
        const { productId, id, createdAt, updatedAt, _id, __v, user, images, ...clonedProduct } = product;
        const newId = uuidv4(); // Generate a new UUID
        clonedProduct.projectId = product?.projectId?.id || '000000000000000000000000';
        clonedProduct.productId = newId;
        try {
            const res = await createProduct({ products: [clonedProduct] })
            if (res.status === 200) {
                setProduct(res?.data?.products[0]);
                setToggleCloneModal(!toggleCloneModal)
                setUploadedImages(res?.data?.products[0]?.images)
                navigate(`/inventory/details?id=${res?.data?.products[0]?.productId}`)
                handleGetProduct(res?.data?.products[0]?.productId);
                notify(text?.notificationsUI?.inventory?.cloned, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }


    useEffect(() => {
        setId(product?.productId);
        setFormattedFieldNames([...new Set(fieldNames)])
    }, [product?.productId, fieldNames]);

    const adminProjects = projects?.filter(project =>
        project.adminEmployees.some(employee => employee.id === user?.id)
    );
    // Add isAdmin flag to each inventory item
    const isAdmin = adminProjects?.some(project => project?.id === product?.projectId?.id) || user?.roleName === 'Admin';

    return !isLoading && isEmpty(product) ? <NotFound /> :

        (
            <>
                <div className='mb-lg tx-left'>
                    <h4>
                        {text?.projects?.details?.details}
                    </h4>
                    <hr />
                </div>
                <StyledContainer
                    theme={theme}
                    className={classnames({
                        desktop: isDesktop,
                        tablet: isTablet,
                        phone: isPhone
                    })}
                >
                    <ConditionalRender renderIf={false}>
                        {
                            !isLoading && (
                                <ConditionalRender renderIf={!isEmpty(product)}>
                                    <EnabledDisabledToggle
                                        disabled={disabled}
                                        handleDisabledToggle={handleDisabledToggle}
                                        isEditing={isEditing}
                                    />
                                </ConditionalRender>
                            )
                        }
                    </ConditionalRender>
                    {
                        isLoading ? (
                            <Loader count={2} customStyled={{
                                height: '100vh',
                                display: 'flex'
                            }} />
                        ) : (
                            <div className='form-wrapper'>
                                <div className='custom-form'>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <HoverPopup disabled={disabled} onClick={!disabled ? togglePriority : null} placement={'right'} id='toggle-priority-popup-inventory' text={text?.inventory?.details?.priority}>
                                            <FontAwesomeIcon icon={faStar} style={{ color: isPriority ? colors.yellow : colors.white }} />
                                        </HoverPopup>
                                    </div>

                                    <Form
                                        onSubmit={onSubmit}
                                        component={CustomForm}
                                        onFormChange={handleFormChange}
                                        isAdmin={isAdmin}
                                        updatedKeys={updatedKeys}
                                        newAdvanceFields={newAdvanceFields}
                                        product={product}
                                        // disabled={disabled}
                                        newFieldName={newFieldName}
                                        addField={addField}
                                        handleOnChange={handleOnChange}
                                        fieldError={fieldError}
                                        errorMessage={errorMessage}
                                        handleInputChange={handleInputChange}
                                        setInitialValues={setInitialValues}
                                        removeField={removeField}
                                        setNewAdvanceFields={setNewAdvanceFields}
                                        setUpdatedKeys={setUpdatedKeys}
                                        setProduct={setProduct}
                                        fieldNames={formattedFieldNames}
                                        categories={categories}
                                        user={user}
                                        inventory={inventory}
                                        inventoryColumns={inventoryColumns}
                                        projects={projects}
                                        setIsEditing={setIsEditing}
                                        isEditing={isEditing}
                                        initialValues={{
                                            productId: product?.productId,
                                            inventoryName: product?.inventoryName,
                                            inventoryCategory: product?.inventoryCategory,
                                            inventoryQuantity: product?.inventoryQuantity,
                                            projectId: {
                                                label: product?.projectId?.projectName || text?.inventory?.landing?.table?.columns?.unassigned,
                                                value: product?.projectId?.id || '000000000000000000000000'
                                            },
                                            // ADVANCE FIELDS
                                            ...initialValues,
                                        }}
                                        keepDirtyOnReinitialize
                                    />
                                    <ConditionalRender renderIf={isDesktop}>
                                        <div className='flex' style={{
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{ display: 'flex' }}>
                                                <p className='clone' onClick={() => setToggleCloneModal(!toggleCloneModal)}>
                                                    {text?.inventory?.details?.clone}
                                                </p>
                                            </div>
                                        </div>
                                    </ConditionalRender>
                                </div>
                                {/* IMAGES */}

                                <Wrapper
                                    title={'Images'}
                                    width={isDesktop ? '50%' : '100%'}
                                    padding={!isDesktop && '1em 0 0 0'}
                                    button={
                                        <div className='image-uploader'>
                                            {
                                                !disabled && (
                                                    <div
                                                        className='dropzone'
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <span>
                                                            {text?.inventory?.details?.uploadImages}
                                                        </span>

                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                >
                                    <RenderImages
                                        images={uploadedImages}
                                        roleName={user?.roleName}
                                        isEditing={isEditing}
                                        removeImage={removeImage}
                                        openModal={openCarousel}
                                        closeModal={closeModal}
                                        setActiveIndex={setActiveIndex}
                                        activeIndex={activeIndex}
                                        isModalOpen={isModalOpen}
                                        disabled={false}
                                    />
                                </Wrapper>
                                <ConditionalRender renderIf={!isDesktop}>
                                    <div className='flex' style={{
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{ display: 'flex' }}>
                                            <p className='clone' onClick={() => setToggleCloneModal(!toggleCloneModal)}>
                                                {text?.inventory?.details?.clone}
                                            </p>
                                        </div>
                                    </div>
                                </ConditionalRender>
                            </div>
                        )
                    }
                    <ConfirmModal
                        onClick={handleDeleteProduct}
                        text={text?.inventory?.details?.modal?.button}
                        toggle={() => setToggleDeleteModal(!toggleDeleteModal)}
                        isOpen={toggleDeleteModal}
                        btnStyles={{
                            color: colors.red
                        }}
                    >
                        {text?.inventory?.details?.modal?.title}
                    </ConfirmModal>
                    <ConfirmModal
                        onClick={handleCloneProduct}
                        text={text?.inventory?.details?.modal?.button}
                        toggle={() => setToggleCloneModal(!toggleCloneModal)}
                        isOpen={toggleCloneModal}
                    >
                        {text?.inventory?.details?.modal?.title2}
                    </ConfirmModal>
                </StyledContainer >
            </>
        )
}

export default InventoryDetailsContainer
import { useState, useEffect } from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        isPhone: false,
        isTablet: false,
        isDesktop: false,
    });
    const [loading, setLoading] = useState(true);

    const updateScreenSize = () => {
        const width = window.innerWidth;
        setScreenSize({
            isPhone: width <= 480,
            isTablet: width > 480 && width <= 1024,
            isDesktop: width > 1024,
        });
        setLoading(false);
    };

    useEffect(() => {
        updateScreenSize();
        window.addEventListener('resize', updateScreenSize);
        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    return { ...screenSize, loading };
};

export default useScreenSize;

import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimeline, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import HoverPopup from '../Core/HoverPopup';
import Wrapper from '../Core/Wrapper';
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import SearchInput from '../Core/Inputs/SearchInput';
import { composeValidators, isRequired } from '../utils/validators';
import { timeLogsColumns, assignedEmployeesColumns } from '../Invetory/columns';
import CustomTable from '../Core/Tables/CustomTable';
import CallToAction from '../Core/CallToAction';
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext'
import useScreenSize from '../context/useScreenSize'
import { updateDailyLog, getTimesheetById } from '../utils/calls';
import { DateRangePicker } from 'react-dates';

import MultiFields from '../Core/MultiFields';
import ExportLogs from '../Core/ExportLogs';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import SubmitModal from '../Core/SubmitModal';
import { exportLogs } from '../utils/calls/exportLogs';
import { isEmpty } from 'lodash';
import generatePDF from '../Project/utils/generatePDF';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import colors from '../globalStyles.scss'

const StyledDateRangePicker = styled.div`
.DateRangePickerInput {
    border: none;
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.white};
    .DateInput_input__focused {
        border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
    }
    .DateRangePickerInput_arrow {
        margin: 0 1em;
        .DateRangePickerInput_arrow_svg {
            fill: ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
        }
    }
}
    .DateInput {
        background: ${colors.lightGray};
        .DateInput_input {
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .DateRangePickerInput_clearDates_default {
        margin: 0;
        right: 0;
        &:hover {
            background: transparent;
            svg {
                fill: ${colors.red};
            }
        }
        svg {
            fill: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .CalendarDay__hovered_span,
    .CalendarDay__selected_span,
    .CalendarDay__selected {
        background: ${colors.blue};
        border: 1px double ${colors.blue};
        color: white;
    }
`;


const TimeLogContainer = ({
    employees,
    user,
    id,
    timeLogs,
    logsIsLoading,
    projects,
    projectId,
    fetchTimeLogs,
    userRole,
    project,
    isAdmin
}) => {
    const { text, formatDate } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const navigate = useNavigate();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (timeLogs?.length === 0) {
            setTableData([]);
        } else {
            setTableData(timeLogs);
        }
    }, [timeLogs]);




    const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
    const [timeLogIsOpen, setTimeLogIsOpen] = useState(false);
    const [dailyLog, setDailyLog] = useState({})

    const [focusedInput, setFocusedInput] = useState(null);
    const handleDatesChange = ({ startDate, endDate }) => {
        setFilters({
            ...filters,
            startDate,
            endDate
        });
    };

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const openExportModal = () => {
        setExportModalIsOpen(!exportModalIsOpen)
    }

    const handleOpenDailyLog = () => {
        setTimeLogIsOpen(!timeLogIsOpen)
    }

    const filterTableData = async () => {
        const filterOptions = {};

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        if (filters.startDate && filters.endDate) {
            filterOptions.startDate = filters.startDate;
            filterOptions.endDate = filters.endDate;
        }

        let filteredLogs = timeLogs;

        if (filterOptions.query) {
            const query = filterOptions.query.toLowerCase();
            filteredLogs = filteredLogs.filter(log => {
                const dailyLog = log?.dailyLog?.toLowerCase().includes(query);
                const firstName = log?.userId?.firstName?.toLowerCase().includes(query);
                const lastName = log?.userId?.lastName?.toLowerCase().includes(query);
                const fullName = `${log?.userId?.firstName?.toLowerCase()} ${log?.userId?.lastName?.toLowerCase()}`.includes(query);
                return dailyLog || firstName || lastName || fullName;
            });
        }

        if (filterOptions.startDate && filterOptions.endDate) {
            filteredLogs = filteredLogs.filter(log =>
                moment(log.clockIn).isBetween(filterOptions.startDate, filterOptions.endDate, 'day', '[]')
            );
        }

        setTableData(filteredLogs);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, timeLogs]);

    const handleExportLogs = async (values) => {
        const dates = values.dates;
        try {
            openExportModal();
            const res = await exportLogs(id, dates.startDate, dates.endDate)
            if (res.status === 200) {
                const logs = res.data;
                generatePDF(logs, dates, formatDate);
            }
        } catch (error) {
            console.error("Error exporting logs:", error);
        }
    };

    const handleSaveDailyLog = async (values) => {
        const userId = dailyLog?.userId?._id;
        try {
            const res = await updateDailyLog(id, values?.dailyLog, dailyLog?.clockIn, userId, true);
            if (res.status === 200) {
                handleOpenDailyLog();

                fetchTimeLogs(null, id, isAdmin)
            } else {
                console.error(res.message)
                handleOpenDailyLog()
            }

        } catch (error) {
            console.error(error)
        }
    }

    const required = composeValidators(
        isRequired(text?.projects?.details?.validations?.required)
    )

    return (
        <Wrapper
            noDataText={isEmpty(timeLogs) && text?.projects?.details?.noLogsFound}
            title={text?.projects?.details?.logsTitle}
            disableButton={isEmpty(timeLogs)}
            button={
                <CallToAction
                    text={text?.projects?.details?.exportTitle}
                    type='button'
                    onClick={() => openExportModal()}
                />
            }
        >
            <div>
                <div className='flex flex-column mb-md'>
                    <ConditionalRender renderIf={!project?.isCompleted && !isEmpty(timeLogs)}>
                        <HoverPopup
                            className='align-self-start'
                            style={{
                                marginRight: '1rem',
                            }}
                            onClick={() => setShowFilters(!showFilters)}
                            placement={'top'}
                            id='toggle-filters-popup-tasks'
                            text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                        </HoverPopup>
                    </ConditionalRender>
                    <ConditionalRender renderIf={showFilters}>
                        <div
                            className='filters mt-md'
                            style={{
                                flexWrap: 'wrap',
                            }}>
                            <SearchInput
                                className='mt-md'
                                width={isDesktop ? '50%' : isTablet ? '50%' : '100%'}
                                value={filters.query}
                                onChange={handleSearchChange}
                                placeHolder={text?.tasks?.home?.filters?.search}
                            />
                            <StyledDateRangePicker theme={theme} className='flex mt-md'>
                                <DateRangePicker
                                    startDate={filters.startDate}
                                    startDateId="_time_start_date_id"
                                    endDate={filters.endDate}
                                    endDateId="_time_end_date_id"
                                    onDatesChange={handleDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                    isOutsideRange={() => false}
                                    displayFormat={'MM-DD-YYYY'}
                                    showClearDates
                                    numberOfMonths={!isDesktop ? 1 : 2}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText={text?.tasks?.home?.filters?.startDate}
                                    endDatePlaceholderText={text?.tasks?.home?.filters?.endDate}
                                />
                            </StyledDateRangePicker>

                        </div>
                    </ConditionalRender>
                </div>
                <ConditionalRender renderIf={userRole === 'Admin' && !isEmpty(timeLogs)}>
                    <p className='flex tx-left mb-md'>
                        {text?.projects?.details?.logsText}
                    </p>
                </ConditionalRender>
                <ConditionalRender
                    isLoading={logsIsLoading}
                    renderIf={!isEmpty(timeLogs)}
                >

                    <CustomTable
                        keyField="_id"
                        columns={timeLogsColumns({ theme, text, user, formatDate, setTimeLogIsOpen, timeLogIsOpen, setDailyLog })}
                        rows={tableData}
                        theme={theme}
                        noDataText={'No Logs Found'}
                        checkboxSelection={false}
                    />

                    <ConditionalRender renderIf={timeLogIsOpen}>
                        <ConfirmModal
                            text={text?.timeTracker?.modal?.button}
                            toggle={() => handleOpenDailyLog()}
                            isOpen={timeLogIsOpen}
                            btnStyles={{
                                color: colors.blue
                            }}
                            projectId={projectId}
                            onSubmit={handleSaveDailyLog}
                            isForm
                        >
                            <div className="editor w-100">
                                <MultiFields
                                    name="dailyLog"
                                    component="textarea"
                                    label={`${text?.timeTracker?.modal?.dailyLog} ${formatDate(dailyLog?.clockIn)}`}
                                    block
                                    initialValue={dailyLog?.dailyLog || ''}
                                />
                            </div>
                        </ConfirmModal>
                    </ConditionalRender>
                    <CallToAction className='mt-md mb-md' style={{ alignSelf: 'flex-start' }} onClick={(e) => navigate(`/logs`)} text={text?.projects?.details?.viewMore} />
                </ConditionalRender>
            </div>

            <SubmitModal
                onClick={handleExportLogs}
                text={text?.projects?.details?.logsModal?.button}
                toggle={openExportModal}
                isOpen={exportModalIsOpen}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '50%' : '100%'}
                btnStyles={{
                    color: colors.blue
                }}
            >
                <div>
                    <h4 className='mb-md'>
                        {text?.projects?.details?.logsModal?.title}
                    </h4>
                    <p>
                        {text?.projects?.details?.logsModal?.text}
                    </p>
                    <p>
                        {text?.projects?.details?.logsModal?.text2}
                    </p>
                    <Field
                        name="dates"
                        validate={required}
                        component={(props) => (
                            <ExportLogs
                                {...props}
                                projectId={id}
                                validate={required}
                            />
                        )}
                    />
                </div>
            </SubmitModal>
        </Wrapper>
    )
}

export default TimeLogContainer
import React, { createContext, useState, useEffect, useContext } from 'react';
import { GlobalContext } from './GlobalContext';
import { updateUser } from '../utils/calls';
import moment from 'moment';
import 'moment/locale/es'; // Import locales as necessary

export const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const { isLoggedIn, user } = useContext(GlobalContext);
    const [language, setLanguage] = useState('en'); // Initialize with default 'en'
    const [text, setText] = useState({});
    const [languageIsLoading, setLanguageIsLoading] = useState(true);

    // Define the updateLanguage function
    const updateLanguage = async (lang, updateDb = false) => {
        sessionStorage.setItem('language', lang);
        setLanguage(lang);
        if (updateDb && isLoggedIn) {
            try {
                await updateUser({ languageSelected: lang, email: user?.email });
            } catch (error) {
                console.error('Error updating user language:', error);
            }
        }
    };

    useEffect(() => {
        moment.locale(language);
    }, [language]);

    // This function now considers the current language state for formatting
    const formatDate = (date, format = 'LLLL') => {
        return moment.utc(date).format(format);
    };

    useEffect(() => {
        const initializeLanguage = async () => {
            let sessionLanguage = sessionStorage.getItem('language');
            let dbLanguage = user?.languageSelected;
            let effectiveLanguage = 'en'; // Default to 'en'

            if (isLoggedIn) {
                if (dbLanguage && dbLanguage !== 'en') {
                    effectiveLanguage = dbLanguage; // Use DB language if not 'en'
                } else if (sessionLanguage && dbLanguage === 'en') {
                    effectiveLanguage = sessionLanguage; // Use session if DB is 'en'
                } else {
                    effectiveLanguage = dbLanguage || sessionLanguage || 'en';
                }

                if (sessionLanguage !== effectiveLanguage || dbLanguage !== effectiveLanguage) {
                    // Call updateLanguage with true to force DB update
                    await updateLanguage(effectiveLanguage, true);
                } else {
                    setLanguage(effectiveLanguage);
                }
            } else {
                // Not logged in, use session or default 'en'
                effectiveLanguage = sessionLanguage || 'en';
                await updateLanguage(effectiveLanguage);
            }
        };

        initializeLanguage();
    }, [isLoggedIn, user?.languageSelected]); // Depend on isLoggedIn and user's selected language

    useEffect(() => {
        const loadText = async () => {
            setLanguageIsLoading(true);
            try {
                const texts = await import(`../assets/languages/${language}.json`);
                setText(texts);
            } catch (error) {
                console.error('Failed to load language file:', error);
            }
            setLanguageIsLoading(false);
        };
        if (language) {
            loadText();
        }
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, text, updateLanguage, formatDate, languageIsLoading }}>
            {children}
        </LanguageContext.Provider>
    );
};

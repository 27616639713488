import React, { useState } from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceLine, ComposedChart } from 'recharts';
import { Field } from 'react-final-form';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import ConfirmModal from '../Core/ConfirmModal';
import { DateRangePicker } from 'react-dates';
import SelectInput from '../Core/SelectInput';
import Wrapper from '../Core/Wrapper';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import moment from 'moment';

import { useTheme } from '../context/ThemeContext';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import colors from '../globalStyles.scss'

const StyledDateRangePicker = styled.div`
.DateRangePickerInput {
    border: none;
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.white};
    .DateInput_input__focused {
        border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
    }
    .DateRangePickerInput_arrow {
        margin: 0 1em;
        .DateRangePickerInput_arrow_svg {
            fill: ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
        }
    }
}
    .DateInput {
        background: ${colors.lightGray};
        .DateInput_input {
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .DateRangePickerInput_clearDates_default {
        margin: 0;
        right: 0;
        &:hover {
            background: transparent;
            svg {
                fill: ${colors.red};
            }
        }
        svg {
            fill: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .CalendarDay__hovered_span,
    .CalendarDay__selected_span,
    .CalendarDay__selected {
        background: ${colors.blue};
        border: 1px double ${colors.blue};
        color: white;
    }
`;

const TimeAnalysisContainer = ({
    analyticsWidgetsLoading,
    allTimeLogsLoading,
    timeLogs,
    filters,
    selectedMonth,
    selectedYear,
    handleDatesChange,
    handleMonthChange,
    handleYearChange,
    spyValues
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const [focusedInput, setFocusedInput] = useState(null);
    const [info, setInfo] = useState(null);
    const [infoIsOpen, setInfoIsOpen] = useState(false);


    const CustomLegend = ({ startDate, endDate }) => {
        const start = startDate ? moment(startDate).format('MM-DD-YYYY') : '';
        const end = endDate ? moment(endDate).format('MM-DD-YYYY') : '';
        return (
            <div className='flex items-center justify-end'>
                <p
                    style={{ color: theme === 'dark' ? colors.secondary : colors.secondary }}
                > {start} / {end}</p>
            </div>
        );
    };

    const CustomTooltip = ({ payload }) => {
        const { clockIn, totalTimeFormatted } = payload?.[0]?.payload || {};
        if (payload && payload.length) {
            return (
                <div style={{ backgroundColor: colors.backgroundSecondary, border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <div style={{ margin: 0, color: colors.white }} >
                        <strong className='mr-md'>
                            {text?.analytics?.date}
                        </strong>
                        {clockIn}
                    </div>
                    <div style={{ margin: 0, color: colors.white }}>
                        <strong className='mr-md'>
                            {text?.analytics?.totalTime}
                        </strong>
                        {totalTimeFormatted}
                    </div>
                </div>
            );
        }

        return null;
    };


    const getTitleFromMilliseconds = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let hoursText = hours === 1 ? text?.analytics?.hour : text?.analytics?.hours;
        let minutesText = remainingMinutes === 1 ? text?.analytics?.minute : text?.analytics?.minutes;

        if (hours > 0) {
            if (remainingMinutes > 0) {
                // Both hours and minutes
                return `${hours} ${hoursText} ${text?.analytics?.and} ${remainingMinutes} ${minutesText}`;
            } else {
                // Only whole hours
                return `${hours} ${hoursText}`;
            }
        } else {
            // Only minutes
            return `${minutes} ${minutesText}`;
        }
    }

    const processTimeLogs = (timeLogs, startDate, endDate) => {
        // Convert UTC date to local date
        const adjustToLocalTime = (dateString) => {
            const date = new Date(dateString);
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString().split('T')[0];
        };

        // Filter logs based on the selected date range
        const filteredLogs = timeLogs.filter(log => {
            const logDate = moment(adjustToLocalTime(log.clockIn)); // Adjust clockIn to local date
            return logDate.isBetween(startDate, endDate, 'day', '[]'); // Inclusive of start and end dates
        });

        // Group by date and then by projectId within each date
        const groupedData = filteredLogs.reduce((acc, log) => {
            const date = adjustToLocalTime(log.clockIn); // Group by local date
            const projectId = log.projectId?.id;

            if (!acc[date]) {
                acc[date] = {
                    clockIn: date,
                    totalTime: 0,
                    totalTimeFormatted: '',
                    projects: {}
                };
            }

            if (!acc[date].projects[projectId]) {
                acc[date].projects[projectId] = {
                    projectId,
                    projectName: log.projectId?.projectName || 'Unknown Project',
                    users: []
                };
            }

            // Find existing entry for this user in the project, if it exists
            let userEntry = acc[date].projects[projectId].users.find(user => user.userId === log.userId?.id);
            if (!userEntry) {
                userEntry = {
                    userId: log.userId?.id,
                    userName: `${log.userId?.firstName} ${log.userId?.lastName}`,
                    totalTime: 0,
                    totalTimeFormatted: ''
                };
                acc[date].projects[projectId].users.push(userEntry);
            }

            userEntry.totalTime += log.totalTime;
            userEntry.totalTimeFormatted = getTitleFromMilliseconds(userEntry.totalTime);

            acc[date].totalTime += log.totalTime;
            acc[date].totalTimeFormatted = getTitleFromMilliseconds(acc[date].totalTime);

            return acc;
        }, {});

        // Prepare final data structure
        const data = Object.values(groupedData).map(log => ({
            clockIn: moment(log.clockIn).format('MM-DD-YYYY'),
            totalTime: (log.totalTime / (1000 * 60 * 60)).toFixed(2), // Convert milliseconds to hours and round to 2 decimals
            totalTimeFormatted: log.totalTimeFormatted, // Formatted total time for the day
            projects: Object.values(log.projects).map(project => ({
                projectId: project.projectId,
                projectName: project.projectName,
                users: project.users.map(user => ({
                    userId: user.userId,
                    userName: user.userName,
                    totalTime: (user.totalTime / (1000 * 60 * 60)).toFixed(2), // Convert milliseconds to hours and round to 2 decimals
                    totalTimeFormatted: user.totalTimeFormatted // Formatted total time for the user
                }))
            }))
        })).reverse();

        return data;
    };

    const handleLineClick = (data) => {
        const payload = data.activePayload?.[0]?.payload;
        setInfo(payload);
    }

    const processedData = processTimeLogs(timeLogs, filters.startDate, filters.endDate);
    const maxNumber = Math.max(...processedData.map(item => item.totalTime));

    return (
        <Wrapper
            title={text?.homeLandingContainer?.services?.timeTracker?.title}
            isLoading={analyticsWidgetsLoading && allTimeLogsLoading}
        >
            <div className="flex mb-md">
                <SelectInput
                    className='mr-md'
                    name="selectMonth"
                    label={text?.analytics?.selectMonth}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    isClearable={false}
                    isSearchable={false}
                    options={moment.months().map(month => ({ value: month, label: month }))}
                />
                <SelectInput
                    name="selectYear"
                    label={text?.analytics?.selectYear}
                    value={selectedYear}
                    onChange={handleYearChange}
                    isClearable={false}
                    isSearchable={false}
                    options={[2023, 2024].map(year => ({ value: year.toString(), label: year.toString() }))}
                />
            </div>

            <StyledDateRangePicker theme={theme} className="flex mb-md">
                <div className="flex flex-column">
                    <label className="mb-md flex b">
                        {text?.analytics?.dateRange}
                    </label>
                    <Field name="dateRange">
                        {({ input }) => (
                            <DateRangePicker
                                startDate={input.value.startDate || filters.startDate}
                                startDateId="start_date_id"
                                endDate={input.value.endDate || filters.endDate}
                                endDateId="end_date_id"
                                onDatesChange={({ startDate, endDate }) => {
                                    input.onChange({ startDate, endDate });
                                    handleDatesChange({ startDate, endDate });
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                isOutsideRange={() => false}
                                displayFormat={'MM-DD-YYYY'}
                                readOnly
                                numberOfMonths={2}
                                hideKeyboardShortcutsPanel
                                startDatePlaceholderText={text?.tasks?.home?.filters?.startDate}
                                endDatePlaceholderText={text?.tasks?.home?.filters?.endDate}
                            />
                        )}
                    </Field>

                </div>
            </StyledDateRangePicker>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    onClick={handleLineClick}

                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    data={processedData}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="clockIn"
                        tick={{ fill: theme === 'dark' ? colors.white : colors.black }} // Change label color here

                    />
                    <YAxis
                        domain={[0, maxNumber]}
                        label={{ value: text?.analytics?.hours, angle: -90, position: 'insideLeft', offset: -5 }}
                        tick={{ fill: theme === 'dark' ? colors.white : colors.black }} // Change label color here


                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        content={<CustomLegend startDate={filters.startDate} endDate={filters.endDate} />}
                    />
                    <ReferenceLine y={0} stroke={theme === 'dark' ? colors.white : colors.black} />
                    <Brush dataKey={'clockIn'} height={20} stroke={theme === 'dark' ? colors.secondary : colors.black} />

                    <Line type="monotone" dataKey="totalTime" connectNulls stroke={theme === 'dark' ? colors.secondary : colors.secondary} />
                </ComposedChart>
            </ResponsiveContainer>

            <ConfirmModal
                isOpen={!isEmpty(info)}
                toggle={() => {
                    setInfoIsOpen(!infoIsOpen);
                    setInfo([])
                }}
                width={'80%'}
                height={'80%'}
            >
                <div className='w-100'>
                    <FieldWrapper
                        noHr
                        name={text?.analytics?.project}
                        label={spyValues?.selectProject?.label}
                        className='mb-md'
                    />
                    <FieldWrapper
                        noHr
                        name={text?.analytics?.employee}
                        label={spyValues?.selectEmployee?.label}
                        className='mb-md'
                    />
                    <FieldWrapper
                        noHr
                        name={text?.analytics?.date}
                        label={info?.clockIn}
                        className='mb-md'
                    />
                    <FieldWrapper
                        name={text?.analytics?.totalTime}
                        label={info?.totalTimeFormatted}
                        className='mb-md'
                    />
                    {
                        info?.projects?.map(project => {
                            return (
                                <div key={project.projectId}>
                                    <ConditionalRender renderIf={spyValues?.selectProject?.value !== project.projectId}>
                                        <FieldWrapper
                                            noHr
                                            name={text?.analytics?.project}
                                            label={project.projectName}
                                            className='mb-md'
                                        />
                                    </ConditionalRender>
                                    {
                                        project.users.map(user => {
                                            return (
                                                <ConditionalRender renderIf={spyValues?.selectEmployee?.value !== user.userId}>
                                                    <div key={user.userId} style={{
                                                        background: colors.lightGray,
                                                        padding: '1em',
                                                        marginBottom: '1em'
                                                    }}>
                                                        <FieldWrapper
                                                            noHr
                                                            name={text?.analytics?.employee}
                                                            label={user.userName}
                                                            className='mb-md'
                                                        />
                                                        <FieldWrapper
                                                            noHr
                                                            name={text?.analytics?.totalTime}
                                                            label={user.totalTimeFormatted}
                                                            className='mb-md'
                                                        />
                                                    </div>
                                                </ConditionalRender>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </ConfirmModal>
        </Wrapper>
    );
}

export default TimeAnalysisContainer;
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { deleteAnnouncement } from '../utils/calls';
import ConditionalRender from '../Core/ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Core/Loader';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import useScreenSize from '../context/useScreenSize';

const StyledDiv = styled.div`

margin: 0 auto;

.announcement__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        background: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.white};
        padding: 15px;
        margin-bottom: 15px;
        .date {
            margin-left: 15px;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
        .title {
            font-weight: bold;
            font-size: 1.5rem;
            font-family: ${colors.openSans};
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
        .body {
            font-size: 1.1rem;
            font-family: ${colors.alegreya};
            text-align: left;
            line-height: 1.2;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
`;

const AnnouncementsContainer = ({
    user,
    announcements,
    announcementsIsLoading,
    fetchAnnouncements
}) => {
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();


    const handleDeleteAnnouncement = async (id) => {
        try {
            const res = await deleteAnnouncement(id);
            if (res.status === 200) {
                fetchAnnouncements()
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            {
                announcementsIsLoading ? <Loader count={announcements.length + 1} customHeight={'200px'} /> :
                    (
                        <StyledDiv
                            theme={theme}
                            className={isDesktop ? 'desktop' : isTablet ? 'tablet scroll-container h-100' : 'phone scroll-container h-100'}

                        >
                            {
                                announcements.length === 0 && (
                                    <p>
                                        {'No Announcements'}
                                    </p>
                                )
                            }
                            {
                                user?.isSuperAdmin && (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        marginBottom: '15px'
                                    }}>
                                        <Link to={'/announcements/create'}>
                                            {'Create'}
                                        </Link>
                                    </div>
                                )
                            }
                            {
                                announcements?.map((announcement) => {
                                    return (
                                        <div className='announcement__wrapper'>
                                            <ConditionalRender renderIf={user?.isSuperAdmin}>
                                                <FontAwesomeIcon
                                                    onClick={() => handleDeleteAnnouncement(announcement?.id)}
                                                    icon={faClose} style={{
                                                        color: colors.red,
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0'
                                                    }}
                                                />
                                            </ConditionalRender>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '15px',
                                                justifyContent: 'space-between'
                                            }}>
                                                <div className='title'>
                                                    {announcement?.title}
                                                </div>
                                                <div className='date'> {moment(announcement?.createdAt).format('MM-DD-YYYY')} </div>
                                            </div>
                                            <div className='body'> {announcement?.body} </div>
                                        </div>
                                    )
                                })
                            }
                        </StyledDiv>
                    )
            }
        </>
    )


}

export default AnnouncementsContainer
import request from '../request';

/**
 * Sends a ids request to the API.
 * @param {Object} productIds - The ids data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const removeProposals = async (ids) => {
    try {
        const { data, status } = await request('POST', '/proposal/remove', { ids });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to delete proposals: ${error.message}`);
    }
};

export default removeProposals;

import request from '../request';

/**
 * search products inventory from the API.
 * @param {string} query - The search query for inventoryName.
 * @param {boolean} isPriority - Flag to filter by isPriority.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const searchProducts = async ({ query, isPriority, userId, projectId }) => {
    try {
        const { data, status } = await request('POST', '/inventory/search-products', { query, isPriority, userId, projectId });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get products: ${error.message}`);
    }
};

export default searchProducts;

import React from 'react';
import { findMaxNumber, sortByKeyWithHighestValue, findMaxKey } from './utils/helperFunctions';
import CustomToolTip from '../Dashboard/Charts/CustomToolTip';
import { renderLabel } from '../Dashboard/Charts/helperFunctions';
import Filters from './Filters';
import generatePrimaryColors from '../utils/generatePrimaryColors';
import { useTheme } from '../context/ThemeContext';
import { isEmpty } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import colors from '../globalStyles.scss';

import {
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ReferenceLine,
    Line,
    Brush
} from 'recharts';
const LineChartContainer = ({
    data,
    inventoryColumns,
    form,
    setFormattedData,
    formattedData,
    handleCombineChange,
    setCombineKey,
    combineKey,
    stringData,
    numberData,
    setDataCheckedKeys,
    dataCheckedKeys,
    handleClear,
    setInfo,
    initialGroupBy,
    setInitialGroupBy,
    setChartIsEditing
}) => {
    const { theme } = useTheme();
    const colorsArray = generatePrimaryColors(dataCheckedKeys?.length)
    let maxNumber = findMaxNumber(formattedData, dataCheckedKeys);

    const handleLineClick = (data, index) => {
        const selectedValue = data?.activeLabel

        // Filter the formattedData based on the selected value
        const filteredData = numberData.filter(item => item[combineKey] === selectedValue);

        // Set the filtered data as the new Info state
        setInfo(filteredData);
    };

    const parseDataForChart = (formattedData) => {
        return formattedData.map(item => {
            const chartItem = {
                inventoryName: item.inventoryName,
                inventoryCategory: item.inventoryCategory,
            };

            // Dynamically add all keys from the main object except for specific exclusions
            for (const key in item) {
                if (key !== 'inventoryName' && key !== 'inventoryCategory' && key !== 'advanceFields') {
                    if (item[key]?.value !== undefined) {
                        chartItem[key] = item[key].value;
                    } else {
                        chartItem[key] = item[key];
                    }
                }
            }

            // Add keys from advanceFields if they exist
            if (item.advanceFields) {
                for (const key in item.advanceFields) {
                    if (item.advanceFields[key]?.value !== undefined) {
                        chartItem[key] = Number(item.advanceFields[key].value);
                    } else {
                        chartItem[key] = item.advanceFields[key];
                    }
                }
            }

            return chartItem;
        });
    };


    const renderLegend = ({ payload }) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontFamily: colors.openSans,
                marginTop: '5px'
            }}>
                <div style={{
                    fontWeight: 'bold',
                    alignSelf: 'end',
                    display: 'flex',
                    justifyContent: 'end',
                    flex: '1'
                }}>
                    {
                        payload?.map((entry, index) => {
                            return (
                                <span style={{
                                    color: entry.color,
                                    padding: '0 5px'
                                }} key={`item-${index}`}>{renderLabel(inventoryColumns, entry)}</span>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    const parsedData = parseDataForChart(formattedData, dataCheckedKeys);

    return (
        <div className='flex mt-md'>
            <Filters
                stringData={stringData}
                numberData={numberData}
                inventoryColumns={inventoryColumns}
                formattedData={formattedData}
                setFormattedData={setFormattedData}
                handleClear={() => handleClear(form)}
                setCombineKey={setCombineKey}
                combineKey={combineKey}
                setDataCheckedKeys={setDataCheckedKeys}
                dataCheckedKeys={dataCheckedKeys}
                handleCombineChange={handleCombineChange}
                initialGroupBy={initialGroupBy}
                setInitialGroupBy={setInitialGroupBy}
                setChartIsEditing={setChartIsEditing}
            />

            <ConditionalRender renderIf={!isEmpty(dataCheckedKeys)} >

                <ResponsiveContainer width={'100%'} height={400}>
                    <LineChart
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        layout={'vertical'}
                        onClick={combineKey && handleLineClick}
                        data={sortByKeyWithHighestValue(dataCheckedKeys, parsedData)}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" domain={[0, maxNumber]} />
                        <YAxis dataKey={combineKey || 'inventoryName'} type="category" />
                        <Tooltip
                            content={({ payload }) => (
                                <CustomToolTip
                                    payload={payload}
                                    combineKey={combineKey}
                                    numberData={numberData}
                                    inventoryColumns={inventoryColumns}
                                    dataCheckedKeys={dataCheckedKeys}
                                />
                            )}
                        />
                        <Legend content={renderLegend} />
                        <ReferenceLine y={0} stroke={theme === 'dark' ? colors.white : colors.black} />
                        <Brush
                            dataKey={findMaxKey(dataCheckedKeys, formattedData)}
                            height={20}
                            stroke={theme === 'dark' ? colors.secondary : colors.black}
                        />

                        {dataCheckedKeys?.map((key, index) => {
                            const color = colorsArray[index % colorsArray?.length];

                            if (data.some(d => key in d)) {
                                return <Line
                                    key={key}
                                    dataKey={key}
                                    stroke={color}
                                    // onClick={combineKey && handleBarClick}
                                    cursor={combineKey && "pointer"}
                                >
                                </Line>
                            } else {
                                return <Line
                                    key={`${key}`}
                                    dataKey={`${key}`}
                                    stroke={color}
                                    // onClick={combineKey && handleBarClick}
                                    cursor={combineKey && "pointer"}
                                >
                                </Line>
                            }
                        })}
                    </LineChart>

                </ResponsiveContainer>
            </ConditionalRender>
        </div>
    )
}

export default LineChartContainer

import React, { useState, useEffect } from 'react'
import CallToAction from '../Core/CallToAction';
import ConditionalRender from '../Core/ConditionalRender';
import CreateNewEmployeeContainer from './CreateNewEmployeeContainer';
import HoverPopup from '../Core/HoverPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'react-final-form';
import PaginatedData from '../Core/PaginatedData';
import Checkbox from '../Core/Inputs/CheckBox';
import Wrapper from '../Core/Wrapper';
import colors from '../globalStyles.scss';
import SelectableBox from '../Core/SelectableBox';
import CustomTable from '../Core/Tables/CustomTable';
import { useTheme } from '../context/ThemeContext'
import { employeesColumns, assignedEmployeesColumns } from '../Invetory/columns';
import ConfirmModal from '../Core/ConfirmModal';
import { useNavigate } from 'react-router';
import SubmitModal from '../Core/SubmitModal';
import styled from 'styled-components';
import SearchInput from '../Core/Inputs/SearchInput';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import {
    createUser,
    setRole,
    removeEmployees,
    updateAssignedEmployees,
    updateUpdatedDate
} from '../utils/calls'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';


const StyledDiv = styled.div`
.EmployeesContainer {
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        a, span {
            color: ${colors.white};
        }
    }
}
`

const EmployeesContainer = ({
    employees,
    filteredEmployees,
    user,
    company,
    fetchCompanyEmployees,
    isProject,
    project,
    title = null,
    showLightColors,
    employeesIsLoading,
    btnWidth = 'auto',


    checkboxSelection = true,
    isDetailsProject,
    EmployeeIsAdmin,
    projectIsComplete,
    openEmployeeModal,
    userRole,
    employeeModalIsOpen,
    id,
    handleGetProject,
    projects,
    isDashboard,
    setExpanded
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet } = useScreenSize();
    const { notify } = useToast();
    const navigate = useNavigate();


    const [addEmployee, setAddEmployee] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [employeesList, setEmployeesList] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const assignedIds = new Set(project?.assignedEmployees.map(emp => emp.id));
        const formattedEmployees = isDetailsProject ? filteredEmployees : employees;

        const sortedEmployees = formattedEmployees && [...formattedEmployees].sort((a, b) => {
            if (assignedIds.has(a.id) && !assignedIds.has(b.id)) {
                return -1;
            }
            if (!assignedIds.has(a.id) && assignedIds.has(b.id)) {
                return 1;
            }
            return 0;
        });

        if (sortedEmployees?.length === 0) {
            setTableData([]);
        } else {
            setTableData(sortedEmployees);
        }
    }, [employees, project, employeesIsLoading]);



    const handleAddEmployee = () => {
        setAddEmployee(!addEmployee)
    };
    const [roleType, setRoleType] = useState('Employee');
    const [error, setError] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const assignedIds = new Set(project?.assignedEmployees.map(emp => emp.id));
        const formattedEmployees = isDetailsProject ? filteredEmployees : employees;


        const sortedEmployees = formattedEmployees && [...formattedEmployees].sort((a, b) => {
            if (assignedIds.has(a.id) && !assignedIds.has(b.id)) {
                return -1;
            }
            if (!assignedIds.has(a.id) && assignedIds.has(b.id)) {
                return 1;
            }
            return 0;
        });

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            const filteredEmployees = sortedEmployees.filter((employee) => {
                const fullName = `${employee?.firstName} ${employee?.lastName}`.toLowerCase();
                return fullName.includes(query);
            });
            setEmployeesList(filteredEmployees);
            setTableData(filteredEmployees);
        } else {
            setEmployeesList(sortedEmployees);
            setTableData(sortedEmployees);
        }
    }, [searchQuery, employees, project, employeesIsLoading]);

    const createNewEmployee = async (values) => {
        values.companyId = company?.id
        values.companyName = company?.companyName
        values.companyIndustry = {
            value: company?.companyIndustry
        }
        const permissions = ['read', 'write']

        setRole({
            companyId: company?.id,
            roleName: roleType,
            permissions
        })
            .then((userRes) => {
                const roleId = userRes?.data?.id
                const roleName = userRes?.data?.roleName
                const companyId = userRes?.data?.companyId
                return createUser({
                    ...values,
                    roleId,
                    roleName,
                    companyId
                })
            }).then((res) => {
                if (res.status === 200) {
                    fetchCompanyEmployees(company?.id)
                    setAddEmployee(false)
                }
            })

            .catch((error) => {
                console.error(error);
                setError(error);
            });
    };

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const [toggleModal, setToggleModal] = useState(false);

    const handleDeleteSelection = async () => {
        try {
            const res = await removeEmployees(selectedIds);
            if (res.status === 200) {
                setToggleModal(!toggleModal)
                fetchCompanyEmployees(company?.id)
                setSelectedIds([])
                setSelectedItems([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleEmployeeSubmit = async (values, e) => {
        try {
            openEmployeeModal()
            const res = await updateAssignedEmployees({ projectId: id, assignedEmployees: values.employee });
            if (res.status === 200) {
                handleGetProject()
                updateUpdatedDate(id)
                notify(text?.notificationsUI?.project?.updated, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    return (
        <StyledDiv>
            <Wrapper
                showLightColors={showLightColors}
                title={title}
                btnWidth={btnWidth}
                isFlex={isProject ? false : true}
                setExpanded={setExpanded}
                section='employees'
                expandable
                button={
                    <>
                        <ConditionalRender renderIf={!isDetailsProject}>
                            <CallToAction
                                text={text?.employees?.landing?.createNew}
                                type='button'
                                onClick={() => handleAddEmployee()}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={isDetailsProject && !projectIsComplete && !EmployeeIsAdmin}>
                            <CallToAction
                                text={text?.projects?.details?.employeebutton}
                                type='button'
                                onClick={() => openEmployeeModal()}
                            />
                        </ConditionalRender>
                    </>
                }
            >
                <div
                    className='EmployeesContainer mb-md'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                    <div className='flex flex-column'>
                        <HoverPopup
                            style={{
                                marginRight: '1rem',
                                alignSelf: 'flex-start'
                            }}
                            onClick={() => setShowFilters(!showFilters)}
                            placement={'top'}
                            id='toggle-filters-popup-employee'
                            text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                        </HoverPopup>

                        <ConditionalRender renderIf={showFilters}>
                            <div className='flex flex-column filters mt-md'>
                                <SearchInput
                                    width={((isDesktop && isDetailsProject) || (isDesktop && isDashboard)) ? '50%' : ((isTablet && isDetailsProject) || (isTablet && isDashboard)) ? '50%' : '100%'}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeHolder={text?.employees?.landing?.search}
                                />

                            </div>
                        </ConditionalRender>
                    </div>
                    <ConditionalRender renderIf={isProject}>
                        <div className='flex flex-column align-start mt-md'>
                            <PaginatedData
                                className='mt-md'
                                data={tableData?.filter((x) => x?._id !== user?._id)}
                                dataIsLoading={employeesIsLoading}
                                noDataText={text?.employees?.landing?.noData}
                                searching={searchQuery}
                                showLightColors
                                ItemComponent={({ item }) => {
                                    const isChecked = project?.assignedEmployees?.some(employee => item.id === employee.id);
                                    const isAdminChecked = project?.adminEmployees?.some(employee => item.id === employee.id);
                                    return (
                                        <Field
                                            name={`employee.${item.id}`}
                                            type="checkbox"
                                            component="input"
                                            initialValue={isChecked}
                                        >
                                            {({ input, meta }) => (
                                                <SelectableBox
                                                    key={item?._id}
                                                    className='flex justify-space-between'
                                                    showLightColors={showLightColors}
                                                >
                                                    <Checkbox
                                                        id={`employee_${item.id}`}
                                                        disabledPopUp
                                                        checked={input.checked}
                                                        label={`${item?.firstName} ${item?.lastName}`}
                                                        showLightColors={showLightColors}
                                                        onChange={(e) => {
                                                            input.onChange(e);
                                                        }}
                                                    />
                                                    {input.checked && (
                                                        <Field
                                                            name={`employee.isAdmin.${item.id}`}
                                                            type="checkbox"
                                                            component="input"
                                                            initialValue={isAdminChecked}
                                                        >
                                                            {({ input: adminInput, meta: adminMeta }) => (
                                                                <Checkbox
                                                                    id={`admin_${item.id}`}
                                                                    disabledPopUp
                                                                    checked={adminInput.checked}
                                                                    showLightColors={showLightColors}
                                                                    className={'ml-md'}
                                                                    label={text?.employees?.details?.admin}
                                                                    onChange={(e) => {
                                                                        adminInput.onChange(e);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    )}
                                                </SelectableBox>
                                            )}
                                        </Field>
                                    )
                                }}
                            />
                        </div>
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isProject}>
                        <div className='flex flex-column align-start mt-md'>
                            <CustomTable
                                keyField="_id"
                                columns={isDetailsProject ? assignedEmployeesColumns({ theme, text, userRole, project, navigate }) : employeesColumns({ theme, text, navigate })}
                                rows={tableData?.filter((x) => x?._id !== user?._id)}
                                theme={theme}
                                noDataText={text?.employees?.landing?.noData}
                                onRowSelectionChange={handleRowSelectionChange}
                                checkboxSelection={checkboxSelection}
                            />
                            <ConditionalRender renderIf={!isProject && !isEmpty(selectedIds)}>
                                <div style={{
                                    color: colors.red,
                                    fontFamily: colors.openSans,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    marginBottom: '5px'
                                }} onClick={() => setToggleModal(true)} >
                                    {text?.employees?.landing?.deleteSelected}
                                </div>

                                <ConfirmModal
                                    onClick={handleDeleteSelection}
                                    text={text?.inventory?.landing?.table?.modal?.button}
                                    toggle={() => setToggleModal(!toggleModal)}
                                    isOpen={toggleModal}

                                    btnStyles={{
                                        color: colors.red
                                    }}

                                >
                                    <div>
                                        <h4 style={{ marginBottom: '15px' }}>
                                            {text?.inventory?.landing?.table?.modal?.title}
                                        </h4>
                                        <ul>
                                            {selectedItems?.map((x) => {
                                                return (
                                                    <li style={{ fontSize: '0.9rem' }}>
                                                        - {`${x?.firstName} ${x?.lastName}`}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </ConfirmModal>
                            </ConditionalRender>

                        </div>
                    </ConditionalRender>

                    <ConditionalRender renderIf={!isEmpty(error)}>
                        <span className='error'>
                            {error}
                        </span>
                    </ConditionalRender>

                    {/* Modals */}
                    <ConditionalRender renderIf={addEmployee}>
                        <CreateNewEmployeeContainer
                            isOpen={addEmployee}
                            onClick={createNewEmployee}
                            toggle={() => setAddEmployee(false)}
                            setRoleType={setRoleType}
                            roleType={roleType}
                            employees={employees}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={isDetailsProject && employeeModalIsOpen}>
                        <SubmitModal
                            onClick={(values, e) => handleEmployeeSubmit(values, e)}
                            text={text?.projects?.details?.assignModal?.button}
                            toggle={openEmployeeModal}
                            isOpen={employeeModalIsOpen}
                            width={isDesktop ? '80%' : '100%'}
                            height={isDesktop ? '80%' : '100%'}
                            btnStyles={{
                                color: colors.blue
                            }}
                        >
                            <div className='w-100'>
                                <h4 className='flex justify-center'>
                                    {text?.projects?.details?.assignModal?.title}
                                </h4>
                                <p className='flex justify-center mb-md'>
                                    {text?.projects?.details?.assignModal?.text}
                                </p>
                                <div className='w-100'>
                                    <EmployeesContainer
                                        employees={employees}
                                        userRole={userRole}
                                        user={user}
                                        projects={projects}
                                        company={company}
                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                        isProject
                                        project={project}
                                        showLightColors
                                        employeesIsLoading={employeesIsLoading}
                                        btnWidth='100%'
                                    />
                                </div>
                            </div>

                        </SubmitModal>
                    </ConditionalRender>
                </div>
            </Wrapper>
        </StyledDiv>
    )
}

export default EmployeesContainer
import React, { useState, useEffect } from 'react'
import ConditionalRender from '../Core/ConditionalRender'
import { grantAccess } from '../utils/calls'
import CallToAction from '../Core/CallToAction'
import colors from '../globalStyles.scss'

const RenderUser = ({
    company
}) => {
    const [currentCompany, setCurrentCompany] = useState(company);

    // Use useEffect to sync currentCompany with company prop changes
    useEffect(() => {
        setCurrentCompany(company);
    }, [company]);

    const handleGrantAccess = async () => {
        try {
            const response = await grantAccess({ companyId: currentCompany?._id });
            if (response.status === 200) {
                setCurrentCompany(response.data);
            }
        } catch (error) {
            console.error('Error granting access:', error)
        }
    }

    return (
        <div className='flex items-center'>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    marginBottom: '15px',
                    padding: '15px'
                }}>
                <p>
                    {'ID: '}{currentCompany?._id}
                </p>
                <p>
                    {'Company Name: '}{currentCompany?.companyName}
                </p>
            </div>
            <ConditionalRender renderIf={!currentCompany?.accessGranted}>
                <CallToAction
                    text={'Grant Access'}
                    onClick={handleGrantAccess}
                    style={{
                        alignSelf: 'start',
                        marginLeft: '15px'
                    }}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={currentCompany?.accessGranted}>
                <span style={{
                    color: colors.green,
                    marginLeft: '15px'
                }}>
                    {'access granted'}
                </span>
            </ConditionalRender>
        </div>
    )
}

export default RenderUser
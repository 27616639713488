import React, { createContext, useContext, useState, useEffect } from 'react';
import { GlobalContext } from './GlobalContext';
import { updateUser } from '../utils/calls';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const { user } = useContext(GlobalContext);
    const [isDarkMode, setIsDarkMode] = useState(user?.isDarkMode || false);
    const [theme, setTheme] = useState(user?.isDarkMode ? 'dark' : 'light');


    useEffect(() => {
        setIsDarkMode(user?.isDarkMode || false);
        setTheme(user?.isDarkMode ? 'dark' : 'light');
    }, [user?.isDarkMode]);

    const toggleTheme = async () => {
        const newTheme = !isDarkMode;
        try {
            await updateUser({ isDarkMode: newTheme, email: user?.email });
            setIsDarkMode(newTheme);
            setTheme(newTheme ? 'dark' : 'light');
        } catch (error) {
            console.error('Failed to update theme:', error);
        }
    };

    return (
        <ThemeContext.Provider value={{ theme, isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

import React from 'react';
import { Field } from 'react-final-form';
import { NumericFormat } from 'react-number-format';
import FieldWithNoValidation from '../FieldWithNoValidation';
import { useTheme } from '../../context/ThemeContext';
import styled from 'styled-components';

const StyledDiv = styled.div``;

const AmountField = ({
    input,
    meta,
    name,
    label,
    setIsEditing,
    validate,
    ...rest
}) => {

    const handlePriceChange = ({ floatValue }) => {
        input.onChange(floatValue);
        if (setIsEditing) {
            if (input.value !== floatValue) {
                setIsEditing(true);
            }
        }
    };

    const getCurrencyPrefix = () => {
        return '$';
    };

    return (
        <div>
            <NumericFormat
                onValueChange={handlePriceChange}
                thousandSeparator={true}
                prefix={getCurrencyPrefix(input.value)}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                isNumericString={true}
                customInput={FieldWithNoValidation}
                value={input.value}
                placeholder={'$0.00'}
                validate={validate}
                {...rest}
            />
            {meta.touched && meta.error && <span>{meta.error}</span>}
        </div>
    );
};

export const AmountInput = ({
    name,
    label,
    showLightColors,
    validate,
    className,
    ...rest
}) => {
    const { theme } = useTheme();

    return (
        <StyledDiv
            showLightColors={showLightColors}
            theme={theme}
            className={className}
        >
            <label className='custom-label b'>{label}</label>
            <div className='custom-field'>
                <Field
                    name={name}
                    component={AmountField}
                    showLightColors={showLightColors}
                    validate={validate}
                    {...rest}
                />
            </div>
        </StyledDiv>
    );
};

export default AmountInput;

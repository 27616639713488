import React, { useState, useEffect, useCallback, memo } from 'react';
import CustomTable from '../Core/Tables/CustomTable';
import { columns } from './columns';
import { useNavigate } from 'react-router';
import InventoryQuickView from '../QuickView/InventoryQuickView';
import * as XLSX from 'xlsx';
import { renderLabel, getNestedValue } from '../Dashboard/Charts/helperFunctions';
import { saveAs } from 'file-saver';
import moment from 'moment';
import CallToAction from '../Core/CallToAction';
import {
    priorityProduct,
    searchProducts,
    activateColumns,
    deleteProducts
} from '../utils/calls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faFilter } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import HoverPopup from '../Core/HoverPopup';
import SearchInput from '../Core/Inputs/SearchInput';
import ColumnDropdown from './ColumnDropdown';
import ConfirmModal from '../Core/ConfirmModal';
import SelectInput from '../Core/SelectInput';
import { Form } from 'react-final-form';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';


import './styles.scss'
import classnames from 'classnames';

const ProjectForm = memo(({
    projects,
    handleSelectInputChange
}) => {
    const onSubmit = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);
    const { isDesktop, isTablet } = useScreenSize();

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={(e) => {
                    handleSubmit();
                }}>
                    <SelectInput
                        isSearchable={true}
                        isClearable={true}
                        isValidNewOption={() => false}
                        name="selectProject"
                        onChange={(event) => {
                            handleSelectInputChange(event);
                        }}
                        options={projects?.filter((x) => !x?.isCompleted && x?.features?.inventory)?.map((x) => ({
                            label: x?.projectName,
                            value: x?._id
                        }))}
                        noFormat
                        style={{
                            width: '100%',
                        }}

                    />
                </form>
            )}
        />
    );
});

const InventoryItems = ({
    inventory,
    fetchInventoryProducts,
    inventoryColumns,
    setInventoryColumns,
    fetchAttributes,
    user,
    adminPage,
    isProject,
    projectId,
    projects,
    noDataText,
    projectIsComplete,
    showLightColors = false,
    noOverlay = false
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { notify } = useToast();
    const navigate = useNavigate();

    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({});
    const [toggleModal, setToggleModal] = useState(false)

    const [showFilters, setShowFilters] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const isSuperAdmin = adminPage

    const [quickViewData, setQuickViewData] = useState(null);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);


    const handleQuickView = (rowData) => {
        setQuickViewData(rowData);
        setIsQuickViewOpen(!isQuickViewOpen);
    };

    useEffect(() => {
        if (inventory.length === 0) {
            setTableData([]);
        } else {
            setTableData(inventory);
        }
    }, [inventory]);

    const handleColumnToggle = async (selectedColumns) => {
        try {
            const res = await activateColumns(selectedColumns);
            if (res.status === 200) {
                fetchAttributes();
                !isSuperAdmin && fetchInventoryProducts(projectId || null)
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error');
            console.error('Error:', error);
        }
    };

    const handlePriorityChange = async ({ productId, isPriority }) => {
        try {
            // Calculate the updated priority value
            const updatedPriority = !isPriority;

            // Send API request to update priority
            const res = await priorityProduct({ productId, isPriority: updatedPriority });

            if (res.status === 200) {
                // Update the table data
                setTableData((prevTableData) => {
                    const updatedTableData = prevTableData.map((product) => {
                        if (product.productId === productId) {
                            return { ...product, isPriority: updatedPriority };
                        }
                        return product;
                    });
                    notify(text?.notificationsUI?.inventory?.updatedPriority, 'success');
                    return updatedTableData;
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handlePriorityCheckboxChange = () => {
        setFilters({ ...filters, priority: !filters.priority });
    };

    const filterTableData = async (projectId) => {
        const filterOptions = {};

        if (filters.priority) {
            filterOptions.isPriority = filters.priority;
        }
        if (filters.query) {
            filterOptions.query = filters.query;
        }
        if (adminPage) {
            filterOptions.userId = user?.id
        }
        if (projectId || filters.projectId) {
            filterOptions.projectId = projectId || filters.projectId;
        }

        const res = await searchProducts(filterOptions);
        const products = res.data;
        setTableData(products);
    };

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e.target.value });
    };
    const handleSelectInputChange = (e) => {
        setFilters({ ...filters, projectId: e?.value });
    }

    useEffect(() => {
        filterTableData(projectId);
    }, [filters, projectId]);

    const isPriorityOn = inventoryColumns.find((x) => {
        if (x?.fieldName === 'isPriority') {
            return x?.isActive
        }
    });


    const handleDeleteSelection = async () => {
        try {
            const res = await deleteProducts(selectedIds);
            if (res.status === 200) {
                !isSuperAdmin && fetchInventoryProducts(projectId || null)
                setToggleModal(true)
                setSelectedIds([]);
                setSelectedItems([]);
                notify(text?.notificationsUI?.inventory?.deleted, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x.productId));
    };

    const exportToExcel = (data, fileName, columns) => {
        // Filter out unwanted fields and map field names to labels
        const filteredData = data.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                if (key === 'advanceFields') {
                    Object.entries(item.advanceFields).forEach(([fieldKey, value]) => {
                        const label = renderLabel(columns, fieldKey);
                        if (fieldKey.startsWith('number-')) {
                            newItem[label || fieldKey] = value?.value;
                        } else {
                            newItem[label || fieldKey] = value;
                        }
                    });
                } else if (!['images', '__v', 'id', '_id', 'updatedAt', 'createdAt', 'productId', 'isPriority', 'companyId'].includes(key)) {
                    const label = renderLabel(columns, key);
                    if (key === 'inventoryQuantity') {
                        newItem[label || key] = item.inventoryQuantity?.value || '0';
                    } else if (key === 'projectId') {
                        newItem['Project Name'] = item.projectId?.projectName || '';
                    } else {
                        newItem[label || key] = getNestedValue(item, key);
                    }
                }
            });
            return newItem;
        });

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Inventory');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${moment().format('MM-DD-YYYY')}-${fileName}.xlsx`);
    };


    return (
        <div className="InventoryItems">
            <ConditionalRender renderIf={true}>
                <div
                    className={classnames({
                        'flex': isDesktop,
                    })}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '15px',
                        width: '100%'
                    }}>
                    <div className='flex flex-column flex-one'>
                        <ConditionalRender renderIf={!projectIsComplete && !isEmpty(inventory)}>
                            <HoverPopup
                                className='align-self-start'
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'} id='toggle-filters-popup-inventory'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>
                        </ConditionalRender>
                        <ConditionalRender renderIf={showFilters}>
                            <div
                                className={classnames({
                                    'filters mt-md': true,
                                    'w-100': isPhone,
                                    'w-50': isTablet || isDesktop,
                                })}
                            >
                                <CallToAction
                                    text={'Export Inventory'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        exportToExcel(tableData, 'Inventory', inventoryColumns)
                                    }}
                                    style={{
                                        marginBottom: '1em'
                                    }}
                                />
                                <SearchInput
                                    className='mb-md'
                                    value={filters.query}
                                    onChange={handleSearchChange}
                                    placeHolder={text?.inventory?.landing?.table?.search}
                                    style={{
                                        background: colors.lightGray,
                                    }}
                                />
                                <ConditionalRender renderIf={!isProject}>
                                    <ProjectForm
                                        projects={projects}
                                        handleSelectInputChange={handleSelectInputChange}
                                        text={text}

                                    />
                                </ConditionalRender>
                                <ConditionalRender renderIf={isPriorityOn}>
                                    <div className={classnames({
                                        'mt-md flex': true,
                                        'align-self-end': isDesktop,
                                    })}
                                    >
                                        <HoverPopup
                                            onClick={handlePriorityCheckboxChange}
                                            placement={'top'}
                                            id='toggle-priority-popup'
                                            text={isDesktop && text?.inventory?.landing?.table?.priority}
                                        >
                                            <FontAwesomeIcon icon={faStar} style={{ color: filters.priority ? colors.yellow : colors.white }} />
                                        </HoverPopup>
                                    </div>
                                </ConditionalRender>
                            </div>
                        </ConditionalRender>
                    </div>

                    {/* Removed */}
                    <ConditionalRender renderIf={false}>
                        <ConditionalRender renderIf={!projectIsComplete && !isEmpty(inventory)}>
                            <div
                                className={classnames({
                                    'flex': isDesktop,
                                    'mt-md': !isDesktop
                                })}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    alignSelf: 'flex-end',
                                }}>
                                <ColumnDropdown
                                    handleColumnToggle={handleColumnToggle}
                                    columns={inventoryColumns}
                                    setInventoryColumns={setInventoryColumns}
                                    isProject={isProject}
                                />
                            </div>
                        </ConditionalRender>
                    </ConditionalRender>
                </div>


                <CustomTable
                    showLightColors={showLightColors}
                    keyField="productId"
                    columns={columns({
                        handlePriorityChange,
                        inventoryColumns,
                        theme,
                        text,
                        isProject,
                        navigate,
                        handleQuickView
                    })}
                    rows={
                        tableData
                            ?.filter(item => !isProject ? !item?.projectId?.isCompleted : item)
                    }
                    onPriorityChange={handlePriorityChange}
                    isProject={isProject}
                    theme={theme}
                    noDataText={noDataText}
                    onRowSelectionChange={handleRowSelectionChange}

                />
                <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                    <div style={{
                        color: colors.red,
                        fontFamily: colors.openSans,
                        cursor: 'pointer',
                        display: 'flex',
                        marginBottom: '5px'
                    }} onClick={() => setToggleModal(true)} >
                        {text?.inventory?.landing?.table?.deleteSelected}
                    </div>
                    <ConfirmModal
                        onClick={handleDeleteSelection}
                        text={text?.inventory?.landing?.table?.modal?.button}
                        toggle={() => setToggleModal(!toggleModal)}
                        isOpen={toggleModal}
                        btnStyles={{
                            color: colors.red
                        }}

                    >
                        <div>
                            <h4 style={{ marginBottom: '15px' }}>
                                {text?.inventory?.landing?.table?.modal?.title}
                            </h4>
                            <ul>
                                {selectedItems?.map((x) => {
                                    return (
                                        <li style={{ fontSize: '0.9rem' }}>
                                            - {x?.inventoryName}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </ConfirmModal>
                </ConditionalRender>

                {/* Quick View */}
                <InventoryQuickView
                    isQuickViewOpen={isQuickViewOpen}
                    setIsQuickViewOpen={setIsQuickViewOpen}
                    quickViewData={quickViewData}
                    noOverlay={noOverlay}
                    inventoryColumns={inventoryColumns}
                />
            </ConditionalRender>
        </div>
    );
};

export default InventoryItems;

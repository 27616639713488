import React from 'react';
import { Link } from 'react-router-dom';
import colors from '../globalStyles.scss'
import Loader from './Loader';

class ConditionalRender extends React.PureComponent {
    render() {
        const {
            renderIf,
            children,
            isRoute,
            url,
            isLoading = false,
            loadingCount = 1,
            customStyled = null
        } = this.props;

        if (isLoading) return <Loader {...this.props} customStyled={customStyled} count={loadingCount ? loadingCount : 1} />

        if (renderIf) {
            if (isRoute) {
                return (
                    <Link to={url}>
                        <span style={{ color: colors.blue }}>
                            {children}
                        </span>
                    </Link>
                )
            }
            return children;
        }

        return null;
    }
}

export default ConditionalRender;

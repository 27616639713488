import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export const OutsideClick = ({ children, onClick, style }) => {
    return (
        <div style={style}>
            <OutsideClickHandler
                onOutsideClick={onClick}
            >
                {children}
            </OutsideClickHandler>
        </div>
    )
}

export default OutsideClick
export const getPremiumProducts = (text) => {
    return [
        {
            type: 'month',
            label: text?.payment?.monthly,
            price: 35,
            productName: 'MonthlyPremium',
            value: '$35 USD',
        },
        {
            type: 'year',
            label: text?.payment?.yearly,
            price: 300,
            productName: 'YearPremium',
            value: '$300 USD',
        }
    ];
};
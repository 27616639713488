import React from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { status, proposalStatus } from '../utils/helperFunctions';
import RenderImages from '../Core/RenderImages';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../context/useScreenSize';
import moment from 'moment';
import colors from '../globalStyles.scss';

const ProposalQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    noOverlay
}) => {
    const { text, formatDate } = useLanguage();
    const { isDesktop } = useScreenSize();
    return (
        <ConfirmModal
            toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
            isOpen={isQuickViewOpen}
            noOverlay={noOverlay}
            shaddow
            width={isDesktop ? '50%' : '100%'}
            height={isDesktop ? '70%' : '100%'}
        >
            <div className='w-100 mr-md'>
                <h4 className='mb-md'>{quickViewData?.title}</h4>
                <FieldWrapper
                    name={'#'}
                    label={`${quickViewData?.uuid}`}
                />
                <ConditionalRender renderIf={quickViewData?.isSubmitted}>
                    <FieldWrapper
                        name={text?.proposals?.details?.submitted}
                        label={<FontAwesomeIcon
                            style={{
                                color: colors.green,
                                marginLeft: '1em'
                            }}
                            icon={faCheck}
                        />}
                    />

                </ConditionalRender>
                <FieldWrapper
                    name={text?.proposals?.details?.status}
                    label={proposalStatus(text).find(s => s.value === quickViewData?.status)?.label}
                />
                <ConditionalRender renderIf={!isEmpty(quickViewData?.projectId)}>
                    <FieldWrapper
                        name={text?.proposals?.details?.project}
                        label={quickViewData?.projectId?.projectName}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(quickViewData?.clientId)}>
                    <FieldWrapper
                        name={text?.proposals?.details?.client}
                        label={quickViewData?.clientId?.name}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(quickViewData?.additionalEmails)}>
                    <FieldWrapper
                        name={text?.proposals?.details?.additionalEmails}
                        label={quickViewData?.additionalEmails?.map(email => email).join(', ')}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(quickViewData?.emailSubject)}>
                    <FieldWrapper
                        name={text?.proposals?.create?.emailSubject}
                        label={quickViewData?.emailSubject}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(quickViewData?.emailMessage)}>
                    <FieldWrapper
                        name={text?.proposals?.create?.emailMessage}
                        label={quickViewData?.emailMessage}
                    />
                </ConditionalRender>

                <ConditionalRender renderIf={!isEmpty(quickViewData?.fields)}>
                    <table className="w-100 mb-md">
                        <thead className="b">
                            <tr>
                                <th className='pr-md'>{text?.proposals?.create?.descriptionTwo}</th>
                                <th className='pr-md'>{text?.proposals?.create?.fields?.notes}</th>
                                <th className='pr-md'>{text?.proposals?.create?.fields?.amount}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quickViewData?.fields?.map((field, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '10px 0', borderBottom: '1px solid #DDD' }}>{field?.description}</td>
                                    <td style={{ padding: '10px 0', borderBottom: '1px solid #DDD' }}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: field?.notes?.replace(/\n/g, '<br />') || '',
                                            }}
                                        />
                                    </td>
                                    <td style={{ padding: '10px 0', borderBottom: '1px solid #DDD' }}>${field?.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ConditionalRender>



                <FieldWrapper
                    name={text?.proposals?.details?.total}
                    label={`$${quickViewData?.totalCost}`}
                />
                <ConditionalRender renderIf={!isEmpty(quickViewData?.images)}>
                    <RenderImages
                        images={quickViewData?.images}
                        // roleName={user?.roleName}
                        disabled={true}
                        // isEditing={isEditing}
                        // removeImage={removeImage}
                        openModal={() => null}
                    // closeModal={closeModal}
                    // setActiveIndex={setActiveIndex}
                    // activeIndex={activeIndex}
                    // isModalOpen={isModalOpen}
                    />
                </ConditionalRender>
            </div>
        </ConfirmModal>
    )
}

export default ProposalQuickView

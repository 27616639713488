import React from 'react'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarLogs from '../Core/CalendarLogs'
import CalendarLogsSmall from '../Core/CalendarLogsSmall'
import useScreenSize from '../context/useScreenSize'
import ConditionalRender from '../Core/ConditionalRender'

const LogDetails = ({
    projects,
    fetchTimeLogs,
    timeLogs,
    logsIsLoading,
    user
}) => {
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const assignedProjects = projects?.filter(project => {
        // Check if the user is an admin
        const isAdmin = project.assignedEmployees?.some(employee => employee?.id === user?.id);
        const isProjectCompleted = project?.isCompleted;

        return (isAdmin || user?.roleName === 'Admin') && !isProjectCompleted;
    });

    const getTitleFromMilliseconds = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let hoursText = hours === 1 ? 'hour' : 'hours';
        let minutesText = remainingMinutes === 1 ? 'minute' : 'minutes';

        if (hours > 0) {
            if (remainingMinutes > 0) {
                // Both hours and minutes
                return `${hours} ${hoursText} and ${remainingMinutes} ${minutesText}`;
            } else {
                // Only whole hours
                return `${hours} ${hoursText}`;
            }
        } else {
            // Only minutes
            return `${minutes} ${minutesText}`;
        }
    }

    // function getStartOfDayInUTC(clockIn) {
    //     // Parse the date in UTC mode and set it to the start of the day
    //     const startOfDay = moment.utc(clockIn).startOf('day');
    //     return startOfDay; // This will return the start of the day in UTC format
    // }


    const transformTimesToEvents = (times) => {
        // Helper function to get the start of the day in UTC
        const getStartOfDayInUTC = (dateStr) => {
            const date = new Date(dateStr);
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();
        };

        // Create an object to hold the groups
        const groupedByDay = times?.reduce((acc, current, index) => {
            if (current && current?.projectId) {
                // Extract the day part of the clockIn timestamp as the key
                const date = new Date(current.clockIn);
                const dayKey = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();

                // If the key doesn't exist in the accumulator, initialize it
                if (!acc[dayKey]) {
                    acc[dayKey] = {
                        id: index,
                        totalTime: 0,
                        isRunning: false,
                        projects: {}, // Changed to an object to store project names and times
                        totalTimeText: '',  // Added a key for the text representation of the total time
                        clockIn: getStartOfDayInUTC(dayKey), // Initialize using getStartOfDayInUTC
                    };
                }

                // Sum the totalTime for this key
                acc[dayKey].totalTime += current.totalTime;
                acc[dayKey].totalTimeText = getTitleFromMilliseconds(acc[dayKey].totalTime);  // Update the totalTimeText with the formatted time

                // Update isRunning status if current entry is running
                if (current.isRunning) {
                    acc[dayKey].isRunning = true;
                }

                // If the project doesn't exist in the projects object, initialize it
                if (!acc[dayKey].projects[current.projectId.projectName]) {
                    acc[dayKey].projects[current.projectId.projectName] = {
                        id: '',
                        totalTime: 0,
                        dailyLog: '',
                        projectTotalTime: '',
                        clockIn: new Date().toISOString(),
                        projectName: ''
                    };
                }

                // Add the totalTime for this project
                acc[dayKey].projects[current.projectId.projectName].id = current.projectId.id;
                acc[dayKey].projects[current.projectId.projectName].dailyLog = current.dailyLog;
                acc[dayKey].projects[current.projectId.projectName].clockIn = current.clockIn;
                acc[dayKey].projects[current.projectId.projectName].projectName = current.projectId.projectName;
                acc[dayKey].projects[current.projectId.projectName].totalTime += current.totalTime;
                acc[dayKey].projects[current.projectId.projectName].projectTotalTime = getTitleFromMilliseconds(acc[dayKey].projects[current.projectId.projectName].totalTime);

                return acc;
            }
            return acc;
        }, {});

        return groupedByDay;
    };




    return (
        <>
            <ConditionalRender renderIf={isDesktop}>
                <CalendarLogs
                    fetchTimeLogs={fetchTimeLogs}
                    logsIsLoading={logsIsLoading}
                    timeLogs={timeLogs}
                    transformTimesToEvents={transformTimesToEvents}
                    getTitleFromMilliseconds={getTitleFromMilliseconds}
                    projects={assignedProjects}
                    user={user}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isTablet || isPhone}>
                <CalendarLogsSmall
                    fetchTimeLogs={fetchTimeLogs}
                    logsIsLoading={logsIsLoading}
                    timeLogs={timeLogs}
                    transformTimesToEvents={transformTimesToEvents}
                    getTitleFromMilliseconds={getTitleFromMilliseconds}
                    projects={assignedProjects}
                    user={user}
                />
            </ConditionalRender>
        </>
    )
}

export default LogDetails
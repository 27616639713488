import request from '../request';

/**
 * Sends a inventory request to the API.
 * @param {Object} inventoryData - The inventory data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const createProject = async (inventoryData) => {
    try {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        };
        const { data, status } = await request('POST', '/project/create', inventoryData, config);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to create product: ${error.message}`);
    }
};

export default createProject;

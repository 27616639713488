import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import { NumericFormat } from 'react-number-format';
import SubmitModal from '../Core/SubmitModal'
import { isRequired, composeValidators } from '../utils/validators';
import CreateNewClientContainer from '../Clients/CreateNewClientContainer';
import { GlobalContext } from '../context/GlobalContext';
import CallToAction from '../Core/CallToAction';
import ConditionalRender from '../Core/ConditionalRender';
import MultiFields from '../Core/MultiFields';
import arrayMutators from 'final-form-arrays';
import ImageUploader from '../Core/ImageUploader';
import base64ToBlob from '../utils/base64ToBlob';
import { FormSpy, Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import SelectInput from '../Core/SelectInput';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';
import CreatableMultiInput from '../Core/Inputs/CreatableMultiInput';
import FieldsWrapper from './FieldsWrapper';
import classnames from 'classnames';
import {
    saveProposalAsDraft,
    createProposalImages,
    createProposal,
    sendProposalEmails,
    updateUpdatedDate
} from '../utils/calls';
import colors from '../globalStyles.scss';


const CreateNewProposalContainer = ({
    isOpen,
    toggle,
    projects,
    adminProjects,
    project,
    clients,
    isProject,
    projectId,
    fetchAllProposals,
    user

}) => {
    const { fetchAllClients } = useContext(GlobalContext);
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const { text } = useLanguage();
    const { notify } = useToast();
    const [spyValues, setSpyValues] = useState();
    const [total, setTotal] = useState(0);
    const [newClient, setNewClient] = useState({});

    const formRef = useRef();

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    const [initialProjectValue, setInitialProjectValue] = useState(() => {
        if (!isProject) {
            if (user?.roleName === 'Admin') {
                return { label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' };
            }
            const firstProject = projects?.find((x) => x?._id === projectId) || projects?.[0];
            return { label: firstProject?.projectName || text?.inventory?.details?.form?.unassigned, value: firstProject?._id || '000000000000000000000000' };
        }
        const project = projects?.find((x) => x?._id === projectId) || projects?.[0];
        return { label: project?.projectName || text?.inventory?.details?.form?.unassigned, value: project?._id || '000000000000000000000000' };
    });


    useEffect(() => {
        if (!isProject) {
            if (user?.roleName === 'Admin') {
                setInitialProjectValue({ label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' });
            } else {
                const project = projects?.find((x) => x?._id === projectId) || projects?.[0];
                setInitialProjectValue({ label: project?.projectName || text?.inventory?.details?.form?.unassigned, value: project?._id || '000000000000000000000000' });
            }
        } else {
            const project = projects?.find((x) => x?._id === projectId) || projects?.[0];
            setInitialProjectValue({ label: project?.projectName || text?.inventory?.details?.form?.unassigned, value: project?._id || '000000000000000000000000' });
        }
    }, [projectId, isProject, projects, text, user]);

    const [initialClientValue, setInitialClientValue] = useState(() => {
        const selectedProject = spyValues?.values?.projectId && projects?.find((x) => x?._id === spyValues?.values?.projectId?.value);
        return selectedProject?.client && {
            label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
            value: selectedProject?.client?._id
        };
    })

    useMemo(() => {
        if (initialProjectValue) {
            const selectedProject = projects?.find((x) => x?._id === spyValues?.values?.projectId?.value);
            setInitialClientValue(selectedProject?.client && {
                label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
                value: selectedProject?.client?._id
            });
        }
    }, [initialProjectValue])

    useEffect(() => {
        if (newClient) {
            formRef?.current?.change('clientId', {
                label: `${newClient?.name} (${newClient?.email})`,
                value: newClient?._id
            }
            )
        }
    }, [newClient])

    const [selectedImages, setSelectedImages] = useState([]);
    const handleImagesSelect = (imageData) => {
        setSelectedImages(imageData)
    };

    const uploadImages = async (proposalId) => {
        try {
            // Convert and upload product images
            const imageUploadPromises = selectedImages?.map(async (image) => {
                const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
                const formData = new FormData();
                formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
                formData.append("proposalId", proposalId);

                console.log("Uploading image...");

                // Send the image to a separate endpoint
                const res = await createProposalImages(formData);

                if (res?.status === 200) {
                    return res;
                } else {
                    throw new Error("Image upload failed");
                }
            }) || [];

            // Wait for all images to be uploaded
            await Promise.all(imageUploadPromises);
        } catch (error) {
            console.error("Error uploading images:", error);
        }
    };

    const onSubmit = async (values) => {
        let body = {}
        body.projectId = values?.projectId?.value;
        body.clientId = values?.clientId?.value;
        body.clientEmail = values?.clientId?.label.match(/\(([^)]+)\)/)?.[1] || '';
        body.yourEmail = user?.email
        if (!isEmpty(values?.additionalEmails)) {
            body.additionalEmails = values?.additionalEmails.map(x => x.value)
        }
        if (values?.emailSubject) {
            body.emailSubject = values?.emailSubject
        }
        body.title = values?.title
        body.emailMessage = values?.emailMessage
        body.fields = values?.fields?.map(x => {
            return {
                description: x?.description || '',
                amount: x?.amount || 0,
                notes: x?.notes || ''
            }
        })
        body.totalCost = total
        try {
            toggle()
            const res = await createProposal(body)
            if (res.status === 200) {
                const proposalId = res.data?._id
                uploadImages(proposalId).then(() => {
                    return sendProposalEmails(proposalId)
                })
                if (values?.projectId?.value) {
                    await updateUpdatedDate(values?.projectId?.value)
                }
                fetchAllProposals()
                notify(text?.notificationsUI?.proposal?.submitted, 'success')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const saveAsDraft = async () => {
        let values = {};
        if (spyValues?.values?.projectId) {
            values.projectId = spyValues?.values?.projectId?.value;
        }
        if (spyValues?.values?.clientId) {
            values.clientId = spyValues?.values?.clientId?.value;
            values.clientEmail = spyValues?.values?.clientId?.label.match(/\(([^)]+)\)/)?.[1] || '';
        }
        if (!isEmpty(spyValues?.values?.additionalEmails)) {
            values.additionalEmails = spyValues?.values?.additionalEmails.map(x => x.value)
        }
        if (spyValues?.values?.emailSubject) {
            values.emailSubject = spyValues?.values?.emailSubject
        }
        if (spyValues?.values?.emailMessage) {
            values.emailMessage = spyValues?.values?.emailMessage
        } else {
            values.emailMessage = ''
        }
        if (spyValues?.values?.title) {
            values.title = spyValues?.values?.title
        }
        if (total > 0) {
            values.totalCost = total
        }
        if (!isEmpty(spyValues?.values?.fields)) {
            values.fields = spyValues?.values?.fields.map(x => {
                return {
                    description: x?.description || '',
                    amount: x?.amount || 0,
                    notes: x?.notes || ''
                }
            })
        } else {
            values.fields = []
        }

        try {
            toggle()
            const res = await saveProposalAsDraft(values)
            if (res.status === 200) {
                const proposalId = res.data?._id
                if (spyValues?.values?.projectId) {
                    await updateUpdatedDate(spyValues?.values?.projectId?.value)
                }
                await uploadImages(proposalId)
                if (isProject) {
                    fetchAllProposals(projectId)
                } else {
                    fetchAllProposals()
                }
                notify(text?.notificationsUI?.proposal?.saved, 'success')
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!isEmpty(spyValues?.values?.fields)) {
            const total = spyValues?.values?.fields?.reduce((acc, curr) => {
                if (curr.amount === undefined) return acc
                return acc + curr.amount
            }, 0)
            setTotal(total)
        }
    }, [spyValues?.values?.fields]);

    const [addClient, setAddClient] = useState(false)

    return (
        <>
            <SubmitModal
                onClick={onSubmit}
                text={text?.proposals?.create?.send}
                toggle={toggle}
                isOpen={isOpen}
                saveAsDraft={spyValues?.values?.title ? saveAsDraft : null}
                saveAsDraftText={text?.proposals?.create?.save}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                mutators={{
                    ...arrayMutators,
                }}
            >
                <FormSpy subscription={{ values: true }}>
                    {({ form, values }) => {
                        formRef.current = form;
                        if (!values.fields || values.fields.length === 0) {
                            form.change('fields', [
                                {
                                    description: '',
                                    amount: null,
                                    notes: ''
                                }
                            ]);
                        }
                        return null;
                    }}
                </FormSpy>
                <FormSpy subscription={{ values: true }} onChange={(values) => setSpyValues(values)} />
                <div className='mr-md ml-md'>
                    <h4>{text?.proposals?.landing?.createButton}</h4>
                    <FormSpy subscription={{ values: true }}>
                        {({ form, values }) => {

                            return (
                                <>
                                    <SelectInput
                                        className='field-style'
                                        width={isDesktop ? 30 : isTablet ? 50 : 80}
                                        showLightColors
                                        menuPlacement='bottom'
                                        name='projectId'
                                        label={text?.proposals?.create?.project}
                                        isClearable={false}
                                        validate={required}
                                        disabled={isProject}
                                        initialValue={initialProjectValue}
                                        placeholder={text?.tasks?.create?.project}
                                        isSearchable
                                        isValidNewOption={() => false}
                                        controlWidth='100%'
                                        options={[
                                            ...(user?.roleName === 'Admin' ? [{ label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' }] : []),
                                            ...(adminProjects ? adminProjects?.filter((x) => !x.isCompleted)?.map((x) => ({
                                                label: x?.projectName,
                                                value: x?.id
                                            })) : [])
                                        ]}
                                        onChange={(selectedOption) => {
                                            const selectedProject = projects?.find((x) => x?._id === selectedOption.value);
                                            const newClientValue = selectedProject?.client && {
                                                label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
                                                value: selectedProject?.client?._id
                                            };
                                            form.change('clientId', newClientValue);
                                        }}
                                    />

                                    <MultiFields
                                        className='field-style'
                                        name="title"
                                        component="text"
                                        type="text"
                                        label={text?.proposals?.create?.title}
                                        validate={required}
                                        block
                                        showLightColors
                                    />

                                    <div className='email_wrapper' style={{
                                        backgroundColor: colors.lightGray,
                                        padding: '1em',
                                    }}>
                                        <FormSpy subscription={{ values: true }}>
                                            {({ values }) => {
                                                const selectedProject = projects?.find((x) => x?._id === values?.projectId?.value);

                                                return (
                                                    <ConditionalRender renderIf={true}>
                                                        <p className='small'>
                                                            {text?.proposals?.create?.emailDescription}
                                                        </p>
                                                        <p className='small flex'>
                                                            {text?.proposals?.details?.textFour}
                                                        </p>
                                                        <div className={classnames({
                                                            'flex items-center mt-md': isDesktop || isTablet,
                                                        })} >
                                                            <SelectInput
                                                                width={isDesktop ? 30 : isTablet ? 50 : 100}
                                                                showLightColors
                                                                menuPlacement='bottom'
                                                                initialValue={initialClientValue}
                                                                name='clientId'
                                                                label={text?.projects?.create?.client}
                                                                isClearable={false}
                                                                validate={required}
                                                                placeholder={text?.proposals?.create?.selectClient}
                                                                isSearchable
                                                                isValidNewOption={() => false}
                                                                controlWidth='100%'
                                                                options={clients?.map((client) => ({
                                                                    label: `${client.name} (${client.email})`,
                                                                    value: client?._id
                                                                }))}
                                                            />
                                                            <CallToAction
                                                                text={text?.client?.create?.title}
                                                                type='button'
                                                                onClick={() => setAddClient(!addClient)}
                                                                style={isDesktop || isTablet ? {
                                                                    alignSelf: 'flex-end',
                                                                    marginLeft: '1em'
                                                                } : {
                                                                    marginTop: '1em'
                                                                }}
                                                            />
                                                        </div>
                                                    </ConditionalRender>
                                                );
                                            }}
                                        </FormSpy>
                                        <FormSpy subscription={{ values: true }}>
                                            {({ values }) => {
                                                return (
                                                    <ConditionalRender renderIf={!isEmpty(values?.clientId)}>
                                                        <Field name="additionalEmails">
                                                            {({ input, meta }) => (
                                                                <CreatableMultiInput
                                                                    className='field-style'
                                                                    input={input}
                                                                    meta={meta}
                                                                    isClearable={true}
                                                                    showLightColors
                                                                    placeHolder={text?.proposals?.create?.additionalEmails}
                                                                    width={'30%'}
                                                                />
                                                            )}
                                                        </Field>
                                                    </ConditionalRender>
                                                );
                                            }}
                                        </FormSpy>
                                        <MultiFields
                                            className='field-style'
                                            name="emailSubject"
                                            component="text"
                                            type="text"
                                            label={text?.proposals?.create?.emailSubject}
                                            block
                                            showLightColors
                                        />
                                        <MultiFields
                                            className='field-style'
                                            name="emailMessage"
                                            component="textarea"
                                            type="text"
                                            label={text?.proposals?.create?.emailMessage}
                                            block
                                            showLightColors
                                        />
                                    </div>
                                </>
                            );
                        }}
                    </FormSpy>
                    <FieldsWrapper
                        name={'fields'}
                        showLightColors
                        validate={required}
                    />

                    <ConditionalRender renderIf={!isEmpty(spyValues?.values?.fields)}>
                        <div>
                            <div className='field-style'>
                                <label className='mb-sm b'>
                                    {text?.proposals?.create?.total}
                                </label>
                                <div className='custom-field'>
                                    <NumericFormat
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        isNumericString={true}
                                        name={'total'}
                                        value={total}
                                        placeholder={'$'}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </ConditionalRender>

                    <ImageUploader
                        onImageUpload={handleImagesSelect}
                        maxFiles={10}
                        title={text?.projects?.create?.design}
                        customMessage={text?.inventory?.details?.uploadImages}
                    />
                </div>
            </SubmitModal>
            <ConditionalRender renderIf={addClient}>
                <CreateNewClientContainer
                    isOpen={addClient}
                    toggle={() => setAddClient(false)}
                    user={user}
                    fetchAllClients={fetchAllClients}
                    setNewClient={setNewClient}
                    clients={clients}
                />
            </ConditionalRender>
        </>
    )
}

export default CreateNewProposalContainer
import request from '../request';

/**
 * Sends a user registration request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateUser = async (values) => {
    try {
        const { data, status } = await request('PATCH', `/users/update-user`, values);
        return { data, status };
    } catch (error) {
        throw new Error(`Email Already in Use`);
    }
};

export default updateUser;

import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import {
    clockIn,
    clockOut,
    updateTime,
    getTodaysTime,
    getTimesheetById
} from '../utils/calls';
import { GlobalContext } from './GlobalContext';
import moment from 'moment';

export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
    const [projectId, setProjectId] = useState(null);
    const [isRunning, setIsRunning] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timesheet, setTimesheet] = useState(null);

    const { fetchTimeLogs, user } = useContext(GlobalContext);

    useEffect(() => {
        if (user?.id !== timesheet?.userId) {
            localStorage.removeItem('timeTracker');
        }
    }, [timesheet]);

    const updateLocalStorage = useCallback((running, time, currentProjectId, timesheetId) => {
        const now = new Date();
        // Adjust to local time
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        const data = {
            isRunning: running,
            elapsedTime: time,
            projectId: currentProjectId,
            timesheetId: timesheetId,
            lastUpdateTime: nowLocal,
        };
        localStorage.setItem('timeTracker', JSON.stringify(data));
    }, []);

    // Timer logic
    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                setElapsedTime((prevElapsedTime) => {
                    const updatedElapsedTime = prevElapsedTime + 1000;
                    updateLocalStorage(true, updatedElapsedTime, projectId, timesheet?._id);
                    return updatedElapsedTime;
                });
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [isRunning, elapsedTime]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('timeTracker'));
        if (storedData) {
            setProjectId(storedData?.projectId);
        }
    }, []);

    const fetchOrCreateTodayTime = useCallback(async () => {
        const now = new Date();
        // Adjust to local time
        const todaysTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';
        try {
            if (projectId) {
                const res = await getTodaysTime({ projectId, todaysTime });
                if (res.data?.timesheet) {
                    setTimesheet(res.data?.timesheet);

                    if (res.data?.timesheet?.isRunning) {
                        // Convert the lastUpdateTime from DB to a moment object
                        const lastUpdateTimeFromDB = moment(res.data.timesheet.lastUpdateTime);

                        // Get the current local time as a moment object
                        const currentTimeLocal = moment(todaysTime);

                        // Calculate the elapsed time since the last update
                        const timeElapsedSinceLastUpdate = currentTimeLocal.diff(lastUpdateTimeFromDB);


                        // Add the elapsed time since the last update to the base elapsed time
                        const baseElapsedTime = res.data.timesheet.totalTime;
                        const updatedElapsedTime = baseElapsedTime + timeElapsedSinceLastUpdate;

                        setElapsedTime(updatedElapsedTime);
                        updateLocalStorage(isRunning, updatedElapsedTime, projectId, res.data?.timesheet?._id);
                    } else {
                        setElapsedTime(res.data?.timesheet?.totalTime);
                        updateLocalStorage(isRunning, res.data?.timesheet?.totalTime, projectId, res.data?.timesheet?._id);
                    }
                    setIsRunning(res.data?.timesheet?.isRunning);
                }
            }
        } catch (error) {
            console.error('Error fetching or creating today\'s timesheet:', error);
        }
    }, [isRunning, projectId, updateLocalStorage]);

    const initializeTimerState = useCallback(async () => {
        const storedData = JSON.parse(localStorage.getItem('timeTracker'));
        const nowLocal = new Date();
        let lastUpdateTime = storedData?.lastUpdateTime;

        if (storedData?.timesheetId) {
            try {
                const { data, status } = await getTimesheetById(storedData.timesheetId);

                if (status === 200) {
                    if (data.isRunning) {
                        setIsRunning(data.isRunning);
                        setProjectId(data.projectId);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (storedData?.timesheetId && projectId === storedData?.projectId && !(nowLocal > new Date(lastUpdateTime))) {
            try {
                const { data, status } = await getTimesheetById(storedData.timesheetId);
                if (status === 200) {
                    const lastUpdateTimeLocal = new Date(lastUpdateTime).getTime();
                    const currentTimeLocal = nowLocal.getTime();

                    const timeElapsedSinceLastUpdate = storedData.isRunning
                        ? currentTimeLocal - lastUpdateTimeLocal
                        : 0;

                    const baseElapsedTime = storedData.elapsedTime ?? data.totalTime;
                    const updatedElapsedTime = baseElapsedTime + timeElapsedSinceLastUpdate;

                    // setElapsedTime(updatedElapsedTime);
                    setTimesheet(data);
                    updateLocalStorage(storedData.isRunning, updatedElapsedTime, projectId, data._id);
                }
            } catch (error) {
                console.error('Failed to get timesheet by id:', error);
            }
        } else {
            fetchOrCreateTodayTime();
        }
    }, [projectId, fetchOrCreateTodayTime, updateLocalStorage]);


    useEffect(() => {
        initializeTimerState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    // useEffect(() => {
    //     const storedData = JSON.parse(localStorage.getItem('timeTracker'));
    //     if (storedData && storedData?.timesheetId) {
    //         const fetch = async () => {
    //             const { data, status } = await getTimesheetById(storedData.timesheetId);
    //             if (status === 200) {
    //                 return data;
    //             }
    //         };
    //         const elapsedTime = storedData?.elapsedTime;
    //         if (elapsedTime) {
    //             fetch().then((res) => {
    //                 const totalTime = res?.totalTime;
    //                 if (totalTime !== elapsedTime) {
    //                     if (!res.isRunning) {
    //                         // resets the time to whatever is new from edit
    //                         updateLocalStorage(res?.isRunning, res.totalTime, res?.projectId, res.data?._id);
    //                     }
    //                 }
    //             });
    //         }
    //     }
    // }, [updateLocalStorage]);

    const formatTime = useCallback((time) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }, []);

    const handleStart = useCallback(async (currentProjectId) => {
        const now = new Date();
        // Adjust to local time
        const todaysTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        // Set projectId and save it to localStorage
        setProjectId(currentProjectId);

        // Continue with the start logic...
        const res = await clockIn(currentProjectId, true, elapsedTime, todaysTime);
        if (res.status === 200) {
            setTimesheet(res.data.timesheet || res.data);
            setIsRunning(true);
            updateLocalStorage(true, elapsedTime, currentProjectId, res.data.timesheet?._id);
        }
    }, [setProjectId, elapsedTime, updateLocalStorage]);


    const handlePause = useCallback(async () => {
        try {
            const now = new Date();
            // Adjust the current time to the local timezone
            const localClockOutTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

            const body = {
                totalTime: elapsedTime,
                clockIn: timesheet?.clockIn,
                clockOut: localClockOutTime,
                lastUpdateTime: timesheet?.lastUpdateTime,
                isRunning: false
            };

            const res = await updateTime(timesheet?._id, body);
            if (res.status === 200) {
                setIsRunning(false);
                updateLocalStorage(false, elapsedTime, projectId, timesheet?._id);
                // fetchTimeLogs()
            }
        } catch (error) {
            console.error(error);
        }
    }, [elapsedTime, timesheet, updateLocalStorage, projectId]);


    const handleStop = useCallback(async () => {
        try {
            const res = await clockOut(projectId);
            if (res.status === 200) {
                setIsRunning(false);
                // setElapsedTime(0);
                fetchOrCreateTodayTime();
            }
        } catch (error) {
            console.error(error);
        }
    }, [projectId, fetchOrCreateTodayTime]);

    useEffect(() => {
        // Then initialize the timer state with the fetched data
        initializeTimerState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array ensures this runs once on mount

    const value = {
        isRunning,
        elapsedTime,
        timesheet,
        setTimesheet,
        handleStart,
        handlePause,
        handleStop,
        formatTime,
        setProjectId,
        projectId,
        fetchTimeLogs
    };

    return (
        <TimerContext.Provider value={value}>
            {children}
        </TimerContext.Provider>
    );
};

import React from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../globalStyles.scss';

const dotsAnimation = keyframes`
  0%, 20%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

const ThinkingDotsContainer = styled.div`
  display: inline-block;
  font-size: 1.5rem;
  span {
    display: bock;
    animation-name: ${dotsAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    font-size: ${(props) => props.fontSize || '1rrem'};
    color: ${colors.black} !important;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ThinkingDots = ({ fontSize }) => {
  return (
    <ThinkingDotsContainer fontSize={fontSize}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </ThinkingDotsContainer>
  );
};

export default ThinkingDots;

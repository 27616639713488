import React, { useState, useEffect } from 'react';
import SearchInput from '../Core/Inputs/SearchInput';
import CheckBox from '../Core/Inputs/CheckBox';
import { findCompanies } from '../utils/calls';
import RenderCompany from './RenderCompany';

const AdminContainer = () => {
    const [companies, setCompanies] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [pendingAccess, setPendingAccess] = useState(false);

    // Update search query state
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Fetch and filter companies based on search and checkbox
    const filteredData = async () => {
        const filterOptions = {};
        if (searchQuery) {
            filterOptions.query = searchQuery;
        }
        if (pendingAccess) {
            filterOptions.pendingAccess = true; // pendingAccess set to true when checked
        }
        try {
            const res = await findCompanies(filterOptions); // Call the findCompanies API with filter options
            if (res.status === 200) {
                setCompanies(res.data); // Set companies from API response
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const handlePendingAccess = () => {
        setPendingAccess(!pendingAccess);
        setSearchQuery(''); // Clear search query if showing all companies
    }

    // Fetch companies when searchQuery or isChecked changes
    useEffect(() => {
        filteredData();
    }, [searchQuery, isChecked, pendingAccess]);

    return (
        <div>
            <div className='flex items-center'>
                <SearchInput
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeHolder="Search Company"
                />
                <CheckBox
                    checked={pendingAccess}
                    label={'Show pending access companies'}
                    onChange={handlePendingAccess}
                />
            </div>

            <div style={{ marginTop: '15px' }}>
                {companies.length > 0 ? (
                    companies.map((company) => (
                        <RenderCompany
                            key={company._id}
                            company={company}
                        />
                    ))
                ) : (
                    <p>No companies found</p>
                )}
            </div>
        </div>
    );
};

export default AdminContainer;

import React, { useState } from 'react'
import styled from 'styled-components'
import HoverPopup from './HoverPopup'
import ConditionalRender from './ConditionalRender'
import colors from '../globalStyles.scss'
import Switch from '@mui/material/Switch';
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext'
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

import classnames from 'classnames'

const StyledDiv = styled.div`
        &.desktop {
            .SelectableBox {
                    width: 30%;
                }
        }
            &.tablet {
                .SelectableBox {
                    width: 50%;
                }
            }
            &.phone {
                .SelectableBox {
                    width: 100%;
                }
                .header-wrapper {
                    flex-direction: column;
                    padding: 0.5em;
                }
            }
    h4 {
       font-size: 1em;,
    }

`

const Wrapper = ({
    title,
    children,
    flex,
    margin,
    padding,
    width,
    btnWidth,
    borderRight,
    button,
    secondaryButton,
    showLightColors,
    disableButton,
    noDataText,
    isLoading,
    className,
    toggle,
    toggleState,
    isFlex = true,
    expandable,
    setExpanded,
    section
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const handleToggleModal = () => {
        setExpanded(section);
    };

    return (
        <StyledDiv
            theme={theme}
            className={classnames({
                [className]: className,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
            style={{
                flex: flex,
                margin: margin,
                padding: padding,
                width: width,
                borderRight: borderRight
            }}
        >
            <ConditionalRender
                count={1}
                customStyled={{
                    height: '50vh',
                    display: 'flex'
                }}
                isLoading={isLoading} renderIf={true}>
                <div
                    className={classnames({
                        'flex': isFlex,
                        'justify-space-between items-center flex-wrap header-wrapper': true,
                    })}
                    style={{
                        background: ((theme === 'dark' && !showLightColors) ? colors.secondaryLight : colors.darkGray) || (theme === 'dark' && showLightColors ? colors.lightGray : colors.backgroundSecondary),
                        padding: '0em 0.5em'
                    }}
                >
                    <ConditionalRender renderIf={title}>
                        <ConditionalRender renderIf={toggle}>
                            <div className='flex align-center'>
                                <h4 className='mb-md mt-md mr-md flex items-center'>
                                    <ConditionalRender renderIf={expandable}>
                                        <HoverPopup
                                            className='mr-sm'
                                            placement={'top'}
                                            id={`expand_${section}_popup_${title}`}
                                            text={isDesktop && text?.editToggle?.expand}
                                            onClick={handleToggleModal}
                                            style={{
                                                background: 'transparent'
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faExpand}
                                            />
                                        </HoverPopup>
                                    </ConditionalRender>
                                    {title}
                                </h4>
                                <div className='ml-md flex items-center'>
                                    <Switch
                                        checked={toggleState}
                                        onChange={toggle}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <span
                                        style={{
                                            fontFamily: colors.openSans,
                                            color: toggleState ? (theme === 'dark' ? colors.white : colors.blue) : (theme === 'dark' ? colors.white : colors.blue)
                                        }}
                                    >{!toggleState ? text?.editToggle?.off : text?.editToggle?.on}
                                    </span>
                                </div>
                            </div>
                        </ConditionalRender>
                        <ConditionalRender renderIf={!toggle}>
                            <h4 className='mb-md mt-md mr-md flex items-center'>
                                <ConditionalRender renderIf={expandable}>
                                    <HoverPopup
                                        className='mr-sm'
                                        placement={'top'}
                                        id={`expand_${section}_popup`}
                                        text={isDesktop && text?.editToggle?.expand}
                                        onClick={handleToggleModal}
                                        style={{
                                            background: 'transparent',
                                            color: theme === 'dark' ? colors.white : colors.blue
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faExpand}
                                        />
                                    </HoverPopup>
                                </ConditionalRender>
                                {title}
                            </h4>
                        </ConditionalRender>
                    </ConditionalRender>
                    <div className='flex items-center'>
                        <ConditionalRender renderIf={button && !disableButton}>
                            <div
                                className={classnames({
                                    'mb-md': isPhone
                                })}
                                style={{
                                    width: btnWidth || 'auto',
                                }}
                            >
                                {button}
                            </div>
                        </ConditionalRender>
                        <ConditionalRender renderIf={secondaryButton}>
                            <div
                                className={classnames({
                                    'mb-md': isPhone
                                })}
                                style={{
                                    width: btnWidth || 'auto'
                                }}
                            >
                                {secondaryButton}
                            </div>
                        </ConditionalRender>
                    </div>
                </div>
                <div className='body mt-md mb-md'>
                    <ConditionalRender renderIf={noDataText}>
                        <p>
                            {noDataText}
                        </p>
                    </ConditionalRender>
                    {children}
                </div>
            </ConditionalRender>
        </StyledDiv>
    );
};

export default Wrapper;
import request from '../request';
import queryString from "query-string";


/**
 * Get products inventory request to the API.
 * @param {string} userId - The user ID.
 * @param {string} projectId - The project ID.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getProducts = async (userId, projectId) => {

    let url = '/inventory/get-products';

    // Construct query parameters
    const queryParams = [];
    if (userId) {
        queryParams.push(`userId=${encodeURIComponent(userId)}`);
    }
    if (projectId) {
        queryParams.push(`projectId=${encodeURIComponent(projectId)}`);
    }

    // Append query parameters to URL if any
    if (queryParams.length) {
        url += `?${queryParams.join('&')}`;
    }

    try {
        const { data, status } = await request('GET', url);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get products: ${error.message}`);
    }
};

export default getProducts;

import React, { useState, useEffect } from 'react';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Form } from 'react-final-form';
import MultiFields from '../Core/MultiFields';
import FormActions from '../Core/FormActions';
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';
import { getClient, updateClient } from '../utils/calls';
import ConditionalRender from '../Core/ConditionalRender';
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext';
import colors from '../globalStyles.scss'

const ClientDetailsContainer = ({
    fetchAllClients,
    user
}) => {
    const location = useLocation();
    const { text } = useLanguage();
    const { notify } = useToast();

    const { id } = queryString.parse(location.search)

    const [client, setClient] = useState({});
    const [clientIsLoading, setClientIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false);

    const required = composeValidators(
        isRequired(text?.projects?.details?.validations?.required)
    )
    const emailValidator = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
        isValidEmail(text?.employees?.createNew?.validation?.validEmail)
    )
    const [disabled, setDisabled] = useState(false)


    const handleGetClient = async () => {
        setClientIsLoading(true)
        try {
            const res = await getClient(id)
            if (res.status === 200) {
                const createdById = res.data.createdBy?._id
                if ((createdById !== user?._id) && (user?.roleName !== 'Admin')) {
                    setDisabled(true)
                }
                setClientIsLoading(false)
                setClient(res.data)
            }
        } catch (error) {
            setClientIsLoading(false)
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetClient()
    }, [id])

    const onSubmit = async (values) => {
        setClientIsLoading(true)
        try {
            const res = await updateClient(id, values)
            if (res.status === 200) {
                setIsEditing(false)
                handleGetClient()
                fetchAllClients()
                setClientIsLoading(false)
                notify(text?.notificationsUI?.clients?.saved, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            setClientIsLoading(false)
            console.error(error)
        }
    }

    return (
        <ConditionalRender loadingCount={3} renderIf={true} isLoading={clientIsLoading}>
            <div className='mb-lg tx-left'>
                <h4>
                    {text?.projects?.details?.details}
                </h4>
                <hr />
            </div>
            <Form onSubmit={onSubmit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <MultiFields
                            className='field-style'
                            name="name"
                            component="input"
                            type="text"
                            label={text?.client?.create?.name}
                            block
                            initialValue={client?.name}
                            setIsEditing={setIsEditing}
                            validate={required}
                            disabled={disabled}
                        />
                        <MultiFields
                            className='field-style'
                            name="email"
                            component="input"
                            type="text"
                            label={text?.client?.create?.email}
                            validate={emailValidator}
                            block
                            initialValue={client?.email}
                            setIsEditing={setIsEditing}
                            disabled={disabled}
                        />
                        <MultiFields
                            className='field-style'
                            name="phone"
                            component="phone"
                            type="text"
                            label={text?.client?.create?.phone}
                            block
                            initialValue={client?.phone}
                            setIsEditing={setIsEditing}
                            disabled={disabled}
                        />
                        <MultiFields
                            className='field-style'
                            name="address"
                            component="location"
                            label={text?.client?.create?.address}
                            block
                            initialValue={client?.address}
                            setIsEditing={setIsEditing}
                            disabled={disabled}
                            showMapLabel={text?.client?.create?.showMapLabel}
                        />
                        <MultiFields
                            className='field-style'
                            name="notes"
                            component="textarea"
                            type="text"
                            label={text?.client?.create?.notes}
                            setIsEditing={setIsEditing}
                            initialValue={client?.notes}
                            block
                            disabled={disabled}
                        />
                        {
                            (isEditing && !disabled) && (
                                <FormActions
                                    form={form}
                                    floating
                                    type={'submit'}
                                    submitText={text?.projects?.details?.saveButton}
                                    btnStyle={{
                                        color: colors.blue
                                    }}
                                />
                            )
                        }
                    </form>
                )}
            />


            {/* TODO: SHOW  THE LIST OF THE PROPOSALS SENT TO THIS CLIENT */}
        </ConditionalRender>

    )
}

export default ClientDetailsContainer
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getPremiumProducts } from './premiumProducts';
import CallToAction from '../Core/CallToAction';
import PaymentForm from './PaymentForm';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import robot from '../images/robot.png'

const StyledDiv = styled.div`
.list {
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li {
            margin-bottom: 0.5em;
        }
    }
}
    .SubscriptionOptions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 50px;
        .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-evenly;
        margin: 15px;
        flex: 1;
        h2 {
            font-size: 2rem;
            font-family: $openSans;
            font-weight: bold;
        }
        h3 {
            font-size: 1.5rem;
            font-family: $openSans;
            font-weight: bold;
        }
        }
    }
    .PaymentForm__wrapper {
        width: 100%;
        display: flex;
        height: 100%;
      }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 2px solid ${props => props.theme === 'light' ? colors.blue : colors.secondary};
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  flex: 1;
  border-radius: 5px;
  margin-bottom: 1em;
  &:first-of-type {
    margin-right: 1em;
  }
  &:hover {
    box-shadow: 0 0 10px 0 ${props => props.theme === 'light' ? colors.blue : colors.secondary};
    }
  .header {
    text-align: left;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  .body {
    text-align: left;
    .price {
        font-size: 2em;
        font-weight: bold;
        color: ${props => props.theme === 'light' ? colors.blue : colors.secondary};

    }
  }
`;


const SubscriptionOptions = ({
    isLoggedIn,
    fetchUserDetails,
    trialHasBeenUsed,
    email,
    isPremium,
    fetchCompanyDetails,
    company
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();


    const [selectedPlan, setSelectedPlan] = useState({});
    const [showPayment, setShowPayment] = useState(false);
    const [startTrial, setStartTrial] = useState(false)

    const navigate = useNavigate();

    const handleTrial = () => {
        if (!isLoggedIn) {
            navigate('/login');
            setShowPayment(false);
            setStartTrial(false)
        } else {
            setShowPayment(true);
            setStartTrial(true)
            // TODO: DECIDE IF THE TRIAL WILL SET PREMIUM BY MONTH OR YEAR
            setSelectedPlan({
                type: 'month',
                label: text?.payment?.montly,
                price: 35,
                productName: 'MonthlyPremium',
                value: '$35 USD',
            });
        }
    }

    const handlePlanChange = ({ type, price, productName, value }) => {
        setSelectedPlan({ type, price, productName, value });
        setShowPayment(true);
        setStartTrial(false)
    };

    return (
        <StyledDiv
            theme={theme}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            {
                showPayment ? (
                    <PaymentForm
                        selectedPlan={selectedPlan}
                        setShowPayment={setShowPayment}
                        fetchUserDetails={fetchUserDetails}
                        startTrial={startTrial}
                        email={email}
                        isPremium={isPremium}
                        fetchCompanyDetails={fetchCompanyDetails}
                        company={company}
                    />
                ) : (
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img height={'80%'} src={robot} alt={'robot'} className='mr-md' />

                            <div className='flex flex-column'>
                                <div className={'list'}>
                                    <h1 className='mb-md'>
                                        {text?.payment?.title}
                                    </h1>
                                    <ul>
                                        <li>
                                            {text?.payment?.list?.one}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.two}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.three}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.four}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.five}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.six}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.seven}
                                        </li>
                                        <li>
                                            {text?.payment?.list?.eight}
                                        </li>
                                    </ul>
                                </div>

                                <div className='SubscriptionOptions'>
                                    {
                                        getPremiumProducts(text)?.map((x) => {
                                            return (
                                                <Card theme={theme} onClick={() => handlePlanChange(x)}>
                                                    <h3 className='header'>
                                                        {x.label}
                                                    </h3>
                                                    <div className='body'>
                                                        <p className='price'>
                                                            {x.value}
                                                        </p>
                                                        <hr />
                                                        <small>
                                                            {text?.payment?.tag}
                                                        </small>
                                                    </div>
                                                </Card>
                                            )
                                        })
                                    }
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}>
                                    {
                                        !trialHasBeenUsed && (
                                            <CallToAction
                                                text={text?.payment?.freeButton}
                                                onClick={handleTrial}
                                                style={{ marginRight: '15px' }}

                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </StyledDiv>
    );
};

export default SubscriptionOptions;

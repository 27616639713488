import React, { useState, useEffect } from 'react';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import {
    findUser,
    getProducts,
    getAttributes,
    getQueryResults,
    adminUpdate,
} from '../utils/calls';
import { v4 as uuidv4 } from 'uuid';
import CallToAction from '../Core/CallToAction';

import SelectInput from '../Core/SelectInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { composeValidators, isRequired } from '../utils/validators';
import { isEmpty } from 'lodash';
import { Form, FormSpy } from 'react-final-form';
import ConditionalRender from '../Core/ConditionalRender';
import MultiFields from '../Core/MultiFields';
import FormActions from '../Core/FormActions';
import colors from '../globalStyles.scss'

const placeHolderCode = `const result = await Inventory.aggregate([
    {
        $match: {
            __MATCH__
        }
    },
    {
        $group: {
            _id: null,
            "average-quantity": {
                $avg: { $ifNull: ["$inventoryQuantity", "$$REMOVE"] }
            },
            "average-stock": {
                $avg: { $ifNull: ["$advanceFields.number-stock", "$$REMOVE"] }
            }
        }
    },
    {
        $project: {
            _id: 0,
            "average-quantity": { $ifNull: ["$average-quantity", "Field does not exist"] },
            "average-stock": { $ifNull: ["$average-stock", "Field does not exist"] }
        }
    }
]);`


const AdminDetailsContainer = () => {
    let formReference;  // Define a variable to hold the form reference

    const location = useLocation();
    const [user, setUser] = useState({});
    const [inventory, setInventory] = useState([])
    const [inventoryColumns, setInventoryColumns] = useState([]);
    const [widgets, setWidgets] = useState([])
    const [extractedCode, setExtractedCode] = useState('');
    const [queryResult, setQueryResult] = useState([])

    const [action, setAction] = useState('')

    // password reset
    const [emailSent, setEmailSent] = useState(false);
    const [timeLimit, setTimeLimit] = useState('10 minutes');
    const [customType, setCustomType] = useState('');

    const fetchUserFromAPI = async (id) => {
        try {
            const [user, products, attributes] = await Promise.all([
                findUser({ query: id }),
                getProducts(id),
                getAttributes(id)
            ])
            if (user.status === 200) {
                setUser(user.data[0]);
            }
            if (products.status === 200) {
                setInventory(products.data)
            }
            if (attributes.status === 200) {
                const sortedInventoryColumns = attributes?.data?.inventoryColumns.sort((a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder));
                setInventoryColumns(sortedInventoryColumns)
                setWidgets(attributes?.data?.widgets);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const reset = () => {
        formReference.change('timeLimit', null);
        formReference.change('action', null);
        formReference.change('email', user?.email);
        formReference.change('extractedCode', null);
        formReference.change('combineText', null);
        formReference.change('customType', null);
        formReference.change('widget', { value: 'customWidget', label: 'custom widget' })
        setTimeLimit('10 minutes');
        setExtractedCode(placeHolderCode)
        setCustomType('');
        setEmailSent(false);
        setInventory([]);
        setQueryResult([]);
        setAction('')
    }

    const handleActionChange = (e) => {
        if (e?.value) {
            setAction(e?.value);
            formReference.change('widget', { value: 'customWidget', label: 'custom widget' })
        } else {
            reset(formReference)
        }
        if (e?.value === 'customWidget') {
            formReference.change('extractedCode', placeHolderCode)
            setExtractedCode(placeHolderCode)
        }
    }

    const handleTimeLimitChange = (e) => {
        if (e?.value) {
            setTimeLimit(e?.label)
        } else {
            setTimeLimit('')
        }
    }
    const handleSetCustomType = (e) => {
        if (e?.value) {
            setCustomType(e?.label)
        } else {
            setCustomType('');
        }
    }
    const handleQuery = async () => {
        const userId = user?.id

        if (action === 'customWidget') {
            const res = await getQueryResults(extractedCode, userId);
            if (res.status === 200) {
                const codeResult = res?.data?.codeResult;

                // Check if codeResult is not empty
                if (codeResult && Object.keys(codeResult).length > 0) {
                    // Transforming the codeResult
                    const newArr = [];

                    for (let [key, value] of Object.entries(codeResult[0])) {
                        newArr.push({ key, value });
                    }

                    // Setting the state with the transformed array
                    setQueryResult(newArr);
                } else {
                    console.log("codeResult is empty");
                }
            }
        }

    }
    const onSubmit = async (values) => {
        const action = values?.action?.value;
        const userId = user?.id

        try {
            // submit resset password api
            if (action === 'resetPassword') {
                const email = values?.email
                setEmailSent(false)
                const res = await adminUpdate({ email, timeLimit, action, userId })
                if (res.status === 200) {
                    setEmailSent(true)
                } else {
                    setEmailSent(false)
                }
            }
            if (action === 'customWidget') {
                const res = await adminUpdate({
                    action,
                    userId,
                    widget: {
                        id: uuidv4(),
                        extractedCode,
                        queryResult,
                        widget: values?.widget,
                        customType: values?.customType,
                        combineText: values?.combineText
                    }
                });
                if (res.status === 200) {

                }

            }
            reset(formReference)
        } catch (error) {
            console.error(error)
            setEmailSent(false)
        }
    }

    const handleCancelMembership = async (e) => {
        const userId = user?.id
        e.preventDefault()
        const res = await adminUpdate({
            action,
            userId,
            isPremium: user?.isPremium
        })
        if (res.status === 200) {
            reset(formReference)
        }
    }

    useEffect(() => {
        const { id } = queryString.parse(location.search);
        if (id) {
            fetchUserFromAPI(id);
        }
    }, [location, action]);

    const nameValidator = composeValidators(
        isRequired('Required')
    );

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values }) => {
                formReference = form;

                return (
                    <form onSubmit={handleSubmit}>
                        <FormSpy onChange={(formState) => {
                            const newValues = { ...formState.values }
                            setExtractedCode(newValues?.extractedCode)
                        }} />

                        <div style={{
                            marginBottom: '15px'
                        }}>
                            <SelectInput
                                name='action'
                                placeholder={'Action'}
                                options={[
                                    { value: 'resetPassword', label: 'Reset Password' },
                                    { value: 'cancelMembership', label: 'Cancel Membership' },
                                    { value: 'customWidget', label: 'Custom Widget' },
                                ]}
                                onChange={(e) => handleActionChange(e, form)}
                            />
                        </div>

                        <ConditionalRender renderIf={action === 'resetPassword'}>
                            {
                                emailSent ? 'Email sent' : (
                                    <>
                                        <MultiFields
                                            name="email"
                                            component="input"
                                            type="email"
                                            block
                                            disabled
                                            initialValue={user?.email}
                                            label="Email"
                                            style={{
                                                margin: '15px 0'
                                            }}
                                        />
                                        <SelectInput
                                            name={'timeLimit'}
                                            placeholder={'Set the time limit'}
                                            options={[
                                                { value: '10minutes', label: '10 minutes' },
                                                { value: '20minutes', label: '20 minutes' },
                                                { value: '1hour', label: '1 hour' },
                                                { value: '2 hours', label: '2 hours' },
                                                { value: '24 hours', label: '24 hours' },
                                            ]}
                                            onChange={(e) => handleTimeLimitChange(e)}
                                        />
                                    </>
                                )
                            }

                        </ConditionalRender>

                        <ConditionalRender renderIf={action === 'customWidget'}>
                            <SelectInput
                                name={'widget'}
                                options={[
                                    { value: 'customWidget', label: 'custom widget' },
                                ]}
                                style={{ display: 'none' }}
                            />
                            <SelectInput
                                name={'customType'}
                                options={[
                                    { value: 'queryDB', label: 'Query DB' },
                                ]}
                                onChange={(e) => handleSetCustomType(e)}
                            />
                            <ConditionalRender renderIf={!isEmpty(customType)}>
                                <div className='flex' style={{
                                    marginTop: '15px',
                                    justifyContent: 'space-between'
                                }}>
                                    <div>
                                        <div>
                                            <div style={{
                                                fontFamily: colors.openSans,
                                                fontWeight: 'bold',
                                                color: colors.blue
                                            }}>
                                                {'Inventory Columns'}
                                            </div>
                                            <MultiFields
                                                component="code"
                                                readOnly
                                                value={JSON.stringify(inventoryColumns, null, 4)}
                                                block
                                                disabled
                                                label="Code"
                                                style={{
                                                    margin: '15px 0'
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div style={{
                                                fontFamily: colors.openSans,
                                                fontWeight: 'bold',
                                                color: colors.blue
                                            }}>
                                                {'Inventory'}
                                            </div>
                                            <MultiFields
                                                component="code"
                                                readOnly
                                                value={JSON.stringify(inventory, null, 4)}
                                                block
                                                disabled
                                                label="Code"
                                                style={{
                                                    margin: '15px 0'
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div style={{
                                                fontFamily: colors.openSans,
                                                fontWeight: 'bold',
                                                color: colors.blue
                                            }}>
                                                {'Widgets'}
                                            </div>
                                            <MultiFields
                                                component="code"
                                                readOnly
                                                value={JSON.stringify(widgets, null, 4)}
                                                block
                                                disabled
                                                label="Code"
                                                style={{
                                                    margin: '15px 0'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        marginLeft: '15px',
                                        flex: '1'
                                    }}>
                                        <div style={{
                                            fontFamily: colors.openSans,
                                            fontWeight: 'bold',
                                            color: colors.blue
                                        }}>
                                            {'Create Widget'}
                                        </div>
                                        <MultiFields
                                            name={'extractedCode'}
                                            component="code"
                                            block
                                            label="Code"
                                            style={{
                                                margin: '15px 0'
                                            }}
                                        />
                                        <MultiFields
                                            name={'combineText'}
                                            component="text"
                                            block
                                            validate={nameValidator}
                                            label="Question"
                                            style={{
                                                margin: '25px 0'
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            onClick={handleQuery}
                                            icon={faPlay}
                                            style={{
                                                color: colors.blue,
                                                fontSize: '3rem',
                                                cursor: 'pointer'
                                            }}
                                        />
                                        <ConditionalRender renderIf={!isEmpty(queryResult)}>
                                            <div style={{
                                                margin: '15px 0',
                                                fontSize: '1.2rem',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'
                                            }}>
                                                {queryResult?.map((item, i) => (
                                                    <div key={i} style={{
                                                        marginBottom: '10px'
                                                    }}>
                                                        <span style={{
                                                            fontFamily: colors.openSans,
                                                            color: colors.blue
                                                        }}>{item.key}: </span>
                                                        <span style={{
                                                            fontFamily: colors.roboto,
                                                        }}>
                                                            {item.key?.toLowerCase()?.includes('amount') ? '$' : ''}
                                                            {Number.isFinite(item.value) ?
                                                                (item.value % 1 === 0 ? item.value : Number(item.value).toFixed(2))
                                                                : item.value}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </ConditionalRender>
                                    </div>
                                </div>
                            </ConditionalRender>
                        </ConditionalRender>

                        <ConditionalRender renderIf={action === 'cancelMembership'}>
                            <CallToAction style={{ alignSelf: 'flex-end', flex: '1', backgroundColor: colors.red }} onClick={(e) => handleCancelMembership(e)} text='Cancel Membership' />
                        </ConditionalRender>

                        <FormActions
                            type="submit"
                            submitText={"Save"}
                            btnStyle={{
                                color: colors.blue
                            }}
                        />
                    </form>
                )
            }}
        />
    )
}

export default AdminDetailsContainer
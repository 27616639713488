import React from 'react';
import CheckBox from '../Core/Inputs/CheckBox';
import SelectableBox from '../Core/SelectableBox';

const EmployeeItem = ({
    employee,
    selectedEmployees,
    adminEmployees,
    handleEmployeeSelection,
    handleAdminSelection,
    text
}) => {
    const isSelected = selectedEmployees.some(e => e?._id === employee?._id);
    const isAdmin = adminEmployees.some(e => e?._id === employee?._id);

    return (
        <SelectableBox
            key={employee?._id}
            className='flex justify-space-between'
            showLightColors
        >
            <CheckBox
                label={`${employee?.firstName} ${employee?.lastName}`}
                checked={isSelected}
                onChange={() => handleEmployeeSelection(employee)}
                style={{ marginRight: '15px' }}
                showLightColors
            />
            {isSelected && (
                <CheckBox
                    checked={isAdmin}
                    onChange={() => handleAdminSelection(employee)}
                    style={{ marginLeft: '15px' }}
                    label={text?.projects?.create?.admin}
                    showLightColors
                />
            )}
        </SelectableBox>
    );
};

export default EmployeeItem;
